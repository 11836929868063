<template>
    <div class="menu">
        <div class="menu-item">
            <div class="tag" v-for="(item,index) in $store.state.app.pageMenuList" v-if="$store.state.app.pageMenuList" @click="_active(index)">
                <div class="text-content">
                    <div class="tag-icon">
                        <img :src="item.IcoImg" style="width:35px;height:35px;" alt="" v-if="item.IcoImg">
                        <a-icon v-else :type="item.Ico" :class="active==index?'ant-menu-item-selected':''"/>
                    </div>
                    <div class="tag-text" :class="active==index?'ant-menu-item-selected':''">{{item.Mname}}</div>
                </div>
                <div v-show="active==index" class="triangle ant-btn-primary"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "Menu",
        data(){
            return{
                MenuList: [],
                navItemActive: -1,
                skipActive: -1,
            }
        },
        computed: {
            ...mapState({
                theme:(state) => state.app.theme,
                active:(state) => state.app.defaultOpenKeys,
                defaultIndex: (state) => state.app.defaultIndex,
                showNav : (state) => state.app.showNav
            })
        },
        methods: {
            _active(i){
                this.$store.commit('TOGGLE_DEFAULT_OPEN_KEYS',i);
                this.$store.commit('TOGGLE_SHOW_NAV',true);

            },
            _navItemActive(i){
                if(this.navItemActive==i){
                    this.navItemActive=-1;
                }else{
                    this.navItemActive = i;
                }
            },
            _skipPage(i){
                this.skipActive = i;
            }
        }
    }
</script>

<style scoped lang="less">
    .menu{
        display: flex;
        height: 100%;
        position: fixed;
        top: 80px;
        z-index: 200;
        .menu-item{
            width: 120px;
            position: relative;
            height: calc(~'100% - 80px');
            box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
            z-index: 200;
            background: #fff;
            overflow-y: scroll;
            .tag{
                position: relative;
                width: 100%;
                height: 115px;
                border-bottom: 1px solid #DEE2E6;
                cursor: pointer;
                z-index: 90;
                .tag-icon{
                    text-align: center;
                    i{
                        font-size: 30px;
                        font-weight: bold;
                        /*color: #47404f;*/
                    }
                }
                .tag-text{
                    margin-top: 5px;
                    text-align: center;
                    font-size: 14px;
                    display: block;
                    font-weight: 400;
                    white-space: nowrap;
                    //color:#47404f;
                }
                .text-content{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
                .triangle{
                    position: absolute;
                    width: 0;
                    height: 0;
                    right: 0;
                    bottom: 0;
                    border-style: solid;
                    border-width: 25px 25px 0 0;
                    border-color: #fff transparent transparent transparent;
                }
            }
        }
        .menu-item::-webkit-scrollbar {
            display: none;
        }
    }
</style>