<!--
 * @Author: 艾斯
 * @Description: 通用表单组件
 * @Date: 2022-07-07 09:36:28
 * @LastEditTime: 2022-07-23 11:57:32
 * @FilePath: /sksaas-web/src/components/Common/Form.vue
-->
<template>
  <div class="m-form" :style="{ width: width }">
    <el-form ref="ruleForm" :model="form" :rules="rules" :label-width="labelWidth">
      <el-form-item v-for="item in formItemOptions" :key="item.model" :label="item.label" :prop="item.prop">
        <!-- 文本框 -->
        <el-input
          :type="item.type"
          v-model="form[item.model]"
          :placeholder="item.placeholder"
          :autosize="{ minRows: 2, maxRows: 4 }"
          clearable
          v-if="!item.type || item.type === 'textarea'"
        />
        <!-- 下拉框 -->
        <el-select
          v-model="form[item.model]"
          :placeholder="item.placeholder"
          clearable
          filterable
          :multiple="item.multiple"
          :collapse-tags="item.multiple"
          v-if="item.type === 'select'"
        >
          <el-option v-for="row in item.options" :key="row.value" :label="row.label" :value="row.value" />
        </el-select>
        <!-- 日期选择 -->
        <el-date-picker v-model="form[item.model]" type="date" value-format="yyyy-MM-dd" :placeholder="item.placeholder" v-if="item.type === 'datePicker'" />
        <!-- 级联选择 -->
        <el-cascader v-model="form[item.model]" :options="item.options" :props="item.optionsProps" clearable filterable collapse-tags v-if="item.type === 'cascader'" />
        <!-- 复选框 -->
        <el-checkbox-group v-model="form[item.model]" v-if="item.type === 'checkbox'">
          <el-checkbox v-for="row in item.options" :key="row.value" :name="item.model" :label="row.value">{{ row.label }}</el-checkbox>
        </el-checkbox-group>
        <!-- 单选框 -->
        <el-radio-group v-model="form[item.model]" v-if="item.type === 'radio'">
          <el-radio v-for="row in item.options" :key="row.value" :label="row.value">{{ row.label }}</el-radio>
        </el-radio-group>

        <!-- 插槽 -->
        <slot name="slot" :row="item" v-if="item.type === 'slot'"></slot>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {}
    }
  },
  props: {
    width: {
      type: String,
      default: '500px'
    },
    labelWidth: {
      type: String,
      default: '120px'
    },
    formItemOptions: {
      type: Array,
      default: () => {
        return []
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  created() {
    if (this.formItemOptions.length > 0) {
      let form = {}
      for (const item of this.formItemOptions) {
        form[item.model] = item.value
      }
      this.form = { ...form }
    }
  },
  methods: {
    submitForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            let form = { ...this.form }
            this.form = {}
            this.resetForm()
            resolve({ formInfo: form })
          } else {
            reject(false)
          }
        })
      })
    },
    resetForm() {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.m-form {
}
</style>
