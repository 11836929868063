<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <global-header v-if="skipId == ''"></global-header>
    <a-layout>
      <a-layout-sider :trigger="null" collapsible width="120" :style="$store.state.app.theme == 'light' ? 'background: #fff' : 'background: #001121'" v-if="skipId == ''">
        <Menu></Menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-content :style="{ background: '#F0F0F8', marginTop: skipId ? 0 : '80px', display: 'flex', height: height }">
          <transition name="navShow">
            <div class="nav" v-if="$store.state.app.pageMenuList[active] && $store.state.app.pageMenuList[active].child.length != 0" v-show="showNav">
              <div style="position: fixed; width: 220px; height: 100%;">
                <ul class="nav-list">
                  <!--第二级-->
                  <li
                    class="nav-item"
                    v-for="(item, index) in $store.state.app.pageMenuList[active].child"
                    :key="index"
                    @mouseover="_navItemActive(index)"
                    @mouseout="_navItemActive(index)"
                    @click.stop="_skipPage(index, item.Url, item)"
                  >
                    <div class="tab flex" :class="skipActive == index || navItemActive == index ? 'navItemActive ant-menu-item-selected' : ''">
                      <div>
                        <a-icon class="nav-icon" v-if="item.Ico || item.IcoImg == ''" :type="item.Ico || 'frown'" />
                        <img v-else :src="item.IcoImg" class="icon-img" alt="" />
                        <span class="tab-name">{{ item.Mname }}</span>
                      </div>
                      <span v-if="item.child.length != 0">
                        <a-icon :type="skipActive == index ? 'caret-down' : 'caret-right'" style="color: #666;" />
                      </span>
                    </div>
                    <!--第三级-->
                    <div
                      class="tab-child"
                      v-if="item.child.length != 0 && skipActive == index"
                      @mouseover="_tabChildActive(index + '-' + tabIndex)"
                      @mouseout="_tabChildActive(index + '-' + tabIndex)"
                      @click.stop="_skipChildActive(index, tabIndex, tab)"
                      v-for="(tab, tabIndex) in item.child"
                      :key="tabIndex"
                    >
                      <ul class="nav-list">
                        <li class="nav-item">
                          <div
                            class="tab flex"
                            :class="skipChildActive == index + '-' + tabIndex || tabChildActive == index + '-' + tabIndex ? 'navItemActive ant-menu-item-selected' : ''"
                          >
                            <div>
                              <a-icon class="nav-icon" v-if="tab.Ico || tab.IcoImg == ''" :type="tab.Ico || 'frown'" />
                              <img v-else :src="tab.IcoImg" class="icon-img" alt="" />
                              <span>{{ tab.Mname }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </transition>
          <div class="right-content">
            <div style="position: relative; height: 35px; overflow: hidden;" v-if="multiTab && $store.state.app.multiTabList.length > 0 && !displayTab.includes($route.path)">
              <multi-tab></multi-tab>
            </div>
            <!--SKIM跳转过来的时候不需要边距-->
            <div class="contentLayout" :style="{ height: skipId == '' ? '' : '100%', padding: $route.meta.isPadding == false || $route.query.code ? '0' : '15px' }">
              <keep-alive>
                <router-view v-if="$route.meta.keepAlive && isRouterAlive" class="routerView"></router-view>
              </keep-alive>
              <router-view :key="key" v-if="!$route.meta.keepAlive && isRouterAlive" class="routerView"></router-view>
            </div>
          </div>
        </a-layout-content>
        <!--<setting-drawer v-if="skipId==''"></setting-drawer>-->
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import Menu from 'components/Menu/Menu'
import GlobalHeader from 'components/GlobalHeader/GlobalHeader'
// import SettingDrawer from 'components/SettingDrawer'
import MultiTab from 'components/MultiTab/MultiTab'
import { mixin, mixinDevice } from '../utils/mixin'
import config from '../config/defaultSettings'
import { mapState } from 'vuex'
export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: {
    Menu,
    GlobalHeader,
    MultiTab
  },
  data() {
    return {
      production: config.production,
      navItemActive: -1,
      skipActive: -1,
      tabChildActive: -1,
      skipChildActive: -1,
      height: ' ',
      skipId: '',
      isRouterAlive: true,
      displayTab: ['/workOrder', '/workReport', '/CounselorBoard']
    }
  },
  created() {
    //skim系统跳转过来的时候不显示导航和头部;
    if (window.sessionStorage.getItem('skimCode')) {
      this.skipId = window.sessionStorage.getItem('skimCode')
    } else {
      this.skipId = this.$route.query.code ? this.$route.query.code : ''
      if (this.skipId) {
        window.sessionStorage.setItem('skimCode', this.skipId)
      }
    }
  },
  mounted() {
    if (this.skipId) {
      this.height = window.document.body.clientHeight + 'px'
    } else {
      this.height = window.document.body.clientHeight - 80 + 'px'
    }
  },
  computed: {
    ...mapState({
      showNav: state => state.app.showNav,
      active: state => state.app.defaultOpenKeys,
      MenuList: state => state.app.pageMenuList
    }),
    //同一个页面跳转的时候刷新页面
    key() {
      return this.$route.name ? this.$route.name + +new Date() : this.$route + +new Date()
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    _tabChildActive(index) {
      if (this.tabChildActive == index) {
        this.tabChildActive = -1
      } else {
        this.tabChildActive = index
      }
    },
    _skipChildActive(index, tabIndex, data) {
      this.skipChildActive = index + '-' + tabIndex
      this.$router.push({ path: data.Url })
    },
    _navItemActive(i) {
      if (this.navItemActive == i) {
        this.navItemActive = -1
      } else {
        this.navItemActive = i
      }
    },
    _skipPage(i, url, data) {
      if (data.child.length == 0) {
        this.skipActive = i
        this.$router.push({ path: url })
      } else {
        if (this.skipActive === i) {
          this.skipActive = -1
        } else {
          this.skipActive = i
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.contentLayout {
  height: calc(~'100% - 35px');
  overflow-y: auto;
}
.nav {
  position: relative;
  flex-shrink: 0;
  width: 220px;
  height: 100%;
  z-index: 89;
  padding: 0.75rem 0;
  transition: all 0.24s ease-in-out;
  background: #fff;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  outline: none;
  .nav-list {
    position: relative;
    /*top: 80px;*/
    /*left: 120px;*/
    width: 220px;
    height: 90%;
    overflow-y: scroll;
    background: #fff;
  }
  .nav-list::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .nav-item {
    .tab {
      color: #332e38;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      font-size: 13px;
      cursor: pointer;
      padding: 12px 24px;
      transition: 0.15s all ease-in;
      white-space: nowrap;
      .nav-icon {
        display: inline-block;
        font-size: 18px;
        margin-right: 8px;
        vertical-align: middle;
      }
      .tab-name {
        width: 120px;
        display: inline-block;
        white-space: nowrap;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .tab-child {
      padding-left: 25px;
      .nav-list {
        width: 100%;
      }
    }
    .icon-img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .navItemActive {
    background: #eeeeee;
  }
}
.right-content {
  flex: 1;
  min-height: 100%;
}
.navShow-enter-active {
  opacity: 1;
  width: 220px;
  transition: all 0.3s ease;
}
.navShow-leave-active {
  opacity: 0;
  width: 0;
  transition: all 0.3s ease;
}
.navShow-enter,
.navShow-leave-to {
  opacity: 0;
  width: 0;
}
</style>
