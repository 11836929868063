
const language = {
    state:{
        langTab: ''
    },
    mutations:{
        langTabFn(state,val){
            state.langTab = val;
        }
    },
    actions:{

    },
}

 export default language
