const request = [
  {
    url: '/v1/token/mobile',
    des: '获取access_token',
    code: 110
  },
  {
    url: '/skim/v1/teacher/homework_detail',
    des: '作业详情',
    code: 2000
  },
  {
    url: '/v1/category/getCategory',
    des: '获取tree数据',
    code: 1200
  },
  {
    url: '/v1/category/removeCategory',
    des: '删除tree节点',
    code: 1201
  },
  {
    url: '/v1/category/add',
    des: '添加修改tree子节点',
    code: 1202
  },
  {
    url: '/v1/before_token',
    des: '获取用户关联企业列表',
    code: 1203
  },
  {
    url: '/v1/token',
    des: '获取用户token',
    code: 1204
  },
  {
    url: '/api/v1/todoback/list',
    des: '任务清单列表',
    code: 1205
  },
  {
    url: '/v1/category/getCategory',
    des: '获取所属分类列表',
    code: 1206
  },
  {
    url: '/api/v1/todoback/addtodo',
    des: '获取所属分类列表',
    code: 1207
  },
  {
    url: '/api/v1/todo/delete',
    des: '删除清单',
    code: 1208
  },
  {
    url: '/api/v1/todoback/getinfo',
    des: '获取清单详细信息',
    code: 1209
  },
  {
    url: '/api/v1/todoback/edittodo',
    des: '修改清单',
    code: 1210
  },
  {
    url: '/v1/category/delete',
    des: '删除分类',
    code: 1211
  },
  {
    url: '/v1/category/edit',
    des: '编辑分类',
    code: 1212
  },

  {
    //文件柜
    url: '/file/v1/get_complete_category',
    des: '获取文件柜列表',
    code: 8001
  },
  {
    url: '/user/v1/completeOrgs',
    des: '获取选人树',
    code: 8002
  },
  {
    url: '/user/v1/OrgsNext',
    des: '获取选人树',
    code: 8003
  },
  {
    url: '/api/v1/hr/demandType',
    des: '招聘管理 需求类型',
    code: 8004
  },
  {
    url: '/api/v1/hr/completeStatus',
    des: '招聘管理 完成状态',
    code: 8005
  },
  {
    url: '/api/v1/hr/emergencyLevel',
    des: '招聘管理 紧急程度',
    code: 8006
  },
  {
    url: '/api/v1/hr/getPositionList',
    des: '招聘管理 获取岗位',
    code: 8007
  },
  {
    url: '/school/v1/schools',
    des: '获取所有中心(包含加盟)树(9980不含加盟)',
    code: 8008
  },

  {
    url: '/school/v1/city_list',
    des: '获取省市三级联动',
    code: 8009
  },
  {
    url: '/school/v1/get_region',
    des: '三级联动插件',
    code: 8010
  },
  {
    url: '/user/v1/select_info_universal',
    des: '选人组件里的搜索',
    code: 8011
  },
  {
    url: '/user/v1/Organization',
    des: '文件柜下拉选项（部门）',
    code: 8012
  },
  {
    url: '/workorder/v1/get_project_task_category',
    des: '工单(创建工单级联下拉)',
    code: 8013
  },
  {
    url: '/workorder/v1/get_rule_category',
    des: '工单规则管理(分类))',
    code: 8014
  },
  {
    url: '/workorder/v1/first_category_list',
    des: '工单规则管理(下拉列表)',
    code: 8015
  },
  {
    url: '/workorder/v1/template_list',
    des: '工单规则管理(添加规则选模板)',
    code: 8016
  },
  {
    url: '/workorder/v1/category_table',
    des: '工单分类管理table',
    code: 8017
  },
  {
    url: '/workorder/v1/rule_list',
    des: '工单规则管理table',
    code: 8018
  },

  {
    url: '/trainplan/v1/get_video_info',
    des: '学习计划视频',
    code: 8019
  },
  {
    url: '/trainplan/v1/import_train_plan',
    des: '学习计划excel添加人员',
    code: 8020
  },
  {
    url: '/trainplan/v1/get_more_people',
    des: '学习计划excel添加人员(查看更多，获取余下的人员)',
    code: 8021
  },

  {
    url: '/v1/yindiandian/document_list',
    des: '印点点 （标哥）',
    code: 8022
  },
  {
    url: '/v1/yindiandian/sync_doc_info',
    des: '印点点 （标哥）同步数据',
    code: 8023
  },
  {
    url: '/v1/yindiandian/user_count',
    des: '印点点 （标哥）统计数据',
    code: 8024
  },
  {
    url: '/v1/yindiandian/dept_count',
    des: '印点点 （标哥）部门统计数据',
    code: 8025
  },
  {
    url: '/v1/yindiandian/get_dep_list',
    des: '印点点 （标哥）部门list',
    code: 8026
  },
  {
    url: '/api/v1/hr/get_roles',
    des: '获取角色',
    code: 8027
  },
  {
    url: '/user/v1/users',
    des: '获取角色',
    code: 8028
  },
  {
    url: '/api/v1/systemmenu/list',
    des: '获取菜单列表',
    code: 1213
  },
  {
    url: '/api/v1/logs/logsList',
    des: '日志管理接口',
    code: 1214
  },
  {
    url: '/v1/category/getCategory',
    des: '日志管理获取分类',
    code: 1215
  },
  {
    url: '/api/v1/systemmenu/add',
    des: '添加菜单',
    code: 1216
  },
  {
    url: '/api/v1/systemmenu/edit',
    des: '修改菜单',
    code: 1217
  },
  {
    url: '/api/v1/systemmenu/delete',
    des: '删除菜单',
    code: 1218
  },
  {
    url: '/configuration/v1/get_config_list',
    des: '配置管理 - 获取配置项列表',
    code: 1219
  },
  {
    url: '/api/v1/message/list',
    des: '消息管理数据',
    code: 1220
  },
  {
    url: '/configuration/v1/show_detail',
    des: '显示配置详情',
    code: 1221
  },
  {
    url: '/configuration/v1/add_config_item',
    des: '添加配置管理大类',
    code: 1222
  },
  {
    url: '/configuration/v1/add_config',
    des: '添加配置',
    code: 1223
  },
  {
    url: '/configuration/v1/edit_config',
    des: '修改配置小项',
    code: 1224
  },
  {
    url: '/configuration/v1/del_config',
    des: '删除配置小项',
    code: 1225
  },
  {
    url: '/configuration/v1/del_config_item',
    des: '删除配置大类',
    code: 1226
  },
  {
    url: '/v1/verify_by_code',
    des: 'skim系统跳转，获取用户信息',
    code: 1227
  },
  {
    url: '/api/v1/questionnaire/list',
    des: '问卷获取列表数据',
    code: 1228
  },
  {
    url: '/api/v1/questionnaire/delete',
    des: '删除问卷',
    code: 1229
  },
  {
    url: '/coursemanage/v1/course_list',
    des: '精品课',
    code: 1230
  },
  {
    url: '/coursemanage/v1/online_course',
    des: '精品课',
    code: 1231
  },
  {
    url: '/coursemanage/v1/updata_course',
    des: '精品课',
    code: 1232
  },
  {
    url: '/distribution/v1/editPoster',
    des: '分享海报设置',
    code: 1233
  },

  {
    url: '/distribution/v1/extensionPlanDetail',
    des: '海报分享内容',
    code: 1234
  },
  {
    url: '/report/v1/classCategoryAchievementStatistics',
    des: '课程分类业绩统计',
    code: 1235
  },
  {
    url: '/distribution/v1/distributionUserList',
    des: '分销会员列表',
    code: 1236
  },
  {
    url: '/distribution/v1/distributionUserDetail',
    des: '分销员的详情',
    code: 1237
  },
  {
    url: '/distribution/v1/distributionUserList',
    des: '分销员下级列表',
    code: 1238
  },
  {
    url: '/distribution/v1/distributionUserSituation',
    des: '分销员列表统计',
    code: 1239
  },
  {
    url: '/distribution/v1/distributionOrderlist',
    des: '分销员订单详情',
    code: 1240
  },
  {
    url: '/distribution/v1/saveDistributorRank',
    des: '分销等级操作',
    code: 1241
  },
  {
    url: '/distribution/v1/distributRankList',
    des: '分销等级列表',
    code: 1242
  },
  {
    url: '/distribution/v1/delDistributRank',
    des: '分销等级删除',
    code: 1243
  },
  {
    url: '/distribution/v1/distributRankDetail',
    des: '分销等级详情',
    code: 1244
  },
  {
    url: '/distribution/v1/allDistributRankList',
    des: '分销等级列表',
    code: 1245
  },
  {
    url: '/distribution/v1/updateDistributorLevel',
    des: '修改分销员的等级',
    code: 1246
  },
  {
    url: '/distribution/v1/orderInfo',
    des: '分销订单详情',
    code: 1247
  },
  {
    url: '/distribution/v1/userWithdrawalList',
    des: '分销提现审核列表',
    code: 1248
  },
  {
    url: '/distribution/v1/editWithdrawalAuditStatus',
    des: '分销驳回审核',
    code: 1249
  },
  {
    url: '/api/v1.hr/editHrNeed',
    des: '招聘需求操作',
    code: 1250
  },
  {
    url: '/api/v1.hr/hrNeedList',
    des: '招聘需求列表',
    code: 1251
  },
  {
    url: '/api/v1.hr/positionList',
    des: '招聘需求岗位列表',
    code: 1252
  },
  {
    url: '/api/v1.hr/getHrNeedDetail',
    des: '招聘需求详情',
    code: 1253
  },
  {
    url: '/api/v1.hr/updateHrNeed',
    des: '招聘需求删除',
    code: 1254
  },
  {
    url: '/api/v1.hr/hrOrgTree',
    des: '招聘需求组织架构树',
    code: 1255
  },
  {
    url: '/report/v1/classtrackAnalysis',
    des: '班级出勤分析',
    code: 1256
  },
  {
    url: '/api/v1.hr/cityList',
    des: '城市列表',
    code: 1257
  },
  {
    url: '/report/v1/classtrackTypeAnalysis',
    des: '班级出勤分析',
    code: 1258
  },
  {
    url: '/api/v1.hr/editResume',
    des: '简历操作',
    code: 1259
  },
  {
    url: '/api/v1.hr/resumeList',
    des: '简历列表',
    code: 1260
  },
  {
    url: '/api/v1.hr/resumeDetail',
    des: '简历列表',
    code: 1261
  },

  {
    url: '/api/v1.hr/delResume',
    des: '简历删除',
    code: 1262
  },
  {
    url: '/api/v1.hr/resumeRelationRecruitmentNeeds',
    des: '简历关联招聘需求',
    code: 1263
  },
  {
    url: '/api/v1.hr/statisticsStageNum',
    des: '简历列表总数',
    code: 1264
  },

  {
    url: '/api/v1.hr/hrNeedsTotal',
    des: '简历列表总数',
    code: 1265
  },
  {
    url: '/api/v1.hr/resumeRelationRecruitmentNeeds',
    des: '关联招聘需求',
    code: 1266
  },
  {
    url: '/api/v1.hr/GetOfficePreviewURL',
    des: '阿里云文档预览地址',
    code: 1267
  },
  {
    url: '/api/v1.hr/saveCommonFieldHrResume',
    des: '简历单个字段操作',
    code: 1268
  },
  {
    url: '/api/v1.hr/setHrInterviewDetail',
    des: '简历面试操作',
    code: 1269
  },
  {
    url: '/api/v1.hr/hrInterviewDetail',
    des: '简历面试详情',
    code: 1270
  },
  {
    url: '/api/v1.hr/interviewUserDate',
    des: '简历面试官时间',
    code: 1271
  },
  {
    url: '/api/v1.hr/setResumeFollow',
    des: '关注/取消关注简历',
    code: 1272
  },
  {
    url: '/api/v1.hr/setInterviewEvaluation',
    des: '面试评价',
    code: 1273
  },
  {
    url: '/api/v1.hr/setInterviewStatus',
    des: '候选人反馈',
    code: 1274
  },
  {
    url: '/api/v1.hr/setStatus',
    des: '取消面试',
    code: 1275
  },
  {
    url: '/api/v1.hr/getOfferDetail',
    des: '获取offer的详情',
    code: 1276
  },
  {
    url: '/api/v1.hr/setOffer',
    des: ' 编辑/创建  offer',
    code: 1277
  },
  {
    url: '/api/v1.hr/cancelOffer',
    des: ' 取消 offer',
    code: 1278
  },
  {
    url: '/api/v1.hr/cancelOffer',
    des: '简历标签列表',
    code: 1280
  },
  {
    url: '/api/v1.hr/getPreviewUrl',
    des: '简历预览地址',
    code: 1281
  },
  {
    url: '/api/v1.hr/editOfferApprovalUser',
    des: '添加编辑offer审批人',
    code: 1282
  },
  {
    url: '/api/v1.hr/getApproverList',
    des: '获取offer审批人列表',
    code: 1283
  },
  {
    url: '/api/v1.hr/delApprovalUser',
    des: '删除offer审批人',
    code: 1284
  },
  {
    url: '/api/v1.hr/resumeForward',
    des: '单个推荐给用人部门',
    code: 1285
  },
  {
    url: '/api/v1.hr/batchResumeForward',
    des: '批量推荐给用人部门',
    code: 1286
  },
  {
    url: '/api/v1.hr/setResumeType',
    des: '纳入人才库',
    code: 1287
  },
  {
    url: '/api/v1.hr/batchAddTag',
    des: '批量添加标签',
    code: 1288
  },
  {
    url: '/api/v1.hr/sendInterviewHrMail',
    des: '重新发送面试邮件',
    code: 1289
  },
  {
    url: '/api/v1.hr/interviewList',
    des: '面试安排',
    code: 1290
  },
  {
    url: '/api/v1.hr/interviewTotalTop',
    des: '面试安排数据汇总',
    code: 1291
  },
  {
    url: '/api/v1.hr/interviewTotalLeft',
    des: '面试安排左边汇总',
    code: 1292
  },
  {
    url: '/api/v1.hr/saveInterviewStatus',
    des: '批量取消面试',
    code: 1293
  },
  {
    url: '/api/v1.hr/noticeInterviewer',
    des: '批量通知面试官',
    code: 1294
  },
  {
    url: '/api/v1.hr/batchSendCandidateNotice',
    des: '批量通知候选人',
    code: 1295
  },
  {
    url: '/api/v1.hr/personnelTop',
    des: '人才库数据汇总',
    code: 1296
  },
  {
    url: '/api/v1.hr/updateInchargeUser',
    des: '修改招聘需求招聘负责人',
    code: 1297
  },
  {
    url: '/api/v1.hr/personnelLeft',
    des: '人才库侧边栏',
    code: 1298
  },
  {
    url: '/api/v1.hr/personnelList',
    des: '人才库列表',
    code: 1299
  },
  {
    url: '/api/v1.hr/senfOfferApprovalMsg',
    des: '发送审批链',
    code: 12991
  },
  {
    url: '/api/v1.hr/setOfferCandidateStatus',
    des: '候选人offer反馈',
    code: 12992
  },
  {
    url: '/api/v1.hr/sendOfferCandidate',
    des: '发送offer',
    code: 12993
  },
  {
    url: '/api/v1.hr/setBackList',
    des: '设置黑名单',
    code: 12994
  },
  {
    url: '/api/v1.hr/resumeStatusList',
    des: '简历状态列表',
    code: 12995
  },
  {
    url: '/api/v1.hr/getResumeCorrelateUserlist',
    des: '简历状态用户列表',
    code: 12996
  },
  {
    url: '/api/v1.hr/addRemark',
    des: '简历状态添加备注',
    code: 12997
  },
  {
    url: '/api/v1.hr/ResumeRemarkList',
    des: '简历备注列表',
    code: 12998
  },
  {
    url: '/api/v1.hr/getOfferRemarkList',
    des: 'offer发送记录',
    code: 12999
  },
  {
    url: '/api/v1.hr/importResune',
    des: '简历导入',
    code: 12910
  },
  {
    url: '/api/v1.hr/deleteHrNeed',
    des: '删除需求',
    code: 12911
  },
  {
    url: '/api/v1.hr/updateHrNeedStatus',
    des: '更改招聘需求招聘状态',
    code: 12912
  },
  {
    url: '/api/v1.hr/needRelationResumeList',
    des: '招聘需求候选人列表',
    code: 12913
  },
  {
    url: '/api/v1.hr/setResumeStatus',
    des: '简历的状态',
    code: 12914
  },
  {
    url: '/api/v1.hr/setInterviewUserDate',
    des: '设置面试官的时间',
    code: 12915
  },
  {
    url: '/api/v1.hr/getInterviewUserDate',
    des: '获取面试官的时间',
    code: 12916
  },
  {
    url: '/api/v1.system/ddns_list',
    des: 'ddns列表',
    code: 12917
  },
  {
    url: '/api/v1.system/ddns_action',
    des: 'ddns操作',
    code: 12918
  },
  {
    url: '/api/v1.system/ddns_del',
    des: 'ddns删除',
    code: 12919
  },
  {
    url: '/api/v1.system/ddns_allowed',
    des: 'ddns更改状态',
    code: 12920
  },
  {
    url: '/api/v1.system/ddns_record_list',
    des: 'ddns记录列表',
    code: 12921
  },
  {
    url: '/api/v1.system/ddns_record_add',
    des: 'ddns添加记录',
    code: 12922
  },
  {
    url: '/api/v1.system/list_check',
    des: '数据大屏列表',
    code: 1292256
  },
  {
    url: '/api/v1.system/data_check',
    des: '数据大屏添加、编辑',
    code: 1292257
  },
  {
    url: '/api/v1.system/del_check',
    des: '数据大屏删除',
    code: 1292258
  },
  {
    url: '/api/v1.system/check_info',
    des: '数据大屏详情',
    code: 1292259
  },
  {
    url: '/api/v1.hr/resumeTagList',
    des: '设置面试官的时间',
    code: 12923
  },
  {
    url: '/api/v1.hr/resumeStutasInfo',
    des: '招聘报表',
    code: 12924
  },
  {
    url: '/api/v1.hr/overviewTop',
    des: 'HR总览',
    code: 12925
  },
  {
    url: '/api/v1.hr/overviewLeft',
    des: 'HR总览',
    code: 12926
  },
  {
    url: '/api/v1.hr/overviewRightMy',
    des: 'HR总览@我',
    code: 12927
  },
  {
    url: '/api/v1.hr/overviewRightRemind',
    des: 'HR总览跟进提醒',
    code: 12928
  },
  {
    url: '/api/v1.hr/overviewRightInterviewRelevant',
    des: 'HR总览面试相关',
    code: 12929
  },
  {
    url: '/api/v1.hr/overviewRightOfferRelevant',
    des: 'HR总览offer相关',
    code: 12930
  },
  {
    url: '/api/v1.hr/overviewRightNeedRelevant',
    des: 'HR总览招聘需求相关',
    code: 12931
  },
  {
    url: '/api/v1.hr/needLeft',
    des: 'HR招聘需求',
    code: 12932
  },
  {
    url: '/api/v1.hr/needCompleteInfo',
    des: 'HR招聘需求',
    code: 12933
  },
  {
    url: '/market/v1/get_school_person',
    des: '获取中心顾问',
    code: 12934
  },
  {
    url: '/api/v1.hr/sendNeedMsgToUser',
    des: '招聘需求发送单条消息',
    code: 12935
  },
  {
    url: '/api/v1.hr/getUserInfo',
    des: '招聘需求获取审批用户信息',
    code: 12936
  },
  {
    url: '/api/v1.hr/editNeedApproval',
    des: '修改招聘需求链',
    code: 12937
  },
  {
    url: '/api/v1.hr/sendInterviewEvaluationMsg',
    des: '发送面试反馈提醒',
    code: 12938
  },
  {
    url: '/api/v1.hr/relevantNeedList',
    des: '候选人搜索的招聘需求列表',
    code: 12939
  },
  {
    url: '/api/v1.hr/recruitProgress',
    des: '招聘进展',
    code: 12940
  },
  {
    url: '/api/v1.hr/recruitProgressOverview',
    des: '招聘进展树',
    code: 12941
  },
  {
    url: '/api/v1.hr/needApprovalOffer',
    des: 'offer审批列表',
    code: 12942
  },
  {
    url: '/api/v1.hr/isHr',
    des: '是否有hr权限',
    code: 129423
  },
  {
    url: '/api/v1.hr/needList',
    des: '导入需求列表',
    code: 129424
  },
  {
    url: '/api/v1.hr/resumeAdditional',
    des: '附加信息',
    code: 129425
  },
  {
    url: '/api/v1.hr/getPositionDuties',
    des: '职务',
    code: 129426
  },
  {
    url: '/api/v1.hr/getRemark',
    des: '招聘需求评论',
    code: 129427
  },
  {
    url: '/api/v1.hr/getDetailStageList',
    des: '状态',
    code: 129428
  },
  {
    url: '/api/v1.hr/setResumeStageStatus',
    des: '状态',
    code: 129429
  },
  {
    url: '/v1/marketing/index',
    des: '全民营销',
    code: 129430
  },
  {
    url: '/v1/marketing/get_student_field',
    des: '会员状态',
    code: 129431
  },
  {
    url: '/v1/marketing/student_list',
    des: '学生列表',
    code: 129432
  },
  {
    url: '/v1/marketing/product_list',
    des: '产品订单列表',
    code: 129433
  },
  {
    url: '/v1/marketing/rank_detail',
    des: '排行榜',
    code: 129434
  },
  {
    url: '/v1/performance/position_list',
    des: '绩效职位列表',
    code: 129435
  },
  {
    url: '/v1/performance/position_add',
    des: '添加绩效职位',
    code: 1294381
  },

  {
    url: '/v1/performance/position_detail',
    des: '绩效职位详细',
    code: 129436
  },
  {
    url: '/v1/performance/items',
    des: '绩效项目名称',
    code: 129437
  },
  {
    url: '/v1/performance/formula_add',
    des: '绩效项目编辑',
    code: 129438
  },
  {
    url: '/v1/performance/field_list',
    des: '绩效前提字段列表',
    code: 129439
  },
  {
    url: '/v1/performance/item_detail',
    des: '绩效详情',
    code: 129440
  },
  {
    url: '/v1/performance/data_field_list',
    des: '数据项目列表',
    code: 129441
  },
  {
    url: '/v1/performance/field_del',
    des: '数据项目删除',
    code: 129442
  },
  {
    url: '/v1/performance/field_save',
    des: '数据项目添加、编辑',
    code: 129443
  },
  {
    url: '/v1/performance/salary_list',
    des: '工资单',
    code: 129444
  },
  {
    url: '/v1/performance/base_data',
    des: '基础数据',
    code: 129445
  },
  {
    url: '/v1/performance/item_add',
    des: '项目名称添加',
    code: 129446
  },
  {
    url: '/v1/performance/item_del',
    des: '项目名称删除',
    code: 129447
  },
  {
    url: '/v1/performance/formula_item_add',
    des: '项目名称添加',
    code: 129448
  },
  {
    url: '/v1/performance/detail_new',
    des: '项目名称详情',
    code: 129449
  },
  {
    url: '/v1/performance/change_item_sort',
    des: '项目修改排序',
    code: 129450
  },
  {
    url: '/v1/performance/edit_table',
    des: '绩效列表修改',
    code: 129451
  },
  {
    url: '/v1/performance/salary_confirm',
    des: '工资确认',
    code: 129452
  },
  {
    url: '/v1/performance/check_field',
    des: '项目字段检查',
    code: 129453
  },
  {
    url: '/v1/performance/person_refund_list',
    des: '退费列表',
    code: 129454
  },
  {
    url: '/v1/performance/edit_refund',
    des: '退费金额修改',
    code: 129455
  },
  {
    url: '/v1/performance/refund_log_list',
    des: '退费日志',
    code: 129456
  },
  {
    url: '/v1/performance/check_org_item',
    des: '绩效检查部门',
    code: 1294557
  },
  {
    url: '/v1/performance/item_org_list',
    des: '绩效部门列表',
    code: 129457
  },
  {
    url: '/v1/performance/attr_list',
    des: '绩效属性列表',
    code: 129458
  },
  {
    url: '/v1/performance/import_data_list',
    des: '导入数据查询列表',
    code: 129459
  },
  {
    url: '/v1/performance/edit_import_data',
    des: '导入数据查询修改',
    code: 129460
  },
  {
    url: '/v1/performance/import',
    des: '导入数据上传',
    code: 129461
  },
  {
    url: '/v1/performance/batch_task_list',
    des: '绩效其它列表',
    code: 129462
  },
  {
    url: '/model/v1/api_info',
    des: '模块数据调用',
    code: 129463
  },
  {
    url: '/model/v1/api_list',
    des: '模块数据调用列表',
    code: 129464
  },
  {
    url: '/model/v1/api_del',
    des: '模块数据调用删除',
    code: 129465
  },
  {
    url: '/model/v1/general_query',
    des: '模块数据调用预览',
    code: 129466
  },
  {
    url: '/v1/performance/copy_item',
    des: '复制绩效项',
    code: 129467
  },

  {
    url: '/file/v1/get_complete_category',
    des: '获取文件柜树结构',
    code: 9999
  },
  {
    url: '/file/v1/get_category_list',
    des: '获取文件柜分类下面文件以及文件夹',
    code: 9998
  },
  {
    url: '/file/v1/file_info',
    des: '获取文件信息',
    code: 9997
  },
  {
    url: '/file/v1/edit_file_info',
    des: '获取文件柜修改(以及重命名)',
    code: 9996
  },
  {
    url: '/file/v1/file_info',
    des: '获取文件信息',
    code: 9995
  },
  {
    url: '/file/v1/edit_file_info',
    des: '获取文件柜修改(以及重命名)',
    code: 9994
  },
  {
    url: '/file/v1/upload_file',
    des: '文件柜=>上传文件',
    code: 9993
  },
  {
    url: '/file/v1/get_file_permit',
    des: '文件柜=>权限设置（获取权限）',
    code: 9992
  },
  {
    url: '/file/v1/add_file_permit',
    des: '文件柜=>权限设置（设置权限）',
    code: 9991
  },
  {
    url: '/file/v1/download_file',
    des: '文件柜=>文件夹下载',
    code: 9990
  },
  {
    url: '/file/v1/add_share_file',
    des: '文件柜=>文件共享',
    code: 9989
  },
  {
    url: '/file/v1/del_file_category',
    des: '文件柜=>文件夹及文件删除',
    code: 9988
  },
  {
    url: '/file/v1/empty_trash_can',
    des: '文件柜=>回收站文件删除',
    code: 9987
  },
  {
    url: '/file/v1/recovery_file',
    des: '文件柜=>回收站文件还原',
    code: 9986
  },
  {
    url: '/file/v1/add_star_list',
    des: '文件柜=>文件加星标',
    code: 9985
  },
  {
    url: '/file/v1/add_file_category',
    des: '文件柜=>创建文件夹',
    code: 9984
  },
  {
    url: '/file/v1/user_share_list',
    des: '文件柜=>与我共享',
    code: 9983
  },
  {
    url: '/file/v1/user_star_list',
    des: '文件柜=>已加星标',
    code: 9982
  },
  {
    url: '/file/v1/recycle_bin',
    des: '文件柜=>回收站',
    code: 9981
  },
  {
    url: '/file/v1/get_file_share',
    des: '文件柜=>共享获取数据',
    code: 9972
  },

  {
    url: '/trainplan/v1/expel_comment',
    des: '学习计划=>评论导出',
    code: 1019
  },
  {
    url: '/trainplan/v1/wx_send_speaker',
    des: '学习计划=>导师消息提醒',
    code: 1020
  },
  {
    url: '/api/v1/questionnaire/list',
    des: '学习计划=>试卷',
    code: 1021
  },
  {
    url: '/trainplan/v1/trainAdd',
    des: '学习计划=>添加、修改学习计划',
    code: 1022
  },
  {
    url: '/trainplan/v1/list',
    des: '学习计划=>学习计划列表',
    code: 1023
  },
  {
    url: '/api/v1/questionnaire/questionnaireForTrainPlan',
    des: '学习计划=>我的问卷',
    code: 1024
  },
  {
    url: '/trainplan/v1/delete',
    des: '学习计划=>删除学习计划',
    code: 1025
  },
  {
    url: '/trainplan/v1/detail',
    des: '学习计划=>删除学习计划',
    code: 1026
  },
  {
    url: '/trainplan/v1/delete',
    des: '学习计划=>删除学习计划',
    code: 1027
  },
  {
    url: '/trainplan/v1/upload_file',
    des: '学习计划=>图片、课件上传',
    code: 1028
  },
  {
    url: '/trainplan/v1/quireAdd',
    des: '学习计划=>问卷添加',
    code: 1029
  },
  {
    url: '/trainplan/v1/quireDel',
    des: '学习计划=>问卷删除',
    code: 1030
  },
  {
    url: '/trainplan/v1/tutorDel',
    des: '学习计划=>参与人、导师删除',
    code: 1031
  },
  {
    url: '/trainplan/v1/myDetails',
    des: '学习计划=>学习计划个人简介添加修改',
    code: 1032
  },
  {
    url: '/trainplan/v1/mybaseInfo',
    des: '学习计划=>学习计划个人简介',
    code: 1033
  },
  {
    url: '/trainplan/v1/saveTutor',
    des: '学习计划=>学习计划导师修改',
    code: 1034
  },
  {
    url: '/trainplan/v1/signup',
    des: '学习计划=>学习计划我要报名',
    code: 1035
  },
  {
    url: '/trainplan/v1/videoDel',
    des: '学习计划=>学习计划视频删除',
    code: 1036
  },
  {
    url: '/trainplan/v1/fileDel',
    des: '学习计划=>学习计划图片删除',
    code: 1037
  },
  {
    url: '/trainplan/v1/myQuire',
    des: '学习计划=>学习计划我的问卷、考试测评',
    code: 1038
  },
  {
    url: '/trainplan/v1/comment',
    des: '学习计划=>学习计划我的问卷、考试测评',
    code: 1039
  },
  {
    url: '/trainplan/v1/insertComment',
    des: '学习计划=>学习计划我的问卷、考试测评',
    code: 1040
  },
  {
    url: '/trainplan/v1/trainComment',
    des: '学习计划=>学习计划我的笔记列表',
    code: 1041
  },
  {
    url: '/trainplan/v1/delComment',
    des: '学习计划=>学习计划笔记删除',
    code: 1042
  },
  {
    url: '/trainplan/v1/infoComment',
    des: '学习计划=>学习计划笔记详情',
    code: 1043
  },
  {
    url: '/trainplan/v1/updateComment',
    des: '学习计划=>学习计划笔记修改',
    code: 1044
  },
  {
    url: '/trainplan/v1/personAdd',
    des: '学习计划=>学习计划导师添加',
    code: 1045
  },
  {
    url: '/trainplan/v1/joinAdd',
    des: '学习计划=>学习计划导师添加',
    code: 1047
  },
  {
    url: '/api/v1/student/record',
    des: '顾问=>跟踪名单',
    code: 1048
  },
  {
    url: '/api/v1/student/listRecord',
    des: '顾问=>某一天的跟踪名单',
    code: 1049
  },
  {
    url: '/api/v1/student/monthRcord',
    des: '顾问=>某一天的跟踪名单',
    code: 1050
  },
  {
    url: '/api/v1/hr/orgListTree',
    des: '报表=>树形数据',
    code: 1051
  },
  {
    url: '/api/v1/student/poster',
    des: '海报合成=>海报合成',
    code: 1052
  },
  {
    url: '/api/v1/student/getUserInfo',
    des: '获取用户信息=>获取用户信息',
    code: 1053
  },
  {
    url: '/api/v1/student/holiday_list',
    des: '海报列表=>海报列表',
    code: 1054
  },
  {
    url: '/api/v1/student/holiday_temple',
    des: '海报创建=>海报创建',
    code: 1055
  },
  {
    url: '/api/v1/student/holiday_del',
    des: '海报删除=>海报删除',
    code: 1056
  },
  {
    url: '/api/v1/student/holiday_info',
    des: '海报信息=>海报信息',
    code: 1057
  },
  {
    url: '/api/v1/student/holiday_info',
    des: '海报信息=>海报信息',
    code: 1057
  },
  {
    url: '/api/v1/student/holiday_copy',
    des: '海报复制=>海报复制',
    code: 1058
  },

  {
    url: '/school/v1/shool_seal',
    des: '公章修改=>公章修改',
    code: 1059
  },
  {
    url: '/api/v1/activity/template_action',
    des: '活动方案=>活动方案创建',
    code: 1060
  },
  {
    url: '/api/v1/activity/upload_base64',
    des: '活动方案=>上传base64图片',
    code: 1061
  },
  {
    url: '/api/v1/activity/template_list',
    des: '活动方案=>活动方案库列表',
    code: 1062
  },
  {
    url: '/api/v1/activity/template_del',
    des: '活动方案=>活动方案库删除',
    code: 1063
  },
  {
    url: '/api/v1/activity/template_info',
    des: '活动方案=>活动方案库详情',
    code: 1064
  },
  {
    url: '/api/v1/activity/template_edit',
    des: '活动方案=>活动方案库修改',
    code: 1065
  },
  {
    url: '/api/v1/activity/activity_flow',
    des: '活动方案=>活动流程',
    code: 1066
  },
  {
    url: '/api/v1/activity/flow_del',
    des: '活动方案=>活动流程删除',
    code: 1067
  },
  {
    url: '/api/v1/activity/marketInsert',
    des: '营销活动=>创建、修改',
    code: 1068
  },
  {
    url: '/api/v1/activity/infoMarket',
    des: '营销活动=>详情',
    code: 1069
  },
  {
    url: '/api/v1/activity/marketDel',
    des: '营销活动=>删除',
    code: 1070
  },
  {
    url: '/api/v1/activity/marketList',
    des: '营销活动=>列表',
    code: 1071
  },

  {
    url: '/work/v1/get_user_list',
    des: '获取成员列表',
    code: 1073
  },
  {
    url: '/work/v1/get_unassigned_list',
    des: '获取离职成员的客户列表',
    code: 1074
  },
  {
    url: '/work/v1/user_transfer',
    des: '客户分配',
    code: 1075
  },
  {
    url: '/work/v1/get_user_group_list',
    des: '获取成员群列表',
    code: 1076
  },
  {
    url: '/work/v1/group_transfer',
    des: '客户群分配',
    code: 1077
  },
  {
    url: '/work/v1/get_new_group_list',
    des: '新建加群列表',
    code: 1078
  },
  {
    url: '/work/v1/get_group_user_list',
    des: '获取群成员列表',
    code: 1079
  },
  {
    url: '/work/v1/get_contact_list',
    des: '客户列表',
    code: 1080
  },
  {
    url: '/work/v1/get_user_total',
    des: '总会员数',
    code: 1084
  },
  {
    url: '/mini/v1/miniList',
    des: '小程序管理列表',
    code: 1081
  },
  {
    url: '/mini/v1/miniDel',
    des: '小程序管理删除',
    code: 1082
  },
  {
    url: '/mini/v1/minAction',
    des: '小程序管理添加、修改',
    code: 1083
  },
  {
    url: '/mini/v1/rent_list',
    des: '房租情况列表',
    code: 1085
  },
  {
    url: '/mini/v1/rentAction',
    des: '房租情况添加、修改',
    code: 1086
  },

  {
    url: '/mini/v1/rentDel',
    des: '房租情况删除',
    code: 1087
  },
  {
    url: '/api/v1.report/signFsourceAnalysis',
    des: '名单来源报表',
    code: 1088
  },
  {
    url: '/api/v1.report/signSourceDistribution',
    des: '名单来源报表',
    code: 1089
  },
  {
    url: '/api/v1.report/PartAreaTree',
    des: '名单来源报表组织架构',
    code: 1090
  },
  {
    url: '/model/v1/content_add',
    des: '通用模型内容添加/修改',
    code: 1091
  },
  {
    url: '/model/v1/content_list',
    des: '通用模型内容添加/修改',
    code: 1092
  },
  {
    url: '/model/v1/count_list',
    des: '通用模型内容添加/修改',
    code: 10921
  },
  {
    url: '/model/v1/content_del',
    des: '通用模型内容删除',
    code: 1093
  },

  {
    url: '/model/v1/model_del',
    des: '通用模型模板删除',
    code: 1094
  },
  {
    url: '/model/v1/field_del',
    des: '通用模型字段删除',
    code: 1095
  },
  {
    url: '/model/v1/category_tree',
    des: '通用模型分类树形结构数据',
    code: 1096
  },
  {
    url: '/model/v1/file_tree',
    des: '通用模型分类树形结构数据',
    code: 1097
  },
  {
    url: '/model/v1/get_files',
    des: '通用模型文件源数据',
    code: 1098
  },
  {
    url: '/api/v1.report/baseReport',
    des: '基本报表',
    code: 1099
  },
  {
    url: '/api/v1.report/class_report',
    des: '上课人数报表',
    code: 1100
  },
  {
    url: '/api/v1.report/student_introduction',
    des: '签单总业绩报表',
    code: 1101
  },
  {
    url: '/api/v1.report/introduction_sign',
    des: '签单总业绩详情',
    code: 1102
  },
  {
    url: '/api/v1.report/class_category',
    des: '课程类别',
    code: 1103
  },
  {
    url: '/api/v1/hr/saveOaSchedul',
    des: '班次操作',
    code: 1104
  },
  {
    url: '/api/v1/hr/oaSchedulList',
    des: '班次列表',
    code: 1105
  },
  {
    url: '/api/v1/hr/delOaSchedu',
    des: '班次删除',
    code: 1106
  },
  {
    url: '/api/v1.report/userSelectClassTableHead',
    des: '排班管理表头',
    code: 1107
  },
  {
    url: '/api/v1.report/oa_schedul_user_list',
    des: '排班列表',
    code: 1108
  },
  {
    url: '/api/v1.report/oa_schedul_report',
    des: '排班列表',
    code: 1109
  },
  {
    url: '/api/v1/hr/userDelClass',
    des: '清空班次',
    code: 1110
  },
  {
    url: '/api/v1/hr/userSelectClass',
    des: '修改班次',
    code: 1111
  },
  {
    url: '/api/v1/hr/userBatchSelectClass',
    des: '批量修改班次',
    code: 1112
  },
  {
    url: '/api/v1/hr/delUserSelectedClass',
    des: '批量清空班次',
    code: 1113
  },
  {
    url: '/api/v1/hr/batchUpdateMultUserClass',
    des: '批量修改某一天班次',
    code: 1114
  },
  {
    url: '/api/v1/hr/batchDelMultUserClass',
    des: '批量删除某一天班次',
    code: 1115
  },
  {
    url: '/work/v1/un_wachat_user',
    des: '未加企业微信会员',
    code: 1116
  },
  {
    url: '/work/v1/un_group_list',
    des: '未加群会员',
    code: 1117
  },
  {
    url: '/work/v1/get_bind_user',
    des: '已绑定并且已加入企业微信',
    code: 1118
  },
  {
    url: '/work/v1/un_bind_user',
    des: '加入企业微信未绑定',
    code: 1119
  },
  {
    url: '/work/v1/get_group_info',
    des: '群详情',
    code: 1120
  },
  {
    url: '/model/v1/ct_list',
    des: '模板列表-任务看板',
    code: 1121
  },
  {
    url: '/model/v1/model_detail',
    des: '模块详情',
    code: 1122
  },
  {
    url: '/api/v1.report/achieve_complete_rate',
    des: '业绩完成率报表',
    code: 1123
  },

  {
    url: '/api/v1/coupon/getSchool',
    des: '中心树状图数据',
    code: 9980
  },
  {
    url: '/api/v1/coupon/getCategory',
    des: '优惠券分类',
    code: 9979
  },
  {
    url: '/api/v1/coupon/getList',
    des: '优惠券table',
    code: 9978
  },
  {
    url: '/api/v1/coupon/detail',
    des: '优惠券详情table',
    code: 9977
  },
  {
    url: '/api/v1/coupon/save',
    des: '优惠券添加',
    code: 9976
  },
  {
    url: '/api/v1/coupon/delete',
    des: '优惠券删除',
    code: 9975
  },
  {
    url: '/file/v1/get_complete_category_next',
    des: '文件树状',
    code: 9974
  },
  {
    url: '/report/v1/product_monthly_performance_supplement',
    des: '新零售统计页面',
    code: 9973
  },
  {
    url: '/report/v1/product_monthly_performance_first',
    des: '新零售统计页面2',
    code: 9972
  },
  {
    url: '/api/v1/hr/recruitManage',
    des: '人事招聘首页 => 招聘管理',
    code: 9971
  },
  {
    url: '/api/v1/hr/personBase',
    des: '简历 人员基本信息',
    code: 9970
  },
  {
    url: '/api/v1/hr/personTags',
    des: '简历 人员基本信息(标签)查',
    code: 9969
  },
  {
    url: '/api/v1/hr/addTag',
    des: '简历 人员基本信息(标签)增',
    code: 9968
  },
  {
    url: '/api/v1/hr/delTag',
    des: '简历 人员基本信息(标签)删',
    code: 9967
  },
  {
    url: '/api/v1/hr/standardResumeDetail',
    des: '简历 人员基本信息(个人资料)',
    code: 9966
  },
  {
    url: '/api/v1/hr/update_resume_photo',
    des: '简历 人员基本信息(个人头像)',
    code: 9965
  },
  {
    url: '/api/v1/hr/enclosure_resume_list',
    des: '简历 人员附件简历(列表)',
    code: 9964
  },
  {
    url: '/api/v1/hr/del_enclosure_resume',
    des: '简历 人员附件简历(删除)',
    code: 9963
  },
  {
    url: '/api/v1/hr/insert_enclosure_resume',
    des: '简历 人员附件简历(新增)',
    code: 9962
  },
  {
    url: '/api/v1/hr/standardResumeDetail',
    des: '简历 获取标准简历信息',
    code: 9961
  },
  {
    url: '/api/v1/hr/add_project_experience',
    des: '简历 编辑项目经验 add',
    code: 9960
  },
  {
    url: '/api/v1/hr/add_work_experience',
    des: '简历 编辑工作经验 add',
    code: 9959
  },
  {
    url: '/api/v1/hr/update_standard_resume',
    des: '简历 编辑标准简历',
    code: 9958
  },
  {
    url: '/school/v1/school_list',
    des: '中心管理筛选列表',
    code: 9957
  },
  {
    url: '/school/v1/school_info',
    des: '中心管理 中心详情',
    code: 9956
  },
  {
    url: '/school/v1/save_images',
    des: '中心管理 中心照片上传',
    code: 9955
  },
  {
    url: '/school/v1/save_map_image',
    des: '中心管理 中心地图上传',
    code: 9954
  },
  {
    url: '/school/v1/save_seal_image',
    des: '中心管理 中心工章上传',
    code: 9953
  },
  {
    url: '/school/v1/save_school_business_license',
    des: '中心营业执照上传',
    code: 995523
  },
  {
    url: '/school/v1/update_school_info',
    des: '中心管理 中心基本信息添加or修改',
    code: 9952
  },
  {
    url: '/school/v1/update_construction',
    des: '中心管理 中心施工信息添加or修改',
    code: 9951
  },
  {
    url: '/api/v1/hr/interview_status_list',
    des: '招聘管理 面试状态接口',
    code: 9950
  },
  {
    url: '/api/v1/hr/del_experience',
    des: '招聘管理 删除工作经验或者项目经验',
    code: 9949
  },
  {
    url: '/api/v1/hr/update_work_experience',
    des: '招聘管理 更新工作经验',
    code: 9948
  },
  {
    url: '/api/v1/hr/update_project_experience',
    des: '招聘管理 更新项目经验',
    code: 9947
  },
  {
    url: '/api/v1/hr/interviewRecord', //未用到
    des: '招聘管理 面试记录',
    code: 9946
  },
  {
    url: '/api/v1/hr/employ_status',
    des: '招聘管理 录用状态(选项用)',
    code: 9945
  },
  {
    url: '/api/v1/hr/qualification_list',
    des: '招聘管理 学历列表',
    code: 9944
  },
  {
    url: '/api/v1/hr/resume_source_list',
    des: '招聘管理 简历来源列表',
    code: 9943
  },
  {
    url: '/api/v1/hr/city_list',
    des: '招聘管理 获取城市列表(有中心的城市)',
    code: 9942
  },
  {
    url: '/api/v1/hr/interview_list',
    des: '招聘管理 简历/人才库(table)',
    code: 9941
  },

  //李泳章爸爸从 5836开始;
  {
    url: '/api/v1/questionnaire/add',
    des: '添加问卷',
    code: 5836100
  },
  {
    url: '/api/v1/questionnaire/recommendlist',
    des: '获取推荐数据',
    code: 5836101
  },
  {
    url: '/api/v1/questionnaire/copy',
    des: '复制问卷',
    code: 5836102
  },
  {
    url: '/api/v1/questionnaire/questionnaireInfo',
    des: '获取卷的详情数据',
    code: 5836103
  },
  {
    url: '/api/v1/questionnaire/edit',
    des: '编辑问卷',
    code: 5836104
  },
  {
    url: '/api/v1/questionnaire/usertestpaperlist',
    des: '获取试卷答案-批改页面数据',
    code: 5836105
  },
  {
    url: '/api/v1/questionnaire/paperinfolist',
    des: '获取试卷详情列表数据-考试试卷批改',
    code: 5836106
  },
  {
    url: '/api/v1/questionnaire/allowuser',
    des: '添加批改人',
    code: 5836107
  },
  {
    url: '/api/v1/questionnaire/removeUser',
    des: '删除考试用户',
    code: 5836108
  },
  {
    url: '/api/v1/qt_writein/correctionsubmit',
    des: '提交批改试卷数据',
    code: 5836109
  },
  {
    url: '/api/v1/questionnaire/do_change',
    des: '用于更新-现只支持发布和取消发布',
    code: 5836110
  },
  {
    url: '/api/v1/questionnaire/getQrcodeUrl',
    des: '获取卷的二维码链接',
    code: 5836111
  },
  {
    url: '/configuration/v1/detail',
    des: '通过label查询系统的基本配置配置',
    code: 5836112
  },
  {
    url: '/api/v1/questionstat/getstat',
    des: '获取统计数据',
    code: 5836113
  },
  {
    url: '/api/v1/questionstat/qaList',
    des: '获取答案信息列表',
    code: 5836114
  },
  {
    url: '/api/v1/questionstat/export',
    des: '问卷数据导出',
    code: 5836115
  },
  {
    url: '/api/v1/questionnaire/userQuerstionList',
    des: '获取试卷用户列表带搜索',
    code: 5836116
  },
  {
    url: '/api/v1/questionnaire/getOptionList',
    des: '获取卷题目列表',
    code: 5836117
  },
  {
    url: '/api/v1/questionnaire/getQuerstionUserAnswerData',
    des: '获取卷的用户填写答案数据',
    code: 5836118
  },
  {
    url: '/api/v1/student/student_map',
    des: '客户筛选',
    code: 5836119
  },
  {
    url: '/api/v1/student/map_base',
    des: '会员分布基础数据',
    code: 5836120
  },
  {
    url: '/api/v1/student/region',
    des: '获取省市区',
    code: 5836121
  },
  {
    url: '/api/v1/hr/class_report',
    des: '数据报表',
    code: 5836122
  },
  {
    url: '/api/v1/todoback/remove_file',
    des: '删除子清单文件',
    code: 5836123
  },
  {
    url: '/api/v1/homework/material_upload',
    des: '课后作业上传素材',
    code: 5836124
  },
  {
    url: '/api/v1/homework/material_list',
    des: '课后作业获取素材',
    code: 5836125
  },
  {
    url: '/api/v1/homeworkpc/question/list',
    des: '课后作业题目列表',
    code: 5836126
  },
  {
    url: '/api/v1/homeworkpc/template/list',
    des: '课后作业模板列表',
    code: 5836127
  },
  {
    url: '/api/v1/homeworkpc/question/delete',
    des: '课后作业删除题目',
    code: 5836128
  },
  {
    url: '/api/v1/homeworkpc/question/add',
    des: '课后作业添加题目',
    code: 5836129
  },
  {
    url: '/api/v1/homeworkpc/template/add',
    des: '课后作业添加模板',
    code: 5836130
  },
  {
    url: '/api/v1/homeworkpc/template/delete',
    des: '课后作业删除模板',
    code: 5836131
  },
  {
    url: '/api/v1/homeworkpc/template/edit',
    des: '课后作业编辑模板',
    code: 5836132
  },
  {
    url: '/api/v1/homeworkpc/question/info',
    des: '课后作业题目信息',
    code: 5836133
  },
  {
    url: '/api/v1/homeworkpc/question/edit',
    des: '课后作业编辑题目',
    code: 5836134
  },
  {
    url: '/user/v1/user_epidemic_list',
    des: '获取员工疫情情况（地图）',
    code: 5836135
  },
  {
    url: '/api/v1/questionnaire/GetQuestionnaireInfoForUseTemp',
    des: '问卷使用模板数据',
    code: 5836136
  },
  {
    url: '/api/v1/questionnaire/GetQuestionnaireInfoForUseTemp',
    des: '问卷使用模板数据',
    code: 5836136
  },
  {
    url: '/api/v1/homework/material_del',
    des: '删除素材',
    code: 5836137
  },
  {
    url: '/api/v1/homework/rename',
    des: '素材重命名',
    code: 5836138
  },
  {
    url: '/recording/v1/get_list',
    des: '录音管理列表',
    code: 5836139
  },
  {
    url: '/recording/v1/delete',
    des: '录音管理删除',
    code: 5836140
  },
  {
    url: '/recording/v1/add',
    des: '录音管理添加',
    code: 5836141
  },
  {
    url: '/recording/v1/get_info',
    des: '获取录音信息',
    code: 5836142
  },
  {
    url: '/recording/v1/edit',
    des: '保存编辑',
    code: 5836143
  },
  {
    url: '/trainplan/v1/create_video_record',
    des: '添加观看视频记录',
    code: 5836144
  },
  {
    url: '/trainplan/v1/get_video_record',
    des: '获取视频播放时长数据',
    code: 5836145
  },
  {
    url: '/trainplan/v1/video_record_export',
    des: '观看视频记录导出',
    code: 5836146
  },
  {
    url: '/recording/v1/get_bgmusic_list',
    des: '获取背景音乐列表',
    code: 5836147
  },
  {
    url: '/recording/v1/upload_audio',
    des: '录音管理音频文件上传接口',
    code: 5836148
  },
  {
    url: '/api/v1/questionnaire/export',
    des: '题目信息导出',
    code: 5836149
  },
  {
    url: '/api/v1/homeworkpc/workbook/get_workbook_list',
    des: '获取练习册题目列表',
    code: 5836150
  },
  {
    url: '/api/v1/homeworkpc/workbook/deleteWorkbook',
    des: '删除练习册',
    code: 5836151
  },
  {
    url: '/api/v1/homeworkpc/workbook/addWorkbook',
    des: '添加练习册',
    code: 5836152
  },
  {
    url: '/api/v1/homeworkpc/workbook/get_workbook_info',
    des: '获取练习册详情页数据',
    code: 5836153
  },
  {
    url: '/api/v1/homeworkpc/workbook/editWorkbook',
    des: '编辑练习册',
    code: 5836154
  },
  {
    url: '/api/v1/homeworkpc/workbook/get_workbook_question',
    des: '获取练习册题目列表',
    code: 5836155
  },
  {
    url: '/report/v1/refundintention',
    des: '退费报表',
    code: 5836156
  },
  {
    url: '/report/v1/refund_list',
    des: '退费意向更多',
    code: 5836157
  },
  {
    url: '/report/v1/refund_tasks',
    des: '退费审批更多',
    code: 5836158
  },
  {
    url: '/report/v1/record_statics',
    des: '录音管理',
    code: 5836159
  },
  {
    url: '/report/v1/record_list',
    des: '录音管理详情',
    code: 5836160
  },
  {
    url: '/report/v1/sayhi_statics',
    des: '问候管理',
    code: 5836161
  },
  {
    url: '/report/v1/sayhi_list',
    des: '问候管理详情',
    code: 5836162
  },
  {
    url: '/model/v1/save_model',
    des: '新建/修改模型',
    code: 5836163
  },
  {
    url: '/model/v1/model_list',
    des: '模型列表',
    code: 5836164
  },
  {
    url: '/model/v1/field_list',
    des: '模块的字段列表',
    code: 5836165
  },
  {
    url: '/model/v1/save_field',
    des: '添加/修改 字段',
    code: 5836166
  },
  {
    url: '/api/v1/questionnaire/get_qbh_list',
    des: '获取随机试题',
    code: 5836167
  },
  {
    url: '/api/v1/homeworkpc/question/getCategoryQuestionAll',
    des: '添加题目',
    code: 5836168
  },
  {
    url: '/api/v1/homeworkpc/workbook/workbook_info_removeid',
    des: '获取练习册详情',
    code: 5836169
  },
  {
    url: '/coursemanage/v1/list',
    des: '课程列表',
    code: 5836170
  },
  {
    url: '/coursemanage/v1/categoryHandle',
    des: '课程分类处理（添加、修改、删除）',
    code: 5836171
  },
  {
    url: '/coursemanage/v1/save_course',
    des: '保存课程信息',
    code: 5836172
  },
  {
    url: '/coursemanage/v1/delete_course',
    des: '删除课程',
    code: 5836173
  },
  {
    url: '/coursemanage/v1/get_course_info',
    des: '获取课程内容',
    code: 5836174
  },
  {
    url: '/coursemanage/v1/get_course_content',
    des: '获取课程内容',
    code: 5836175
  },
  {
    url: '/coursemanage/v1/get_course_content',
    des: '获取课程内容',
    code: 5836176
  },
  {
    url: '/coursemanage/v1/save_content',
    des: '添加(保存)课程内容',
    code: 5836177
  },
  {
    url: '/coursemanage/v1/get_content_info',
    des: '获取课程内容信息',
    code: 5836178
  },
  {
    url: '/coursemanage/v1/delete_content',
    des: '删除课程内容',
    code: 5836179
  },
  {
    url: '/coursepack/v1/list',
    des: '课包列表',
    code: 5836180
  },
  {
    url: '/coursepack/v1/delete_pack',
    des: '删除课包',
    code: 5836181
  },
  {
    url: '/coursepack/v1/get_pack_info',
    des: '获取课包信息',
    code: 5836182
  },
  {
    url: '/coursepack/v1/save_pack',
    des: '课包保存',
    code: 5836183
  },
  {
    url: '/coursepack/v1/get_course_list',
    des: '获取课程列表',
    code: 5836184
  },
  {
    url: '/api/v1/activity/new_salescourse',
    des: '引流订单',
    code: 5836185
  },
  {
    url: '/market/v1/market_person_list',
    des: '负责人列表',
    code: 5836186
  },
  {
    url: '/distribution/v1/templateMaterialList',
    des: '分销素材模板列表',
    code: 5836187
  },
  {
    url: '/distribution/v1/deleteTemplateMaterial',
    des: '删除模板素材',
    code: 5836188
  },
  {
    url: '/distribution/v1/editTemplateMaterial',
    des: '添加/编辑素材模板',
    code: 5836189
  },
  {
    url: '/distribution/v1/editShare',
    des: '添加/编辑分享',
    code: 5836190
  },
  {
    url: '/distribution/v1/extensionList',
    des: '推广计划列表',
    code: 5836191
  },
  {
    url: '/distribution/v1/extensionPlanDetail',
    des: '推广计划的详情',
    code: 5836192
  },
  {
    url: '/distribution/v1/editPlanStatus',
    des: '设置推广计划的状态',
    code: 5836193
  },
  {
    url: '/distribution/v1/deleteExtensionPlan',
    des: '删除推广计划',
    code: 5836194
  },
  {
    url: '/distribution/v1/copyExtensionPlan',
    des: '复制推广计划',
    code: 5836195
  },
  {
    url: '/distribution/v1/extensionPlanStudentList',
    des: '推广计划学生名单',
    code: 5836196
  },
  {
    url: '/api/v1/homeworkpc/question/updateBookQuestion',
    des: '更新练习排序',
    code: 5836197
  },
  {
    url: '/api/v1/activity/salsecourse',
    des: '获取线上课包销售数据',
    code: 7000001
  },
  {
    url: '/api/v1/homeworkpc/question/copyQuestion',
    des: '复制题目',
    code: 7000002
  },
  {
    url: '/api/v1/homeworkpc/workbook/get_category_question_list',
    des: '复制题目',
    code: 7000003
  },
  {
    url: '/api/v1/homeworkpc/categoryOptHandleForWorkBook',
    des: '操作练习册分类节点',
    code: 7000004
  },
  {
    url: '/api/v1/homeworkpc/workbook/add_workbook_question',
    des: '操作练习册分类节点',
    code: 7000005
  },
  {
    url: '/api/v1/homeworkpc/workbook/delete_book_question',
    des: '操作练习册分类节点',
    code: 7000006
  },
  {
    url: '/api/v1/homeworkpc/workbook/set_workbook_config',
    des: '操作练习册分类节点',
    code: 7000007
  },
  {
    url: '/cloudcoach/v1/getCloudcoachReportData',
    des: '每日辅导报表',
    code: 7000008
  },
  {
    url: '/cloudcoach/v1/getCloudcoachReportBySchool',
    des: '每日辅导报表(中心)',
    code: 7000009
  },
  {
    url: '/cloudcoach/v1/getCoachReportNew',
    des: '每日辅导报表(新)',
    code: 7000010
  },
  {
    url: '/cloudcoach/v1/getUserList',
    des: '每日辅导用户列表',
    code: 7000011
  },
  {
    url: '/cloudcoach/v1/getOrg',
    des: '每日辅导树',
    code: 7000012
  },
  {
    url: '/api/v1/questionnaire/sendResitNotify',
    des: '发送重考通知',
    code: 7000013
  },
  {
    url: '/market/v1/parttime_user_list',
    des: '兼职管理列表',
    code: 8000001
  },
  {
    url: '/market/v1/get_city_list',
    des: '获取城市列表',
    code: 8000002
  },
  {
    url: '/market/v1/channel_list',
    des: '获取渠道列表',
    code: 8000003
  },
  {
    url: '/market/v1/parttime_user_info',
    des: '兼职个人信息',
    code: 8000004
  },
  {
    url: '/market/v1/activity_list',
    des: '活动列表信息',
    code: 8000005
  },
  {
    url: '/market/v1/get_city_schools',
    des: '中心列表',
    code: 8000006
  },
  // {
  //     url: '/market/v1/parttime_user_info?Id=2779fd3b09040c70ee1f263acd7abb0e',
  //     des: '兼职个人信息',
  //     code: 8000007
  // },
  {
    url: '/market/v1/parttime_user_edit',
    des: '编辑兼职信息',
    code: 8000008
  },
  {
    url: '/market/v1/get_level_list',
    des: '等级数据',
    code: 8000009
  },
  {
    url: '/market/v1/channel_info',
    des: '渠道信息详情',
    code: 8000010
  },
  {
    url: '/market/v1/channel_save',
    des: '渠道信息添加/修改',
    code: 8000011
  },
  {
    url: '/market/v1/parttime_level_list',
    des: '兼职等级列表',
    code: 8000012
  },
  {
    url: '/market/v1/parttime_level_save',
    des: '兼职等级添加/修改',
    code: 8000013
  },
  {
    url: '/market/v1/get_source',
    des: '所属来源列表',
    code: 8000014
  },
  {
    url: '/market/v1/activity_detail',
    des: '活动详情',
    code: 8000015
  },
  {
    url: '/market/v1/activity_save',
    des: '市场活动创建/修改',
    code: 8000016
  },
  {
    url: '/market/v1/activity_del',
    des: '活动删除',
    code: 8000017
  },
  {
    url: '/market/v1/channel_del',
    des: '渠道删除',
    code: 8000018
  },
  {
    url: '/market/v1/parttime_user_del',
    des: '兼职删除',
    code: 8000019
  },
  {
    url: '/market/v1/activity_student_list',
    des: '活动详情-名单明细搜索',
    code: 8000020
  },
  {
    url: '/market/v1/parttime_level_info',
    des: '等级详细信息',
    code: 8000021
  },
  {
    url: '/market/v1/get_city_channels',
    des: '根据城市获取兼职、渠道',
    code: 8000022
  },
  {
    url: '/market/v1/batch_change_school',
    des: '名单分配',
    code: 8000023
  },
  {
    url: '/market/v1/get_school_and_callcenter',
    des: '名单分配-获取中心列表',
    code: 8000024
  },
  {
    url: '/market/v1/get_person_list',
    des: '名单分配-根据用户/中心 获取顾问列表',
    code: 8000025
  },
  {
    url: '/market/v1/batch_change_school',
    des: '名单分配',
    code: 8000026
  },
  {
    url: '/market/v1/parttime_level_del',
    des: '兼职等级删除',
    code: 8000027
  },
  {
    url: '/market/v1/get_activity_type',
    des: '活动类型',
    code: 8000028
  },
  {
    url: '/report/v1/refundReport',
    des: '退费报表饼图数据',
    code: 8000029
  },
  {
    url: '/market/v1/activity_copy',
    des: '市场活动复制',
    code: 8000030
  },
  {
    url: '/report/v1/refundlist',
    des: '退费报表饼图数据明细',
    code: 8000031
  },
  {
    url: '/market/v1/excel_import',
    des: '名单导入',
    code: 8000032
  },
  {
    url: '/model/v1/search_student_list',
    des: '搜索学生',
    code: 8000033
  },
  {
    url: '/distribution/v1/editBaseExtension',
    des: '添加/编辑推广计划基本信息',
    code: 8000034
  },
  {
    url: '/distribution/v1/editCommissionRule',
    des: '添加/编辑佣金规则',
    code: 8000035
  },
  {
    url: '/market/v1/channel_data',
    des: '名单总计/渠道统计数据',
    code: 8000036
  },
  {
    url: '/market/v1/market_report',
    des: '市场报表(数据统计)',
    code: 8000037
  },
  {
    url: '/okr/v1/getProjectList',
    des: 'OKR项目列表',
    code: 8000038
  },
  {
    url: '/okr/v1/delProject',
    des: 'OKR首页删除',
    code: 8000039
  },
  {
    url: '/okr/v1/getOkrList',
    des: 'OKR列表',
    code: 8000040
  },
  {
    url: '/okr/v1/getProjectInfo',
    des: 'OKR获取项目信息',
    code: 8000041
  },
  {
    url: '/okr/v1/delProject',
    des: 'OKR首页删除',
    code: 8000042
  },
  {
    url: '/okr/v1/SaveData',
    des: 'OKR首页 添加|修改 项目',
    code: 8000043
  },
  {
    url: '/okr/v1/getComment',
    des: 'OKR获取评论信息',
    code: 8000044
  },
  {
    url: '/okr/v1/addComment',
    des: 'OKR添加评论信息',
    code: 8000045
  },
  {
    url: '/okr/v1/setValue',
    des: 'OKR修改进度',
    code: 8000046
  },
  {
    url: '/okr/v1/add',
    des: '添加OKR',
    code: 8000047
  },
  {
    url: '/okr/v1/delOkr',
    des: '删除OKR',
    code: 8000048
  },
  {
    url: '/okr/v1/getOkrInfo',
    des: '删除OKR',
    code: 8000049
  },
  {
    url: '/okr/v1/add',
    des: '添加OKR',
    code: 8000052
  },
  {
    url: '/okr/v1/setWeight',
    des: '设置权重',
    code: 8000050
  },
  {
    url: '/okr/v1/setScore',
    des: '设置分数',
    code: 8000051
  },
  {
    url: '/okr/v1/move',
    des: 'OKR拖动',
    code: 8000052
  },
  {
    url: '/okr/v1/positionSort',
    des: 'OKR外部拖动',
    code: 8000053
  },
  {
    url: '/okr/v1/delComment',
    des: 'OKR删除评论',
    code: 8000057
  },
  {
    url: '/okr/v1/getAlignmentOList',
    des: 'O列表',
    code: 8000058
  },
  {
    url: '/okr/v1/getOBindList',
    des: '对齐数据',
    code: 8000060
  },
  {
    url: '/okr/v1/getOChildrenList',
    des: 'O详情',
    code: 8000061
  },
  {
    url: '/interpolation/v1/main_list',
    des: '候选人管理',
    code: 8000062
  },
  {
    url: '/interpolation/v1/main_number',
    des: '内推顶部tab数据',
    code: 8000063
  },
  {
    url: '/api/v1/hr/getPositionList',
    des: '内推tree',
    code: 8000064
  },
  {
    url: '/okr/v1/alignment',
    des: 'ork对齐视图',
    code: 8000065
  },
  {
    url: '/interpolation/v1/prize_send',
    des: '内推发放奖励',
    code: 8000066
  },
  {
    url: '/interpolation/v1/position_catelist',
    des: '职能类型列表',
    code: 8000067
  },
  {
    url: '/interpolation/v1/need_position_save',
    des: '添加职位',
    code: 8000068
  },
  {
    url: '/interpolation/v1/need_position_del',
    des: '职位删除',
    code: 8000069
  },
  {
    url: '/interpolation/v1/need_position_list',
    des: '职位列表',
    code: 8000070
  },
  {
    url: '/interpolation/v1/rule_list',
    des: '奖励规则列表',
    code: 8000071
  },
  {
    url: '/interpolation/v1/rule_del',
    des: '奖励规则删除',
    code: 8000072
  },
  {
    url: '/interpolation/v1/position_tree',
    des: '职级树',
    code: 8000073
  },
  {
    url: '/interpolation/v1/relation_postion_list',
    des: '关联职级列表',
    code: 8000074
  },
  {
    url: '/interpolation/v1/rule_save',
    des: '奖励规则添加修改',
    code: 8000075
  },
  {
    url: '/recording/v1/setLabel',
    des: '设置故事标签',
    code: 8000076
  },
  {
    url: '/interpolation/v1/get_user_correct_list',
    des: '获取某个人推荐的转正的人员信息',
    code: 8000077
  },
  {
    url: '/recording/v1/getTeacherStoryData',
    des: '获取老师故事数据',
    code: 8000078
  },
  {
    url: '/okr/v1/getAlignmentProjectList',
    des: '获取对齐项目列表',
    code: 8000079
  },
  {
    url: '/okr/v1/followProject',
    des: '关注|取消关注',
    code: 8000080
  },
  {
    url: '/okr/v1/getProjectDynamic',
    des: '获取项目动态',
    code: 8000081
  },
  {
    url: '/cloudcoach/v1/getStudentLogs',
    des: '获取每日辅导记录',
    code: 8000082
  },
  {
    url: '/workorder/v1/left_selector',
    des: '工单左侧筛选栏',
    code: 8000083
  },
  {
    url: '/workorder/v1/workorder_list',
    des: '工单列表',
    code: 8000084
  },
  {
    url: '/workorder/v1/get_task_number',
    des: '工单列表顶部数据',
    code: 8000085
  },
  {
    url: '/workorder/v1/selection_tool',
    des: '工单列表基础数据',
    code: 8000086
  },
  {
    url: '/workorder/v1/get_category_template',
    des: '根据工单分类获取模板',
    code: 8000087
  },
  {
    url: '/workorder/v1/get_rule_template',
    des: '获取规则模板',
    code: 8000088
  },
  {
    url: '/workorder/v1/task_add',
    des: '创建工单',
    code: 8000089
  },
  {
    url: '/workorder/v1/task_detail',
    des: '工单详情',
    code: 8000090
  },
  {
    url: '/workorder/v1/edit_approveuser',
    des: '修改指派人↑',
    code: 8000091
  },
  {
    url: '/workorder/v1/edit_senduser',
    des: '修改抄送人↑',
    code: 8000092
  },
  {
    url: '/workorder/v1/task_del',
    des: '撤回工单↑',
    code: 8000094
  },
  {
    url: '/workorder/v1/add_comment',
    des: '添加评论',
    code: 8000095
  },
  {
    url: '/workorder/v1/check_evaluate',
    des: '判断是否存在未评论的工单',
    code: 8000096
  },
  {
    url: '/workorder/v1/get_task_status',
    des: '判断是否存在未评论的工单',
    code: 8000097
  },
  {
    url: '/workorder/v1/edit_task_status',
    des: '修改工单进度',
    code: 8000098
  },
  {
    url: '/workorder/v1/add_evaluete',
    des: '评价工单',
    code: 8000099
  },
  {
    url: '/workorder/v1/complete_vip_feedback',
    des: '答复会员',
    code: 8000100
  },
  {
    url: '/v1/ReportCenter/data',
    des: '新报表中心统一数据获取入口',
    code: 8000101
  },
  {
    url: '/v1/ReportCenter/event',
    des: '新报表中心某些事件触发',
    code: 8000102
  },
  {
    url: '/okr/v1/copy_project',
    des: '复制项目',
    code: 8000103
  },
  {
    url: '/okr/v1/getRelevantAlignmentList',
    des: '获取相关对齐数据',
    code: 8000104
  },
  {
    url: '/okr/v1/create_alignment_tree',
    des: '生成对齐树',
    code: 8000105
  },
  {
    url: '/okr/v1/project_list',
    des: '项目列表',
    code: 8000106
  },
  {
    url: '/workorder/v1/urge',
    des: '工单催一下',
    code: 8000107
  },
  {
    url: '/workorder/v1/refund_info',
    des: '退费工单默认信息',
    code: 8000108
  },
  {
    url: '/workorder/v1/task_edit',
    des: '编辑工单',
    code: 8000109
  },
  {
    url: '/workorder/v1/submit_pay_info',
    des: '退费工单申请付款',
    code: 8000110
  },
  {
    url: '/report/v1/class_consume_report',
    des: '课耗业绩',
    code: 8000111
  },
  {
    url: '/report/v1/wandou_report',
    des: 'wandou报表',
    code: 8000112
  },
  {
    url: '/report/v1/update_student_remark',
    des: '修改学生备注',
    code: 8000113
  },
  {
    url: '/report/v1/support_list',
    des: '支援列表',
    code: 8000114
  },
  {
    url: '/report/v1/join_support',
    des: '参与支援',
    code: 8000115
  },
  {
    url: '/report/v1/cancel_support',
    des: '取消支援',
    code: 8000116
  },
  {
    url: '/v1/workreport/list',
    des: '日报周报',
    code: 8000117
  },
  {
    url: '/v1/calendar/data',
    des: '日历数据',
    code: 8000118
  },
  {
    url: '/v1/calendar/followlist',
    des: '日历-关注列表',
    code: 8000119
  },
  {
    url: '/v1/calendar/add_follow',
    des: '添加关注',
    code: 8000120
  },
  {
    url: '/v1/calendar/event_add',
    des: '添加事件',
    code: 8000121
  },
  {
    url: '/v1/calendar/event_del',
    des: '删除事件',
    code: 8000122
  },
  {
    url: '/v1/calendar/event',
    des: '获取事件详情',
    code: 8000123
  },
  {
    url: '/v1/calendar/event_edit',
    des: '保存编辑事件',
    code: 8000124
  },
  {
    url: '/v1/calendar/set_event_time',
    des: '时间更新',
    code: 8000126
  },
  {
    url: '/v1/temp/temp_money_field',
    des: '薪资信息录入',
    code: 8000125
  },
  {
    url: '/report/v1/renew_statistics',
    des: '续费跟踪统计',
    code: 8000126
  },
  {
    url: '/v1/qmexchange/code_package_list',
    des: '启蒙兑换码列表',
    code: 8000127
  },
  {
    url: '/v1/qmexchange/getAICourseSetting',
    des: '获取AI系统课-体验课程设置（兑换管理使用）',
    code: 8000128
  },
  {
    url: '/v1/qmexchange/getAICourseList',
    des: '获取Ai课程列表',
    code: 8000129
  },
  {
    url: '/v1/qmexchange/getCourseList',
    des: '核心课列表',
    code: 8000130
  },
  {
    url: '/v1/qmexchange/queryCategoryList',
    des: '核心课程类别查询列表',
    code: 8000131
  },
  {
    url: '/v1/qmexchange/quereyLectureList',
    des: '成长频道课程查询列表',
    code: 8000132
  },
  {
    url: '/v1/qmexchange/queryLessonList',
    des: '核心课课程课时模块-查询核心课程课时查询列表包含未制作完成课时（兑换码新加）',
    code: 8000133
  },
  {
    url: '/v1/qmexchange/getSendChannel',
    des: '渠道列表',
    code: 8000134
  },
  {
    url: '/v1/qmexchange/add',
    des: '添加兑换码',
    code: 8000135
  },
  {
    url: '/v1/qmexchange/updateCodePackageState',
    des: '更新兑换码',
    code: 8000136
  },
  {
    url: '/v1/qmexchange/getPackageCodeDetail',
    des: '获取兑换码详情',
    code: 8000137
  },
  {
    url: '/v1/qmexchange/edit',
    des: '保存修改',
    code: 8000138
  },
  {
    url: '/v1/qmexchange/getCodeList',
    des: '获取兑换码列表',
    code: 8000139
  },
  {
    url: '/v1/qmexchange/updateCodeState',
    des: '更新兑换码状态',
    code: 8000140
  },
  {
    url: '/v1/qmexchange/export_code',
    des: '导出兑换码',
    code: 8000141
  },
  {
    url: '/v1/salesperson/student_data',
    des: '客户数据',
    code: 8000142
  },
  {
    url: '/v1/salesperson/sales_info',
    des: '数据汇总',
    code: 8000143
  },
  {
    url: '/v1/salesperson/rank',
    des: '排行榜',
    code: 8000144
  },
  {
    url: '/v1/salesperson/search_user',
    des: '筛选部门员工',
    code: 8000145
  },
  {
    url: '/v1/salesperson/calendar',
    des: '日历数据',
    code: 8000146
  },
  {
    url: '/v1/salesperson/performance_completion_rate',
    des: '业绩完成率',
    code: 8000147
  },
  {
    url: '/v1/salesperson/update_calendar_status',
    des: '更新日历数据',
    code: 8000148
  },
  {
    url: '/report/v1/student_dynamic_api',
    des: '客户动态',
    code: 8000149
  },
  {
    url: '/report/v1/myTask',
    des: '我的任务',
    code: 8000150
  },
  {
    url: '/report/v1/student_dynamic_type_list',
    des: '动态搜索的列表',
    code: 8000151
  },
  {
    url: '/v1/salesperson/student_detail',
    des: '学生具体名单',
    code: 8000152
  },
  {
    url: '/v1/qmpackage/getClassTypeList',
    des: '七田真启蒙套餐类目列表',
    code: 8000153
  },
  {
    url: '/v1/qmpackage/add_package',
    des: '七田真启蒙套餐类添加',
    code: 8000154
  },
  {
    url: '/v1/qmpackage/get_package_list',
    des: '七田真启蒙套餐列表',
    code: 8000155
  },
  {
    url: '/v1/qmpackage/get_package_detail',
    des: '七田真启蒙套餐详细',
    code: 8000156
  },
  {
    url: '/v1/qmpackage/save_package',
    des: '七田真启蒙套餐保存',
    code: 8000157
  },
  {
    url: '/v1/qmpackage/package_del',
    des: '七田真启蒙套餐删除',
    code: 8000158
  },

  {
    url: '/api/v1/activity/salsecourse',
    des: '获取线上课包销售数据',
    code: 7000001
  },
  {
    url: '/api/v1/homeworkpc/question/copyQuestion',
    des: '复制题目',
    code: 7000002
  },
  {
    url: '/api/v1/homeworkpc/workbook/get_category_question_list',
    des: '复制题目',
    code: 7000003
  },
  {
    url: '/api/v1/homeworkpc/categoryOptHandleForWorkBook',
    des: '操作练习册分类节点',
    code: 7000004
  },
  {
    url: '/api/v1/homeworkpc/workbook/add_workbook_question',
    des: '操作练习册分类节点',
    code: 7000005
  },
  {
    url: '/api/v1/homeworkpc/workbook/delete_book_question',
    des: '操作练习册分类节点',
    code: 7000006
  },
  {
    url: '/api/v1/homeworkpc/workbook/set_workbook_config',
    des: '操作练习册分类节点',
    code: 7000007
  },
  {
    url: '/cloudcoach/v1/getCloudcoachReportData',
    des: '每日辅导报表',
    code: 7000008
  },
  {
    url: '/cloudcoach/v1/getCloudcoachReportBySchool',
    des: '每日辅导报表(中心)',
    code: 7000009
  },
  {
    url: '/cloudcoach/v1/getCoachReportNew',
    des: '每日辅导报表(新)',
    code: 7000010
  },
  {
    url: '/cloudcoach/v1/getUserList',
    des: '每日辅导用户列表',
    code: 7000011
  },
  {
    url: '/cloudcoach/v1/getOrg',
    des: '每日辅导树',
    code: 7000012
  },
  {
    url: '/api/v1/questionnaire/sendResitNotify',
    des: '发送重考通知',
    code: 7000013
  },
  {
    url: '/market/v1/parttime_user_list',
    des: '兼职管理列表',
    code: 8000001
  },
  {
    url: '/market/v1/get_city_list',
    des: '获取城市列表',
    code: 8000002
  },
  {
    url: '/market/v1/channel_list',
    des: '获取渠道列表',
    code: 8000003
  },
  {
    url: '/market/v1/parttime_user_info',
    des: '兼职个人信息',
    code: 8000004
  },
  {
    url: '/market/v1/activity_list',
    des: '活动列表信息',
    code: 8000005
  },
  {
    url: '/market/v1/get_city_schools',
    des: '中心列表',
    code: 8000006
  },
  // {
  //     url: '/market/v1/parttime_user_info?Id=2779fd3b09040c70ee1f263acd7abb0e',
  //     des: '兼职个人信息',
  //     code: 8000007
  // },
  {
    url: '/market/v1/parttime_user_edit',
    des: '编辑兼职信息',
    code: 8000008
  },
  {
    url: '/market/v1/get_level_list',
    des: '等级数据',
    code: 8000009
  },
  {
    url: '/market/v1/channel_info',
    des: '渠道信息详情',
    code: 8000010
  },
  {
    url: '/market/v1/channel_save',
    des: '渠道信息添加/修改',
    code: 8000011
  },
  {
    url: '/market/v1/parttime_level_list',
    des: '兼职等级列表',
    code: 8000012
  },
  {
    url: '/market/v1/parttime_level_save',
    des: '兼职等级添加/修改',
    code: 8000013
  },
  {
    url: '/market/v1/get_source',
    des: '所属来源列表',
    code: 8000014
  },
  {
    url: '/market/v1/activity_detail',
    des: '活动详情',
    code: 8000015
  },
  {
    url: '/market/v1/activity_save',
    des: '市场活动创建/修改',
    code: 8000016
  },
  {
    url: '/market/v1/activity_del',
    des: '活动删除',
    code: 8000017
  },
  {
    url: '/market/v1/channel_del',
    des: '渠道删除',
    code: 8000018
  },
  {
    url: '/market/v1/parttime_user_del',
    des: '兼职删除',
    code: 8000019
  },
  {
    url: '/market/v1/activity_student_list',
    des: '活动详情-名单明细搜索',
    code: 8000020
  },
  {
    url: '/market/v1/parttime_level_info',
    des: '等级详细信息',
    code: 8000021
  },
  {
    url: '/market/v1/get_city_channels',
    des: '根据城市获取兼职、渠道',
    code: 8000022
  },
  {
    url: '/market/v1/batch_change_school',
    des: '名单分配',
    code: 8000023
  },
  {
    url: '/market/v1/get_school_and_callcenter',
    des: '名单分配-获取中心列表',
    code: 8000024
  },
  {
    url: '/market/v1/get_person_list',
    des: '名单分配-根据用户/中心 获取顾问列表',
    code: 8000025
  },
  {
    url: '/market/v1/batch_change_school',
    des: '名单分配',
    code: 8000026
  },
  {
    url: '/market/v1/parttime_level_del',
    des: '兼职等级删除',
    code: 8000027
  },
  {
    url: '/market/v1/get_activity_type',
    des: '活动类型',
    code: 8000028
  },
  {
    url: '/report/v1/refundReport',
    des: '退费报表饼图数据',
    code: 8000029
  },
  {
    url: '/market/v1/activity_copy',
    des: '市场活动复制',
    code: 8000030
  },
  {
    url: '/report/v1/refundlist',
    des: '退费报表饼图数据明细',
    code: 8000031
  },
  {
    url: '/market/v1/excel_import',
    des: '名单导入',
    code: 8000032
  },
  {
    url: '/model/v1/search_student_list',
    des: '搜索学生',
    code: 8000033
  },
  {
    url: '/distribution/v1/editBaseExtension',
    des: '添加/编辑推广计划基本信息',
    code: 8000034
  },
  {
    url: '/distribution/v1/editCommissionRule',
    des: '添加/编辑佣金规则',
    code: 8000035
  },
  {
    url: '/market/v1/channel_data',
    des: '名单总计/渠道统计数据',
    code: 8000036
  },
  {
    url: '/market/v1/market_report',
    des: '市场报表(数据统计)',
    code: 8000037
  },
  {
    url: '/okr/v1/getProjectList',
    des: 'OKR项目列表',
    code: 8000038
  },
  {
    url: '/okr/v1/delProject',
    des: 'OKR首页删除',
    code: 8000039
  },
  {
    url: '/okr/v1/getOkrList',
    des: 'OKR列表',
    code: 8000040
  },
  {
    url: '/okr/v1/getProjectInfo',
    des: 'OKR获取项目信息',
    code: 8000041
  },
  {
    url: '/okr/v1/delProject',
    des: 'OKR首页删除',
    code: 8000042
  },
  {
    url: '/okr/v1/SaveData',
    des: 'OKR首页 添加|修改 项目',
    code: 8000043
  },
  {
    url: '/okr/v1/getComment',
    des: 'OKR获取评论信息',
    code: 8000044
  },
  {
    url: '/okr/v1/addComment',
    des: 'OKR添加评论信息',
    code: 8000045
  },
  {
    url: '/okr/v1/setValue',
    des: 'OKR修改进度',
    code: 8000046
  },
  {
    url: '/okr/v1/add',
    des: '添加OKR',
    code: 8000047
  },
  {
    url: '/okr/v1/delOkr',
    des: '删除OKR',
    code: 8000048
  },
  {
    url: '/okr/v1/getOkrInfo',
    des: '删除OKR',
    code: 8000049
  },
  {
    url: '/okr/v1/add',
    des: '添加OKR',
    code: 8000052
  },
  {
    url: '/okr/v1/setWeight',
    des: '设置权重',
    code: 8000050
  },
  {
    url: '/okr/v1/setScore',
    des: '设置分数',
    code: 8000051
  },
  {
    url: '/okr/v1/move',
    des: 'OKR拖动',
    code: 8000052
  },
  {
    url: '/okr/v1/positionSort',
    des: 'OKR外部拖动',
    code: 8000053
  },
  {
    url: '/okr/v1/delComment',
    des: 'OKR删除评论',
    code: 8000057
  },
  {
    url: '/okr/v1/getAlignmentOList',
    des: 'O列表',
    code: 8000058
  },
  {
    url: '/okr/v1/getOBindList',
    des: '对齐数据',
    code: 8000060
  },
  {
    url: '/okr/v1/getOChildrenList',
    des: 'O详情',
    code: 8000061
  },
  {
    url: '/interpolation/v1/main_list',
    des: '候选人管理',
    code: 8000062
  },
  {
    url: '/interpolation/v1/main_number',
    des: '内推顶部tab数据',
    code: 8000063
  },
  {
    url: '/api/v1/hr/getPositionList',
    des: '内推tree',
    code: 8000064
  },
  {
    url: '/okr/v1/alignment',
    des: 'ork对齐视图',
    code: 8000065
  },
  {
    url: '/interpolation/v1/prize_send',
    des: '内推发放奖励',
    code: 8000066
  },
  {
    url: '/interpolation/v1/position_catelist',
    des: '职能类型列表',
    code: 8000067
  },
  {
    url: '/interpolation/v1/need_position_save',
    des: '添加职位',
    code: 8000068
  },
  {
    url: '/interpolation/v1/need_position_del',
    des: '职位删除',
    code: 8000069
  },
  {
    url: '/interpolation/v1/need_position_list',
    des: '职位列表',
    code: 8000070
  },
  {
    url: '/interpolation/v1/rule_list',
    des: '奖励规则列表',
    code: 8000071
  },
  {
    url: '/interpolation/v1/rule_del',
    des: '奖励规则删除',
    code: 8000072
  },
  {
    url: '/interpolation/v1/position_tree',
    des: '职级树',
    code: 8000073
  },
  {
    url: '/interpolation/v1/relation_postion_list',
    des: '关联职级列表',
    code: 8000074
  },
  {
    url: '/interpolation/v1/rule_save',
    des: '奖励规则添加修改',
    code: 8000075
  },
  {
    url: '/recording/v1/setLabel',
    des: '设置故事标签',
    code: 8000076
  },
  {
    url: '/interpolation/v1/get_user_correct_list',
    des: '获取某个人推荐的转正的人员信息',
    code: 8000077
  },
  {
    url: '/recording/v1/getTeacherStoryData',
    des: '获取老师故事数据',
    code: 8000078
  },
  {
    url: '/okr/v1/getAlignmentProjectList',
    des: '获取对齐项目列表',
    code: 8000079
  },
  {
    url: '/okr/v1/followProject',
    des: '关注|取消关注',
    code: 8000080
  },
  {
    url: '/okr/v1/getProjectDynamic',
    des: '获取项目动态',
    code: 8000081
  },
  {
    url: '/cloudcoach/v1/getStudentLogs',
    des: '获取每日辅导记录',
    code: 8000082
  },
  {
    url: '/workorder/v1/left_selector',
    des: '工单左侧筛选栏',
    code: 8000083
  },
  {
    url: '/workorder/v1/workorder_list',
    des: '工单列表',
    code: 8000084
  },
  {
    url: '/workorder/v1/get_task_number',
    des: '工单列表顶部数据',
    code: 8000085
  },
  {
    url: '/workorder/v1/selection_tool',
    des: '工单列表基础数据',
    code: 8000086
  },
  {
    url: '/workorder/v1/get_category_template',
    des: '根据工单分类获取模板',
    code: 8000087
  },
  {
    url: '/workorder/v1/get_rule_template',
    des: '获取规则模板',
    code: 8000088
  },
  {
    url: '/workorder/v1/task_add',
    des: '创建工单',
    code: 8000089
  },
  {
    url: '/workorder/v1/task_detail',
    des: '工单详情',
    code: 8000090
  },
  {
    url: '/workorder/v1/edit_approveuser',
    des: '修改指派人↑',
    code: 8000091
  },
  {
    url: '/workorder/v1/edit_senduser',
    des: '修改抄送人↑',
    code: 8000092
  },
  {
    url: '/workorder/v1/task_del',
    des: '撤回工单↑',
    code: 8000094
  },
  {
    url: '/workorder/v1/add_comment',
    des: '添加评论',
    code: 8000095
  },
  {
    url: '/workorder/v1/check_evaluate',
    des: '判断是否存在未评论的工单',
    code: 8000096
  },
  {
    url: '/workorder/v1/get_task_status',
    des: '判断是否存在未评论的工单',
    code: 8000097
  },
  {
    url: '/workorder/v1/edit_task_status',
    des: '修改工单进度',
    code: 8000098
  },
  {
    url: '/workorder/v1/add_evaluete',
    des: '评价工单',
    code: 8000099
  },
  {
    url: '/workorder/v1/complete_vip_feedback',
    des: '答复会员',
    code: 8000100
  },
  {
    url: '/v1/ReportCenter/data',
    des: '新报表中心统一数据获取入口',
    code: 8000101
  },
  {
    url: '/v1/ReportCenter/event',
    des: '新报表中心某些事件触发',
    code: 8000102
  },
  {
    url: '/okr/v1/copy_project',
    des: '复制项目',
    code: 8000103
  },
  {
    url: '/okr/v1/getRelevantAlignmentList',
    des: '获取相关对齐数据',
    code: 8000104
  },
  {
    url: '/okr/v1/create_alignment_tree',
    des: '生成对齐树',
    code: 8000105
  },
  {
    url: '/okr/v1/project_list',
    des: '项目列表',
    code: 8000106
  },
  {
    url: '/workorder/v1/urge',
    des: '工单催一下',
    code: 8000107
  },
  {
    url: '/workorder/v1/refund_info',
    des: '退费工单默认信息',
    code: 8000108
  },
  {
    url: '/workorder/v1/task_edit',
    des: '编辑工单',
    code: 8000109
  },
  {
    url: '/workorder/v1/submit_pay_info',
    des: '退费工单申请付款',
    code: 8000110
  },
  {
    url: '/report/v1/class_consume_report',
    des: '课耗业绩',
    code: 8000111
  },
  {
    url: '/report/v1/wandou_report',
    des: 'wandou报表',
    code: 8000112
  },
  {
    url: '/report/v1/region_report',
    des: 'wandou报表',
    code: 80001121
  },
  {
    url: '/report/v1/update_student_remark',
    des: '修改学生备注',
    code: 8000113
  },
  {
    url: '/report/v1/support_list',
    des: '支援列表',
    code: 8000114
  },
  {
    url: '/report/v1/join_support',
    des: '参与支援',
    code: 8000115
  },
  {
    url: '/report/v1/cancel_support',
    des: '取消支援',
    code: 8000116
  },
  {
    url: '/v1/workreport/list',
    des: '日报周报',
    code: 8000117
  },
  {
    url: '/v1/calendar/data',
    des: '日历数据',
    code: 8000118
  },
  {
    url: '/v1/calendar/followlist',
    des: '日历-关注列表',
    code: 8000119
  },
  {
    url: '/v1/calendar/add_follow',
    des: '添加关注',
    code: 8000120
  },
  {
    url: '/v1/calendar/event_add',
    des: '添加事件',
    code: 8000121
  },
  {
    url: '/v1/calendar/event_del',
    des: '删除事件',
    code: 8000122
  },
  {
    url: '/v1/calendar/event',
    des: '获取事件详情',
    code: 8000123
  },
  {
    url: '/v1/calendar/event_edit',
    des: '保存编辑事件',
    code: 8000124
  },
  {
    url: '/v1/calendar/set_event_time',
    des: '时间更新',
    code: 8000126
  },
  {
    url: '/v1/temp/temp_money_field',
    des: '薪资信息录入',
    code: 8000125
  },
  {
    url: '/report/v1/renew_statistics',
    des: '续费跟踪统计',
    code: 8000126
  },
  {
    url: '/v1/qmexchange/code_package_list',
    des: '启蒙兑换码列表',
    code: 8000127
  },
  {
    url: '/v1/qmexchange/getAICourseSetting',
    des: '获取AI系统课-体验课程设置（兑换管理使用）',
    code: 8000128
  },
  {
    url: '/v1/qmexchange/getAICourseList',
    des: '获取Ai课程列表',
    code: 8000129
  },
  {
    url: '/v1/qmexchange/getCourseList',
    des: '核心课列表',
    code: 8000130
  },
  {
    url: '/v1/qmexchange/queryCategoryList',
    des: '核心课程类别查询列表',
    code: 8000131
  },
  {
    url: '/v1/qmexchange/quereyLectureList',
    des: '成长频道课程查询列表',
    code: 8000132
  },
  {
    url: '/v1/qmexchange/queryLessonList',
    des: '核心课课程课时模块-查询核心课程课时查询列表包含未制作完成课时（兑换码新加）',
    code: 8000133
  },
  {
    url: '/v1/qmexchange/getSendChannel',
    des: '渠道列表',
    code: 8000134
  },
  {
    url: '/v1/qmexchange/add',
    des: '添加兑换码',
    code: 8000135
  },
  {
    url: '/v1/qmexchange/updateCodePackageState',
    des: '更新兑换码',
    code: 8000136
  },
  {
    url: '/v1/qmexchange/getPackageCodeDetail',
    des: '获取兑换码详情',
    code: 8000137
  },
  {
    url: '/v1/qmexchange/edit',
    des: '保存修改',
    code: 8000138
  },
  {
    url: '/v1/qmexchange/getCodeList',
    des: '获取兑换码列表',
    code: 8000139
  },
  {
    url: '/v1/qmexchange/updateCodeState',
    des: '更新兑换码状态',
    code: 8000140
  },
  {
    url: '/v1/qmexchange/export_code',
    des: '导出兑换码',
    code: 8000141
  },
  {
    url: '/v1/salesperson/student_data',
    des: '客户数据',
    code: 8000142
  },
  {
    url: '/v1/salesperson/sales_info',
    des: '数据汇总',
    code: 8000143
  },
  {
    url: '/v1/salesperson/rank',
    des: '排行榜',
    code: 8000144
  },
  {
    url: '/v1/salesperson/search_user',
    des: '筛选部门员工',
    code: 8000145
  },
  {
    url: '/v1/salesperson/calendar',
    des: '日历数据',
    code: 8000146
  },
  {
    url: '/v1/salesperson/performance_completion_rate',
    des: '业绩完成率',
    code: 8000147
  },
  {
    url: '/v1/salesperson/update_calendar_status',
    des: '更新日历数据',
    code: 8000148
  },
  {
    url: '/report/v1/student_dynamic_api',
    des: '客户动态',
    code: 8000149
  },
  {
    url: '/report/v1/myTask',
    des: '我的任务',
    code: 8000150
  },
  {
    url: '/report/v1/student_dynamic_type_list',
    des: '动态搜索的列表',
    code: 8000151
  },
  {
    url: '/v1/salesperson/student_detail',
    des: '学生具体名单',
    code: 8000152
  },
  {
    url: '/v1/qmpackage/getClassTypeList',
    des: '七田真启蒙套餐类目列表',
    code: 8000153
  },
  {
    url: '/v1/qmpackage/add_package',
    des: '七田真启蒙套餐类添加',
    code: 8000154
  },
  {
    url: '/v1/qmpackage/get_package_list',
    des: '七田真启蒙套餐列表',
    code: 8000155
  },
  {
    url: '/v1/qmpackage/get_package_detail',
    des: '七田真启蒙套餐详细',
    code: 8000156
  },
  {
    url: '/v1/qmpackage/save_package',
    des: '七田真启蒙套餐保存',
    code: 8000157
  },
  {
    url: '/v1/qmpackage/package_del',
    des: '七田真启蒙套餐删除',
    code: 8000158
  },

  {
    url: '/v1/plan/plan_list',
    des: '目标列表',
    code: 8000160
  },
  {
    url: '/v1/plan/plan_del',
    des: '目标删除',
    code: 8000161
  },
  {
    url: '/v1/plan/plan_save',
    des: '目标添加修改',
    code: 8000162
  },
  {
    url: '/v1/plan/plan_detail',
    des: '目标详情',
    code: 8000163
  },
  {
    url: '/v1/plan/item_list',
    des: '目标项目列表',
    code: 8000164
  },
  {
    url: '/v1/qm/viporder/get_free_list',
    des: '七田真启蒙vip订单管理列表-领取',
    code: 8000165
  },
  {
    url: '/v1/qm/viporder/get_vip_list',
    des: '七田真启蒙vip订单管理列表-购买',
    code: 8000166
  },
  {
    url: '/v1/qm/viporder/get_vip_manage',
    des: '七田真启蒙vip项目',
    code: 8000167
  },
  {
    url: '/v1/qm/viporder/do_save',
    des: '七田真启蒙添加记录',
    code: 8000168
  },
  {
    url: '/v1/qm/viporder/get_detail',
    des: '七田真启蒙获取vip记录详情',
    code: 8000169
  },
  {
    url: '/v1/qm/viporder/vip_del',
    des: '七田真启蒙vip删除',
    code: 8000170
  },
  {
    url: '/v1/plan/item_save',
    des: '目标项目保存',
    code: 8000200
  },
  {
    url: '/v1/plan/log_list',
    des: '修改日志列表',
    code: 8000201
  },
  {
    url: '/v1/onlineclassource/list',
    des: '线上课课件',
    code: 8000202
  },
  {
    url: '/v1/onlineclassource/category',
    des: '线上课课件-目录',
    code: 8000203
  },
  {
    url: '/v1/onlineclassource/add_sync',
    des: '线上课课件-添加同步',
    code: 8000204
  },
  {
    url: '/v1/onlineclassource/get_file_list',
    des: '线上课课件-获取文件列表',
    code: 8000205
  },
  {
    url: '/v1/performance/level_list',
    des: '',
    code: 8000206
  },
  {
    url: '/v1/performance/positionMatchList',
    des: '职位匹配-未匹配数据',
    code: 8000207
  },
  {
    url: '/v1/performance/getUnMatchUserList',
    des: '职位匹配-未匹配人员',
    code: 8000208
  },
  {
    url: '/v1/performance/getPositionLevel',
    des: '职位匹配-职位级别',
    code: 8000209
  },
  {
    url: '/v1/performance/save',
    des: '职位匹配-操作',
    code: 8000210
  },
  {
    url: '/v1/qm/opencourse/searchUser',
    des: '搜索启蒙用户',
    code: 8000211
  },
  {
    url: '/v1/qm/opencourse/registerUser',
    des: '创建启蒙用户',
    code: 8000212
  },
  {
    url: '/v1/qm/opencourse/openCourse',
    des: '开通课程',
    code: 8000213
  },
  {
    url: '/v1/qm/opencourse/get_open_list',
    des: '获取开通课程列表',
    code: 8000214
  },
  {
    url: '/v1/qm/opencourse/getUserOrderLessonList',
    des: '通过category获取当前分类下的开通课程列表',
    code: 8000215
  },
  //公共接口
  {
    url: '/common/orgs',
    des: '组织架构',
    code: 1072
  },
  {
    url: '/v1/handover/saveItem',
    des: '保存更新项目',
    code: 13000
  },
  {
    url: '/v1/handover/items',
    des: '交接项目',
    code: 13001
  },
  {
    url: '/v1/handover/setRoleItem',
    des: '配置角色绑定项目',
    code: 13002
  },
  {
    url: '/v1/handover/roleItems',
    des: '获取角色绑定项目表',
    code: 13003
  },
  {
    url: '/v1/handover/userItems',
    des: '获取用户交接项目',
    code: 13004
  },
  {
    url: '/v1/handover/userItem',
    des: '提交用户交接项目',
    code: 13005
  },
  //合同扣课单价管理
  {
    url: '/api/v1/ContactHandling/getHandlingList',
    des: '获取合同列表信息',
    code: 80820001
  },
  {
    url: '/api/v1/ContactHandling/getCourseVoucherList',
    des: '获取课卷列表信息',
    code: 80820002
  },
    //节点管理
    {
      url: '/api/v1/OperateNode/getOperateNodeList',
      des: '获取节点信息',
      code: 80830001
    },
    {
      url: '/api/v1/OperateNode/saveOperateNode',
      des: '修改新增节点信息',
      code: 80830002
    },
    {
      url: '/api/v1/OperateNode/operateNodeDel',
      des: '删除节点信息',
      code: 80830003
    },
    {
      url: '/api/v1/OperateNode/getOperateNodeSetList',
      des: '获取节点详情列表信息',
      code: 80831001
    },
    {
      url: '/api/v1/OperateNode/saveOperateNodeSet',
      des: '修改新增节点详情信息',
      code: 80831002
    },
    {
      url: '/api/v1/OperateNode/operateNodeSetDel',
      des: '删除节点详情信息',
      code: 80831003
    },
    //精品课兑换码管理
  {
    url: '/api/v1/ExchangeLog/getExchangeLogList',
    des: '精品课兑换码列表',
    code: 80841001
  },
  {
    url: '/api/v1/ExchangeLog/saveExchangeLog',
    des: '精品课兑换码生成',
    code: 80841002
  },
  {
    url: '/api/v1/ExchangeLog/exchangeLogDel',
    des: '精品课兑换码删除',
    code: 80841003
  },
  //进度详情
  {
    url: '/model/v1/get_message_list',
    des: '任务列表详情',
    code: 80850001
  },
  {
    url: '/model/v1/del_message',
    des: '任务列表详情删除',
    code: 80850002
  },
  {
    url: '/model/v1/save_course_verif',
    des: '课程兑换订单批量核销',
    code: 80860001
  },
 //菜单管理
 {
  url: '/api/v1/systemmenu/getPidNode',
  des: '获取父级节点',
  code: 81800001
  },
   
  //防伪码管理
  {
    url: '/api/v1/hdticket/list',
    des: '防伪码列表',
    code: 80800001
  },
  {
    url: '/api/v1/hdticket/add',
    des: '防伪码添加',
    code: 80800002
  },
  {
    url: '/api/v1/hdticket/makeTicket',
    des: '防伪码生成',
    code: 80800003
  },
  {
    url: '/api/v1/hdticket/ticket_del',
    des: '防伪码删除',
    code: 80800004
  },
  {
    url: '/api/v1/hdticket/get_category_list',
    des: '防伪码分类',
    code: 80800005
  },
  //收费优惠管理
  {
    url: '/v1/classFee/getFeeList',
    des: '收费标准',
    code: 80810001
  },
  {
    url: '/v1/classFee/feeRecovery',
    des: '恢复收费标准',
    code: 80810002
  },
  {
    url: '/v1/classFee/feeDel',
    des: '删除收费标准',
    code: 80810003
  },
  {
    url: '/v1/classFee/checkInContract',
    des: '检测收费标准，优惠方案是否在合同中',
    code: 80810004
  },
  {
    url: '/v1/classFee/getConcessionList',
    des: '课程优惠方案',
    code: 80811001
  },
  {
    url: '/v1/classFee/getConcessionInfo',
    des: '课程优惠方案信息',
    code: 80811002
  },
  {
    url: '/v1/classFee/getCourseVoucherList',
    des: '课程优惠方案赠送券的类别',
    code: 80811003
  }, 
  {
    url: '/v1/classFee/getClassFeeType',
    des: '课程优惠方案收费标准类型',
    code: 80811004
  }, 
  {
    url: '/v1/classFee/saveConcession',
    des: '新增或修改课程优惠方案',
    code: 80811005
  },
  {
    url: '/v1/classFee/concessionDel',
    des: '删除课程优惠方案',
    code: 80811006
  },
  {
    url: '/v1/classFee/concessionRecovery',
    des: '恢复课程优惠方案',
    code: 80811007
  },
  {
    url: '/v1/classFee/getConcessionType',
    des: '优惠方案类型',
    code: 80811008
  },
  {
    url: '/v1/classFee/getConcessionIsMain',
    des: '课程优惠方案类型',
    code: 80811009
  },
  {
    url: '/v1/classFee/getConcessionApplyToObjects',
    des: '课程优惠方案适用对象类型',
    code: 80811010
  },
  {
    url: '/v1/classFee/getProdList',
    des: '物品优惠方案',
    code: 80812001
  },
  {
    url: '/v1/classFee/getProdInfo',
    des: '物品优惠方案信息',
    code: 80812002
  },
  {
    url: '/v1/classFee/getGoodsList',
    des: '物品选择列表',
    code: 80812003
  },
  {
    url: '/v1/classFee/getGoodCategory',
    des: '物品所属大类',
    code: 80812004
  },
  {
    url: '/v1/classFee/getGoodSubCategory',
    des: '物品所属小类',
    code: 80812005
  },
  {
    url: '/v1/classFee/saveProd',
    des: '新增或修改物品优惠方案',
    code: 80812006
  },
  {
    url: '/v1/classFee/prodDel',
    des: '删除物品优惠方案',
    code: 80812007
  },
  {
    url: '/v1/classFee/prodRecovery',
    des: '恢复物品优惠方案',
    code: 80812008
  },
  {
    url: '/v1/classFee/getTicketList',
    des: '优惠券方案',
    code: 80813001
  },
  {
    url: '/v1/classFee/getTicketInfo',
    des: '获取优惠卷方案信息',
    code: 80813002
  },
  {
    url: '/v1/classFee/saveTicket',
    des: '优惠卷新增及修改',
    code: 80813002
  },
  {
    url: '/v1/classFee/ticketDel',
    des: '删除优惠卷',
    code: 80813003
  },
  {
    url: '/v1/classFee/ticketStatus',
    des: '优惠卷状态修改',
    code: 80813004
  },
  {
    url: '/v1/classFee/getHDTicketType',
    des: '优惠券类型',
    code: 80813005
  },
  {
    url: '/v1/classFee/getHDTicketCategoryType',
    des: '优惠券平台类型',
    code: 80813006
  },{
    url: '/v1/classFee/ticketRecovery',
    des: '优惠券恢复',
    code: 80813007
  },
  {
    url: '/v1/classFee/getCourseVouchersList',
    des: '课卷列表',
    code: 80814001
  },
  {
    url: '/v1/classFee/getCourseVoucherStartType',
    des: '课卷使用时间',
    code: 80814002
  },
  {
    url: '/v1/classFee/getCourseVoucherType',
    des: '课券类型',
    code: 80814003
  },
  {
    url: '/v1/classFee/saveCourseVoucher',
    des: '课券增加或修改',
    code: 80814004
  },
  {
    url: '/v1/classFee/courseVoucherStatus',
    des: '修改课券状态',
    code: 80814005
  },
  {
    url: '/v1/classFee/courseVoucherRecovery',
    des: '课券恢复',
    code: 80814006
  },
  {
    url: '/v1/classFee/courseVoucherDel',
    des: '课券删除',
    code: 80814007
  },
  {
    url: '/v1/classFee/getFeeClassList',
    des: '课包简介',
    code: 80810011
  },
  {
    url: '/v1/classFee/getClassType',
    des: '课程类别',
    code: 80810012
  },
  //教案管理
  {
    url: '/v1/teachingPlan/category',
    des: '教案分类',
    code: 20000
  },
  {
    url: '/v1/teachingPlan/plans',
    des: '教案列表',
    code: 20001
  },
  {
    url: '/v1/teachingPlan/detail',
    des: '教案详情',
    code: 20002
  },
  {
    url: '/v1/teachingPlan/filecategories',
    des: '课件分类',
    code: 20003
  },
  {
    url: '/v1/teachingPlan/files',
    des: '课件列表',
    code: 20004
  },
  {
    url: '/v1/teachingPlan/editPlan',
    des: '编辑教案',
    code: 20005
  },
  {
    url: '/v1/teachingPlan/batchSyncFiles',
    des: '批量同步',
    code: 20006
  },
  {
    url: '/v1/teachingPlan/sysfile',
    des: '课件分类列表',
    code: 20007
  },
  {
    url: '/v1/teachingPlan/fileDetail',
    des: '课件详情',
    code: 20008
  },
  {
    url: '/v1/teachingPlan/add_category',
    des: '添加教案分类',
    code: 20009
  },
  {
    url: '/v1/teachingPlan/updateFile',
    des: '同步更新课件',
    code: 20010
  },
  {
    url: '/v1/teachingPlan/deletePlan',
    des: '删除教案',
    code: 20011
  },
  {
    url: '/api/v1/ContactHandling/tempContact',
    des: '获取合同模板',
    code: 20012
  },
  {
    url: '/api/v1/ContactHandling/saveTempContact',
    des: '获取合同模板',
    code: 20013
  },
  {
    url: '/api/v1/ContactHandling/addTempContact',
    des: '获取合同模板',
    code: 20014
  },
  {
    url: '/api/v1/ContactHandling/orgList',
    des: '获取中心',
    code: 20015
  },
  {
    url: '/api/v1/ContactHandling/contacts',
    des: '获取合同模板列表',
    code: 20016
  },
  // 枚举信息
  {
    url: '/v1/classFee/getFeePolicyType',
    des: '获取协议类型',
    code: 300008
  },
  {
    url: '/v1/classFee/getFeeNameType',
    des: '获取合同类型',
    code: 300009
  },
  {
    url: '/v1/classFee/getFeeType',
    des: '购买途径',
    code: 300010
  },
  {
    url: '/v1/classFee/saveFee',
    des: '新增/修改收费标准',
    code: 300011
  },
  {
    url: '/model/v1/test',
    des: '消息群发',
    code: 9999999
  },
  {
    url: '/api/student',
    des: '客户列表',
    code: 300012
  },
]

export default request
