import { asyncRouterMap, constantRouterMap } from '@/config/router-config';

function hasPermission(roles, route) {
    if (route.meta && route.meta.role) {
        let list = [];
        if (route.meta.role.indexOf(roles) >= 0) {
            list.push(route);
        }
        return list.length > 0 ? true : false;
    } else {
        return true
    }
}

const permission = {
    state: {
        routers: constantRouterMap,
        addRouters: []
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers;
            state.routers = constantRouterMap.concat(routers);
        }
    },
    actions: {
        GenerateRoutes({ commit }, data) {
            return new Promise(resolve => {
                const { roles } = data;
                const accessedRouters = asyncRouterMap.filter(v => {
                    if (v.meta) {
                        if (v.meta.role.indexOf(roles) < 0) {
                            return false
                        }
                    }
                    if (hasPermission(roles, v)) {
                        if (v.children && v.children.length > 0) {
                            v.children = v.children.filter(child => {
                                if (hasPermission(roles, child)) {
                                    return child
                                }
                                return false;
                            });
                            return v
                        } else {
                            return v
                        }
                    }
                });
                commit('SET_ROUTERS', accessedRouters);
                resolve();
            })
        }
    }
};

export default permission;