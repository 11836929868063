import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import language from './modules/language'
import permission from './modules/permission'
import getters from './getters'
Vue.use(Vuex);
export default new Vuex.Store({
    modules:{
        app,
        permission,
        language,
    },
    getters
})
