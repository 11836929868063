/*
 * @Author: 艾斯
 * @Description: 自定义全局组件
 * @Date: 2022-07-07 16:53:25
 * @LastEditTime: 2022-07-07 16:58:38
 * @FilePath: /sksaas-web/src/custom/index.js
 */
import Vue from 'vue'
import Dialog from '@/components/Common/Dialog.vue';
import Table from '@/components/Common/Table.vue';
import Form from '@/components/Common/Form.vue';

Vue.component('m-dialog',Dialog)
Vue.component('m-tabel',Table)
Vue.component('m-form',Form)