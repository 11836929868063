import axios from 'axios'
import MD5 from './md5'
import request from './url.js'
import Vue from 'vue'

axios.interceptors.request.use(
    config => {
      return config
    },
    err => {
      return Promise.reject(err)
    }
)

const ENV = 'https://sapi.sikegroup.com';
// const ENV = 'http://sksaas-api.cn'

//随机字符串
const randomStr = (len, charSet) => {
  charSet =
      charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var str = ''
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length)
    str += charSet.substring(randomPoz, randomPoz + 1)
  }
  return str.toLowerCase()
}

//生产签名
const toSign = function (obj) {
  let str = ''

  for (let val in obj) {
    if (!str) {
      str += val + '=' + obj[val]
    } else {
      str += '&' + val + '=' + obj[val]
    }
  }

  return str
}

String.prototype.addtoken = function (callback) {
  try {
    var value = window.localStorage.getItem('token')
    return value ? callback.call(null, this, '?access_token=' + value) : this
  } catch (e) {
    console.error('getStorageSync error.')
  }
}

//请求获取access_token
const getToken = function () {
  const loginMsg = JSON.parse(window.sessionStorage.getItem('TOGGLE_LOGIN_MSG'))
  const d = {
    appid: loginMsg.appid,
    mobile: loginMsg.mobile,
    nonce: randomStr(32),
    pwd: loginMsg.pwd,
    timestamp: parseInt(new Date().getTime() / 1000)
  }
  let http = axios.create({
    baseURL: ENV,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    withCredentials: false,
    transformRequest: [
      function (data) {
        let newData = ''
        for (let k in data) {
          if (data.hasOwnProperty(k) === true) {
            newData +=
                encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
          }
        }
        return newData
      }
    ]
  })
  const signature = MD5.md5(toSign(d))
  let data = loginMsg
  data.nonce = d.nonce
  data.timestamp = d.timestamp
  data.sign = signature
  data.LoginType = 'skim'
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url: findUrl(1204),
      params: data
    }).then(res => {
      resolve(res)
      if (res.data.code == 200) {
        window.sessionStorage.setItem(
            'userInfo',
            JSON.stringify(res.data.data.client)
        )
        window.sessionStorage.setItem(
            'access_token',
            res.data.data.access_token
        )
      }
    })
  })
}

const findUrl = function (val) {
  let __url__ = ''
  for (let i = 0; i < request.length; i++) {
    if (request[i].code == val) {
      __url__ = request[i].url
    }
  }
  // const __url__ = request.find(e => e.code == val);Authorization
  return __url__ ? ENV + __url__ : false
}

function getUrlParam (name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}

function apiAxios (method, url, params, response) {
  let authentication = window.sessionStorage.getItem('userInfo')
      ? 'USERID ' +
      window.btoa(
          JSON.parse(window.sessionStorage.getItem('userInfo')).appid +
          ':' +
          window.sessionStorage.getItem('access_token') +
          ':' +
          JSON.parse(window.sessionStorage.getItem('userInfo')).uid
      )
      : ''
  let http = axios.create({
    baseURL: ENV,
    headers: {
      authentication: authentication,
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    withCredentials: false,
    transformRequest: [
      function (data) {
        let newData = ''
        for (let k in data) {
          if (data.hasOwnProperty(k) === true) {
            newData +=
                encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
          }
        }
        return newData
      }
    ]
  })
  let baseHttp = axios.create({
    baseURL: ENV,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    withCredentials: false,
    transformRequest: [
      function (data) {
        let newData = ''
        for (let k in data) {
          if (data.hasOwnProperty(k) === true) {
            newData +=
                encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
          }
        }
        return newData
      }
    ]
  })

  //form表单上传
  let formHttp = axios.create({
    baseURL: ENV,
    headers: {
      authentication: authentication,
      'Content-Type': 'multipart/form-data; charset=utf-8;'
    },
    withCredentials: false
  })

  let ajax = url == 5836148 ? formHttp : http

  if (url == 1204) {
    getToken().then(back => {
      response(back)
    })
  } else {
    if(typeof params != 'object'){
      console.log('传入的参数必须是对象,而不是',typeof params);
      return false;
    }
    params.SaasUserId = window.sessionStorage.getItem('userInfo')

        ?JSON.parse(window.sessionStorage.getItem('userInfo')).uid
        : ''
    params.SaasUserName = window.sessionStorage.getItem('userInfo')
        ? JSON.parse(window.sessionStorage.getItem('userInfo')).name
        : ''
    if (window.sessionStorage.getItem('access_token')) {
      ajax({
        method: method,
        url: findUrl(url),
        data: method === 'POST' || method === 'PUT' ? params : '',
        params: method === 'GET' || method === 'DELETE' ? params : ''
      })
          .then(function (res) {
            response(res)
          })
          .catch(function (err) {
            if (err.message == 'Request failed with status code 401') {
              getToken().then(back => {
                apiAxios(method, url, params, response)
              })
            } else {
              response(err)
            }
          })
    } else {
      //SKIM跳转过来的时候直接请求参数已经在main入口文件获取
      if (getUrlParam('code')) {
        ajax({
          method: method,
          url: findUrl(url),
          data: method === 'POST' || method === 'PUT' ? params : '',
          params: method === 'GET' || method === 'DELETE' ? params : ''
        })
            .then(function (res) {
              response(res)
            })
            .catch(function (err) {
              if (err.message == 'Request failed with status code 401') {
                //token过期的时候重新去请求token，然后在重新请求
                baseHttp({
                  method: 'GET',
                  url: '/v1/refresh',
                  params: {
                    refresh_token: window.sessionStorage.getItem('refresh_token'),
                    appid: JSON.parse('userInfo').appid
                  }
                }).then(function (res) {
                  ajax({
                    method: method,
                    url: findUrl(url),
                    data: method === 'POST' || method === 'PUT' ? params : '',
                    params: method === 'GET' || method === 'DELETE' ? params : ''
                  })
                      .then(function (res) {
                        response(res)
                      })
                      .catch(function (err) {
                        response(err)
                      })
                })
              } else {
                response(err)
              }
            })
      } else {
        console.log('无token重新请求token')
        getToken().then(back => {
          apiAxios(method, url, params, response)
        })
      }
    }
  }
}

export default {
  get: function (url, params, response) {
    return apiAxios('GET', url, params, response)
  },
  post: function (url, params, response) {
    return apiAxios('POST', url, params, response)
  },
  put: function (url, params, response) {
    return apiAxios('PUT', url, params, response)
  },
  delete: function (url, params, response) {
    return apiAxios('DELETE', url, params, response)
  }
}
