<!--
 * @Author: 艾斯
 * @Description: 通用表格组件
 * @Date: 2022-07-05 10:16:08
 * @LastEditTime: 2022-07-23 12:16:04
 * @FilePath: /sksaas-web/src/components/Common/Table.vue
-->
<!--  -->
<template>
  <div class="m-tabel" v-loading="loading" element-loading-text="拼命加载中">
    <!-- 头部检索 -->
    <header class="search_box" v-if="formList.length > 0">
      <template v-for="(item, index) in formList">
        <!-- 文本框 -->
        <el-input v-model="form[item.model]" :placeholder="item.placeholder" clearable :size="formItmeSize" class="form_item" v-if="!item.type" :key="index"></el-input>
        <!-- 下拉框 -->
        <el-select
          v-model="form[item.model]"
          :placeholder="item.placeholder"
          :multiple="item.multiple"
          collapse-tags
          clearable
          filterable
          :size="formItmeSize"
          class="form_item"
          v-if="item.type === 'select'"
          :key="index"
        >
          <el-option v-for="row in item.options" :key="row.value" :label="row.label" :value="row.value"> </el-option>
        </el-select>
        <!-- 级联选择 -->
        <el-cascader
          v-model="form[item.model]"
          :options="item.options"
          :placeholder="item.placeholder"
          :props="item.props"
          :size="formItmeSize"
          clearable
          filterable
          collapse-tags
          class="form_item2"
          v-if="item.type === 'cascader'"
          :key="index"
        ></el-cascader>
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="form[item.model]"
          type="date"
          :placeholder="item.placeholder"
          :size="formItmeSize"
          value-format="yyyy-MM-dd"
          class="form_item"
          v-if="item.type === 'datePicker'"
          :key="index"
        >
        </el-date-picker>
      </template>
      <el-button :size="formItmeSize" @click="searchReset">重置</el-button>
      <el-button :size="formItmeSize" type="primary" @click="searchSubmit">搜索</el-button>
    </header>
    <!-- 表格主体 -->
    <el-table :data="tableData" stripe class="table" :show-summary="showSummary" :max-height="maxHeight">
      <template v-for="(item, index) in tableHead">
        <!-- 如果表格需要实现多选，在表头数据中添加{ prop: "selection" }即可 -->
        <el-table-column type="selection" :width="item.width" v-if="item.prop === 'selection'" :key="index"></el-table-column>
        <!-- 插槽 -->
        <el-table-column :label="item.label" :fixed="item.fixed" :width="item.width" show-overflow-tooltip v-else-if="item.isSlot" :key="index + 'b'">
          <template slot-scope="scope">
            <slot name="slot" :row="scope.row" :label="item.label" :index="scope.$index"></slot>
          </template>
        </el-table-column>
        <!-- 正常数据 -->
        <el-table-column :prop="item.prop" :label="item.label" :fixed="item.fixed" :width="item.width" show-overflow-tooltip v-else :key="index + 'c'"> </el-table-column>
      </template>
    </el-table>
    <!-- 分页 -->
    <section class="page_box" v-if="isShowPage">
      <el-pagination background :current-page="currentPage" :page-size="pageSize" :total="total" layout=" prev, pager, next, jumper" @current-change="pageChange"> </el-pagination>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formItmeSize: 'medium',
      form: {}
    }
  },
  props: {
    formList: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableHead: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    showSummary: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: '500px'
    },
    isShowPage: {
      type: Boolean,
      default: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number
    },
    currentPage: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.formList.length > 0) {
      let form = {}
      for (const item of this.formList) {
        form[item.model] = ''
      }
      this.form = { ...form }
    }
  },
  methods: {
    // 重置
    searchReset() {
      for (const key in this.form) {
        this.form[key] = ''
      }
    },
    // 检索
    searchSubmit() {
      this.$emit('submitForm', this.form)
    },
    // 分页改变事件
    pageChange(page) {
      this.$emit('pageChange', page)
    }
  }
}
</script>
<style lang="less" scoped>
.m-tabel {
  @mb: 20px;
  .search_box {
    display: flex;
    margin-bottom: @mb;
    .form_item {
      max-width: 180px;
      margin-right: 10px;
    }
    .form_item2 {
      min-width: 260px;
      margin-right: 10px;
      :deep(.el-input__inner) {
        height: 36px !important;
      }
      :deep(.el-cascader__search-input) {
        display: none;
      }
    }
  }
  .table {
    width: 100%;
    border: 1px solid #ebeef5;
    border-bottom: none;
    margin-bottom: @mb;
  }
  .page_box {
    display: flex;
    justify-content: end;
  }
}
</style>
