<template>
    <div class="logo" :class="$store.state.app.theme=='light'?'':'theme'">
        <router-link tag="a" :to="{name:'dashboard'}">
            <img :src="LOGO" alt="">
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "Logo",
        data(){
            return{
                title: 'Ant Design Pro',
                LOGO: '',
            }
        },
        created(){
            //设置网页的标题和LOGO
            document.title = this.$store.state.app.systemBaseMsg.SYSTEM_TITLE||'企业信息管理系统';
            let favicon = document.createElement("link");
            favicon.rel = "icon";
            if(this.$store.state.app.systemBaseMsg){
                favicon.href = this.$store.state.app.systemBaseMsg.SYSTEM_LOGO[0].FileUrl;
                this.LOGO = this.$store.state.app.systemBaseMsg.SYSTEM_LOGO[0].FileUrl;
            }
            console.log(favicon);
            document.head.appendChild(favicon);
        },
        props: {
            showTitle: {
                type: Boolean,
                default: true,
                required: false
            }
        }
    }
</script>

<style scoped lang="less">
    .logo {
        width: 100px;
        position: relative;
        height: 80px;
        overflow: hidden;
        line-height: 64px;
        background: #fff;
        transition: all .3s;
        text-align: center;
        a{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
        img,svg,h1 {
            display: inline-block;
            vertical-align: middle;
            color:inherit;
        }
        img, svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 32px;
            width: 32px;
        }
        h1 {
            font-size: 20px;
            margin: 0 0 0 12px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            font-weight: 600;
            vertical-align: middle;
        }
    }
    .theme{
        background: #001121;
        border-bottom: 1px solid #001121;
    }
</style>
