const getters = {
  countTest:state=> state.app.countTest,
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  multiTab: state => state.app.multiTab,
  langTab: state => state.language.langTab,
  personInfo:state=>state.app.personInfo,//监听个人资料公共部分信息
}

export default getters
