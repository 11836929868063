<template>
    <div class="user-wrapper">
        <div class="content-box">
            <!--  语言选择-->
            <!-- <a-select :defaultValue="selectValue" style="width: 120px" @change="handleChange">
               <a-select-option v-for="item in languages" :key="item.label" :value="item.key">{{item.label}}</a-select-option>
            </a-select> -->
            <span class="action" @click="Shortcut=true">
                <a-icon type="plus-circle" class="add-icon"/>
            </span>
            <notice-icon class="action"/>
            <a-dropdown>
                <span class="action ant-dropdown-link user-dropdown-menu">
                  <a-avatar class="avatar" size="small" :src="userInfo.Avatar"/>
                </span>
                <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
                    <a-menu-item key="0">
                        <router-link :to="{ name: 'center' }">
                            <a-icon type="user"/>
                            <span>个人中心</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="1">
                        <router-link :to="{ name: 'settings' }">
                            <a-icon type="setting"/>
                            <span>账户设置</span>
                        </router-link>
                    </a-menu-item>
                    <a-menu-item key="2" disabled>
                        <a-icon type="setting"/>
                        <span>测试</span>
                    </a-menu-item>
                    <a-menu-divider/>
                    <a-menu-item key="3">
                        <a href="javascript:;" @click="handleLogout">
                            <a-icon type="logout"/>
                            <span>退出登录</span>
                        </a>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>

            <a-icon type="menu-unfold" class="user-msg-right-btn"/>
        </div>
        <a-drawer title="快捷功能" placement="right" width="400" :closable="false" @close="_closeShortcut" :visible="Shortcut" :bordered="false">
            <a-card>
                <a-card-grid style="width:25%;text-align:center;padding: 10px;height:90px;padding-top:20px;">
                    <img src="https://smart-resource.sikegroup.com/saas-todo.png?imageView2/1/w/30/h/30" style="margin-bottom:5px" alt="">
                    <div>工作台</div>
                </a-card-grid>

                <a-card-grid style="width:25%;text-align:center;padding: 10px;height:90px;padding-top:20px;">
                    <img src="https://smart-resource.sikegroup.com/saas-todo.png?imageView2/1/w/30/h/30" style="margin-bottom:5px" alt="">
                    <div>Content</div>
                </a-card-grid>

                <a-card-grid style="width:25%;text-align:center;padding: 10px;height:90px;padding-top:20px;">
                    <img src="https://smart-resource.sikegroup.com/saas-todo.png?imageView2/1/w/30/h/30" style="margin-bottom:5px" alt="">
                    <div>Content</div>
                </a-card-grid>
                <a-card-grid style="width:25%;text-align:center;padding: 10px;height:90px;padding-top:20px;">
                    <img src="https://smart-resource.sikegroup.com/saas-todo.png?imageView2/1/w/30/h/30" style="margin-bottom:5px" alt="">
                    <div>Content</div>
                </a-card-grid>

                <a-card-grid style="width:25%;text-align:center;padding: 10px;height:90px;padding-top:20px;">
                    <a-icon class="ant-menu-item-selected" type="plus-circle" style="font-size: 30px;margin-bottom: 5px;"/>
                    <div class="ant-menu-item-selected">添加</div>
                </a-card-grid>
            </a-card>
        </a-drawer>
    </div>
</template>

<script>
    import NoticeIcon from '../NoticeIcon'
    // import { locale } from 'moment';
    export default {
        name: 'UserMenu',
        components: {
            NoticeIcon
        },
        inject:['reload'],//语言切换
        data(){
            return{
                selectValue: '',
                languages:[{key:'en-gb',label: 'English'},{key:'zh-cn',label: '中文'}],
                userInfo: '',
                specialFnList: [
                    {
                        icon: 'desktop',
                        title: '工作台'
                    },
                    {
                        icon: 'desktop',
                        title: '工作台'
                    }
                ],
                specialFnActive: 0,
                showSpecialFn: false,
                Shortcut: false
            }
        },
        created() {
            if(localStorage.getItem('language')){
                this.selectValue =localStorage.getItem('language');
            }else{
                this.selectValue = this.$store.state.language.langTab;
            }
           
            this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
        },
        mounted() {
            document.addEventListener('click', (e) => { if (!this.$el.contains(e.target)) this.showSpecialFn = false; })
        },
        methods: {
            _closeShortcut(){
                this.Shortcut = false;
            },
            //语言选择
            handleChange(value) {
                // localStorage.setItem('lang', value);
                this.$store.commit('langTabFn',value);//'zh-cn'value)
                this.$i18n.locale = value;// value;//语言切换
                //语言版本保存在本地
                window.localStorage.setItem('language',value)// this.$store.state.language.langTab);
                this.reload();
            },

            handleLogout () {
                const that = this;
                this.$confirm({
                    title: '提示',
                    content: '真的要注销登录吗 ?',
                    cancelText: '取消',
                    okText: '确定',
                    confirmLoading: true,
                    onOk () {
                        window.sessionStorage.clear();
                        that.$router.push({path:'/login'});
                    },
                    onCancel () {
                    }
                })

            }
        }
    }
</script>

<style scoped lang="less">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to{
        opacity: 0;
    }
    .user-msg-right-btn{
        font-size: 24px;
        vertical-align:middle;
        color: #70767B;
        margin-left: 10px;
        cursor: pointer;
    }
    .special-fn{
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                padding: 8px 18px;
                margin-right: 20px;
                border-radius: 4px;
                cursor: pointer;
                div{
                    line-height: 1.6;
                    text-align: center;
                }
                .fn-icon{
                    font-size: 20px;
                    color: #747474;
                }
                .fn-title{
                    color: #747474;
                    font-size: 12px;
                }
            }
            li.active{
                .fn-icon{
                    color: #fff;
                }
                .fn-title{
                    color: #fff;
                }
            }
        }
    }
    .user-wrapper {
        float: right;
        height: 100%;
        .action {
            cursor: pointer;
            padding: 0 15px;
            display: inline-block;
            transition: all 0.3s;
            height: 100%;
            color: rgba(0, 0, 0, 0.65);
            &:hover {
                background: rgba(0, 0, 0, 0.025);
            }

            .avatar {
                width: 30px;
                height: 30px;
                color: #1890ff;
                background: hsla(0, 0%, 100%, 0.85);
                vertical-align: middle;
            }

            .icon {
                font-size: 16px;
                padding: 4px;
            }
        }
        .add-icon{
            font-size: 20px;
            color: #72787D;
            cursor: pointer;
        }
    }
    &.dark {
        .user-wrapper {
            .action {
                color: rgba(255, 255, 255, 0.85);
                a {
                    color: rgba(255, 255, 255, 0.85);
                }

                &:hover {
                    background: rgba(255, 255, 255, 0.16);
                }
            }
        }
    }
    .user-dropdown-menu-wrapper.ant-dropdown-menu {
        padding: 4px 0;

        .ant-dropdown-menu-item {
            width: 160px;
        }

        .ant-dropdown-menu-item > .anticon:first-child,
        .ant-dropdown-menu-item > a > .anticon:first-child,
        .ant-dropdown-menu-submenu-title > .anticon:first-child .ant-dropdown-menu-submenu-title > a > .anticon:first-child {
            min-width: 12px;
            margin-right: 8px;
        }
    }
</style>
