<!--
 * @Author: 艾斯
 * @Description: 通用弹窗组件
 * @Date: 2022-07-02 11:27:33
 * @LastEditTime: 2022-08-03 10:02:01
 * @FilePath: /sksaas-web/src/components/Common/Dialog.vue
-->
<template>
  <div class="m-dialog">
    <el-dialog :visible.sync="isShow" :width="width" :fullscreen="fullscreen" draggable :show-close="false" z-index="1000" @close="close" v-if="type === 'dialog'">
      <!-- 弹窗头部 -->
      <template #title>
        <div class="custom-header">
          <span>{{ title }}</span>
          <div class="right-btn">
            <!-- 全屏按钮 -->
            <el-button class="icon" icon="el-icon-full-screen" @click="fullscreen = !fullscreen"></el-button>
            <!-- 关闭按钮 -->
            <el-button class="icon" icon=" el-icon-close" @click="close"></el-button>
          </div>
        </div>
      </template>
      <!-- 弹窗中间body -->

      <div class="custom-body" :style="bodyHeight">
        <el-scrollbar class="page-component__scroll">
          <slot />
        </el-scrollbar>
      </div>

      <!-- 弹窗底部 -->
      <template v-if="showFooter" #footer>
        <div class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="btnLoding" @click="saveSubmit">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-drawer :title="title" :visible.sync="isShow" :size="width" z-index="1000" @close="close" v-if="type === 'drawer'">
      <div class="drawer_box">
        <section class="drawer_body">
          <el-scrollbar class="page-component__scroll">
            <div class="drawer_inner">
              <slot />
            </div>
          </el-scrollbar>
        </section>
        <!-- 弹窗底部 -->
        <template v-if="showFooter">
          <div class="drawer_footer">
            <el-button @click="close">取消</el-button>
            <el-button type="primary" :loading="btnLoding" @click="saveSubmit">确定</el-button>
          </div>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullscreen: false, //是否全屏,
      btnLoding: false
    }
  },
  props: {
    type: {
      type: String,
      default: 'dialog'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    // 是否显示底部
    showFooter: {
      type: Boolean,
      default: true
    },
    // 标题
    title: {
      type: String,
      default: '测试标题'
    },
    // 宽度
    width: {
      type: String,
      default: '800px'
    },
    height: {
      type: String,
      default: '500px'
    },
    // 确定按钮是否显示loading
    showLoading: {
      type: Boolean,
      default: false
    },
    // 确定按钮是否加载中
    saveLoding: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    saveLoding(val) {
      this.btnLoding = val
    }
  },
  computed: {
    isShow: {
      get() {
        return this.dialogVisible
      },
      set() {}
    },
    bodyHeight() {
      return this.fullscreen ? { height: `calc(100vh - 54px - 130px)` } : { height: this.height }
    }
  },
  methods: {
    // 关闭
    close() {
      this.$emit('update:dialogVisible', false)
      this.$emit('close')
    },
    saveSubmit() {
      if (this.showLoading) {
        this.btnLoding = true
        this.$emit('update:saveLoding', true)
      }
      this.$emit('saveSubmit')
    }
  }
}
</script>
<style lang="less" scoped>
.m-dialog {
  .custom-header {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
    .right-btn {
      float: right;
      .icon {
        font-size: 16px;
        border: none;
        outline: none;
        padding: 0;
        background: transparent;
      }
    }
  }
  .drawer_box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .drawer_body {
      flex: 1;
      height: calc(~'100% - 82px - 60px');
      .drawer_inner {
        padding: 0 20px 20px;
      }
    }
    .drawer_footer {
      padding: 10px 0 10px 20px;
      border-top: 1px solid #ebeef5;
    }
  }

  .page-component__scroll {
    height: 100%;
    :deep(.el-scrollbar__wrap) {
      overflow-x: hidden;
    }
  }
}
</style>
