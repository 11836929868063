<template>
    <a-layout-header id="layout-header" class="layout-header">
        <div style="display: flex;align-items: center">
            <logo></logo>
<!--            <a-icon class="trigger" :type="showNav ? 'menu-unfold' : 'menu-fold'" @click.stop="_cutCollapsed" />-->
            <div class="search-bar">
                <input type="text" placeholder="搜索姓名">
                <a-icon type="search" class="search-icon"/>
            </div>
        </div>
        <tools></tools>
    </a-layout-header>
</template>

<script>
    import Logo from 'components/Logo/Logo'
    //import {mapState} from 'vuex'
    import Tools from '../Tools/Tools'
    export default {
        name: "GlobalHeader",
        components: {
            Tools,Logo
        },
        data(){
            return {
                Shortcut: false
            }
        },
        // computed: {
        //     ...mapState({
        //         showNav:(state) => state.app.showNav
        //     })
        // },
        methods: {
            _closeShortcut(){
                this.Shortcut = false;
            },
            _cutCollapsed() {
                this.$store.commit('TOGGLE_SHOW_NAV',!this.showNav);
            }
        }
    }
</script>

<style lang="less">
    #layout-header{
        display: flex;
        height: 80px;
        line-height: 80px;
        justify-content: space-between;
        background: #fff !important;
        padding: 0 30px 0 0;
        box-shadow: 0 2px 8px #f0f1f2;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 300;
        .trigger{
            font-size: 24px;
            line-height: 80px;
            padding: 0 30px;
            cursor: pointer;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
            color: #70767B;
        }
        .trigger-btn{

            cursor: pointer;
            span{
                display: block;
                width: 25px;
                height: 2px;
                margin-bottom: 6px;
                border-radius: 5px;
            }
        }
        .fn{
            margin-right: 30px;
        }
        .search-bar{
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 230px;
            height: 40px;
            border: 1px solid #EEEEEE;
            border-radius: 20px;
            background: #F8F9FA;
            input{
                width: 85%;
                height: 100%;
                padding: 4px 15px;
                background: transparent;
                border: none;
                vertical-align: top;
                outline: none;
            }
            .search-icon{
                font-size: 18px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 15px;
                color: #70657b;
                cursor: pointer;
            }
        }
    }
</style>