var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"MultiTab",style:({ width: _vm.showNav ? _vm.width - 220 + 'px' : _vm.width + 'px' })},[_c('ul',{staticClass:"tabs-list"},[_c('li',{staticClass:"tab-home",on:{"click":function($event){$event.stopPropagation();return _vm._cutCollapsed.apply(null, arguments)}}},[_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.showNav ? 'menu-unfold' : 'menu-fold'}})],1),_vm._l((_vm.$store.state.app.multiTabList),function(item,index){return [_c('a-dropdown',{attrs:{"trigger":['contextmenu']}},[_c('li',{staticClass:"tab-li",class:{
            'ant-menu-item-selected':
              _vm.$store.state.app.defaultActiveKey == index ? true : false,
            hoverActive: _vm.hoverActive == index ? true : false,
          },on:{"click":function($event){return _vm._skipMenu(item.path, index, item.query)},"mouseover":function($event){return _vm._hoverActive(index)},"mouseout":function($event){_vm.hoverActive = -1}}},[_c('span',{domProps:{"textContent":_vm._s(item.title)}}),(index != 0)?_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.hoverActive == index ||
              _vm.$store.state.app.defaultActiveKey == index
            ),expression:"\n              hoverActive == index ||\n              $store.state.app.defaultActiveKey == index\n            "}],staticClass:"close",attrs:{"type":"close"},on:{"click":function($event){$event.stopPropagation();return _vm._close(index)}}}):_vm._e()],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.menuList),function(tab){return _c('a-menu-item',{on:{"click":function($event){return _vm._tabClick(tab, index, item.path, item.query)}}},[_c('a',{attrs:{"href":"javascript:;"}},[_c('a-icon',{attrs:{"type":tab == '刷新' ? 'redo' : 'close-circle'}}),_vm._v(" "+_vm._s(tab))],1)])}),1)],1)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }