
//保留几位小数 转换字节
let decimal = (value,num) => {
       value = Math.ceil(value*num/(1024))/num;
       return value;
}
//保留几位小数
let decimal_two = (value) => {
       value = Math.ceil(value*100)/100;
       return value;
}
//截取字符（文件尾缀 ==类型）
let file_type = (value,type) => {
       let index = value.indexOf(type);
       let len = value.length;
       return value.substring((index+1),len);
}
const substrName = (value,star,end) => {
     return value.substring(star,end);
}
//数据处理
const transFilter = (value) => {
    if(!value){
       return;
    }
    let returnArr = [];
    let arr = value.split(',');
    for(let i = 0;i<arr.length;i++){
       let name = (arr[i].split('|'))[2];
       returnArr.push(name)
    }
    return returnArr.join(',');
}
const sliceFn = (val,i,k)=>{//字符截取
       if(val){
           return val.substring(i,k);   
       }       
}
export { decimal,file_type,substrName,decimal_two,transFilter,sliceFn}