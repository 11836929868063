export default {
   md5 (string) {
      function md5RotateLeft (lValue, iShiftBits) {
         return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits))
      }
      function md5AddUnsigned (lX, lY) {
         let lX4, lY4, lX8, lY8, lResult
         lX8 = (lX & 0x80000000)
         lY8 = (lY & 0x80000000)
         lX4 = (lX & 0x40000000)
         lY4 = (lY & 0x40000000)
         lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF)
         if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8)
         }
         if (lX4 | lY4) {
            if (lResult & 0x40000000) {
               return (lResult ^ 0xC0000000 ^ lX8 ^ lY8)
            } else {
               return (lResult ^ 0x40000000 ^ lX8 ^ lY8)
            }
         } else {
            return (lResult ^ lX8 ^ lY8)
         }
      }
      function md5F (x, y, z) {
         return (x & y) | ((~x) & z)
      }
      function md5G (x, y, z) {
         return (x & z) | (y & (~z))
      }
      function md5H (x, y, z) {
         return (x ^ y ^ z)
      }
      function md5I (x, y, z) {
         return (y ^ (x | (~z)))
      }
      function md5FF (a, b, c, d, x, s, ac) {
         a = md5AddUnsigned(a, md5AddUnsigned(md5AddUnsigned(md5F(b, c, d), x), ac))
         return md5AddUnsigned(md5RotateLeft(a, s), b)
      }
      function md5GG (a, b, c, d, x, s, ac) {
         a = md5AddUnsigned(a, md5AddUnsigned(md5AddUnsigned(md5G(b, c, d), x), ac))
         return md5AddUnsigned(md5RotateLeft(a, s), b)
      }
      function md5HH (a, b, c, d, x, s, ac) {
         a = md5AddUnsigned(a, md5AddUnsigned(md5AddUnsigned(md5H(b, c, d), x), ac))
         return md5AddUnsigned(md5RotateLeft(a, s), b)
      }
      function md5II (a, b, c, d, x, s, ac) {
         a = md5AddUnsigned(a, md5AddUnsigned(md5AddUnsigned(md5I(b, c, d), x), ac))
         return md5AddUnsigned(md5RotateLeft(a, s), b)
      }
      function md5ConvertToWordArray (string) {
         let lWordCount
         let lMessageLength = string.length
         let lNumberOfWordsTemp1 = lMessageLength + 8
         let lNumberOfWordsTemp2 = (lNumberOfWordsTemp1 - (lNumberOfWordsTemp1 % 64)) / 64
         let lNumberOfWords = (lNumberOfWordsTemp2 + 1) * 16
         let lWordArray = Array(lNumberOfWords - 1)
         let lBytePosition = 0
         let lByteCount = 0
         while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4
            lBytePosition = (lByteCount % 4) * 8
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition))
            lByteCount++
         }
         lWordCount = (lByteCount - (lByteCount % 4)) / 4
         lBytePosition = (lByteCount % 4) * 8
         lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition)
         lWordArray[lNumberOfWords - 2] = lMessageLength << 3
         lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29
         return lWordArray
      }
      function md5WordToHex (lValue) {
         let WordToHexValue = ''
         let WordToHexValueTemp = ''
         let lByte
         let lCount
         for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255
            WordToHexValueTemp = '0' + lByte.toString(16)
            WordToHexValue = WordToHexValue + WordToHexValueTemp.substr(WordToHexValueTemp.length - 2, 2)
         }
         return WordToHexValue
      }
      function md5Utf8Encode (string) {
         string = string.replace(/\r\n/g, '\n')
         let utftext = ''
         for (let n = 0; n < string.length; n++) {
            let c = string.charCodeAt(n)
            if (c < 128) {
               utftext += String.fromCharCode(c)
            } else if ((c > 127) && (c < 2048)) {
               utftext += String.fromCharCode((c >> 6) | 192)
               utftext += String.fromCharCode((c & 63) | 128)
            } else {
               utftext += String.fromCharCode((c >> 12) | 224)
               utftext += String.fromCharCode(((c >> 6) & 63) | 128)
               utftext += String.fromCharCode((c & 63) | 128)
            }
         }
         return utftext
      }
      let x = []
      let k
      let AA
      let BB
      let CC
      let DD
      let a
      let b
      let c
      let d
      let [S11, S12, S13, S14, S21, S22, S23, S24, S31, S32, S33, S34, S41, S42, S43, S44] =
         [7, 12, 17, 22, 5, 9, 14, 20, 4, 11, 16, 23, 6, 10, 15, 21]
      string = md5Utf8Encode(string)
      x = md5ConvertToWordArray(string)
      a = 0x67452301
      b = 0xEFCDAB89
      c = 0x98BADCFE
      d = 0x10325476
      for (k = 0; k < x.length; k += 16) {
         AA = a
         BB = b
         CC = c
         DD = d
         a = md5FF(a, b, c, d, x[k + 0], S11, 0xD76AA478)
         d = md5FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756)
         c = md5FF(c, d, a, b, x[k + 2], S13, 0x242070DB)
         b = md5FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE)
         a = md5FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF)
         d = md5FF(d, a, b, c, x[k + 5], S12, 0x4787C62A)
         c = md5FF(c, d, a, b, x[k + 6], S13, 0xA8304613)
         b = md5FF(b, c, d, a, x[k + 7], S14, 0xFD469501)
         a = md5FF(a, b, c, d, x[k + 8], S11, 0x698098D8)
         d = md5FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF)
         c = md5FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1)
         b = md5FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE)
         a = md5FF(a, b, c, d, x[k + 12], S11, 0x6B901122)
         d = md5FF(d, a, b, c, x[k + 13], S12, 0xFD987193)
         c = md5FF(c, d, a, b, x[k + 14], S13, 0xA679438E)
         b = md5FF(b, c, d, a, x[k + 15], S14, 0x49B40821)
         a = md5GG(a, b, c, d, x[k + 1], S21, 0xF61E2562)
         d = md5GG(d, a, b, c, x[k + 6], S22, 0xC040B340)
         c = md5GG(c, d, a, b, x[k + 11], S23, 0x265E5A51)
         b = md5GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA)
         a = md5GG(a, b, c, d, x[k + 5], S21, 0xD62F105D)
         d = md5GG(d, a, b, c, x[k + 10], S22, 0x2441453)
         c = md5GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681)
         b = md5GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8)
         a = md5GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6)
         d = md5GG(d, a, b, c, x[k + 14], S22, 0xC33707D6)
         c = md5GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87)
         b = md5GG(b, c, d, a, x[k + 8], S24, 0x455A14ED)
         a = md5GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905)
         d = md5GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8)
         c = md5GG(c, d, a, b, x[k + 7], S23, 0x676F02D9)
         b = md5GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A)
         a = md5HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942)
         d = md5HH(d, a, b, c, x[k + 8], S32, 0x8771F681)
         c = md5HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122)
         b = md5HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C)
         a = md5HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44)
         d = md5HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9)
         c = md5HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60)
         b = md5HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70)
         a = md5HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6)
         d = md5HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA)
         c = md5HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085)
         b = md5HH(b, c, d, a, x[k + 6], S34, 0x4881D05)
         a = md5HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039)
         d = md5HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5)
         c = md5HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8)
         b = md5HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665)
         a = md5II(a, b, c, d, x[k + 0], S41, 0xF4292244)
         d = md5II(d, a, b, c, x[k + 7], S42, 0x432AFF97)
         c = md5II(c, d, a, b, x[k + 14], S43, 0xAB9423A7)
         b = md5II(b, c, d, a, x[k + 5], S44, 0xFC93A039)
         a = md5II(a, b, c, d, x[k + 12], S41, 0x655B59C3)
         d = md5II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92)
         c = md5II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D)
         b = md5II(b, c, d, a, x[k + 1], S44, 0x85845DD1)
         a = md5II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F)
         d = md5II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0)
         c = md5II(c, d, a, b, x[k + 6], S43, 0xA3014314)
         b = md5II(b, c, d, a, x[k + 13], S44, 0x4E0811A1)
         a = md5II(a, b, c, d, x[k + 4], S41, 0xF7537E82)
         d = md5II(d, a, b, c, x[k + 11], S42, 0xBD3AF235)
         c = md5II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB)
         b = md5II(b, c, d, a, x[k + 9], S44, 0xEB86D391)
         a = md5AddUnsigned(a, AA)
         b = md5AddUnsigned(b, BB)
         c = md5AddUnsigned(c, CC)
         d = md5AddUnsigned(d, DD)
      }
      return (md5WordToHex(a) + md5WordToHex(b) + md5WordToHex(c) + md5WordToHex(d)).toLowerCase()
   }
}
