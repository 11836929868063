
const app = {
    state: {
        multiTabList: JSON.parse(sessionStorage.getItem('multiTabList'))||[],//标签
        multiTab: true,//是否显示标签
        color: '#7571f9', //'#1890FF' 主题色
        defaultActiveKey: parseInt(sessionStorage.getItem('defaultActiveKey'))||0,//正在打开的标签下标
        defaultIndex: JSON.parse(sessionStorage.getItem('defaultIndex'))||['0-0'],//这个不知道是干嘛的 删了会导致系统崩溃  【危险操作】
        defaultOpenKeys: JSON.parse(sessionStorage.getItem('defaultOpenKeys'))||0,//正在打开的菜单下标
        theme: 'light',//页面显示风格
        weak: false,
        Token: '',//保存token  目前存在缓存用不到这个变量
        loginMsg: '',//保存登陆信息
        showNav: false,//是否显示弹出的菜单栏
        pageMenuList: JSON.parse(sessionStorage.getItem('MenuList'))||[],//菜单列表
        systemBaseMsg: JSON.parse(sessionStorage.getItem('systemBaseMsg'))||'',//系统基本配置信息

        //考试问卷
        childrenQuestionnaire: [],//子问卷列表
        // 简历个人信息
        personInfo:{},

    },
    mutations: {
      
        TOGGLE_CHILDREN_QUESTIONNAIRE:(state,data) =>{
            if(data.clear==1){
                state.childrenQuestionnaire = data.list;
            }else{
                state.childrenQuestionnaire.push(data.list);
            }
        },
        TOGGLE_SYSTEM_BASE_MSG:(state,data) =>{
            state.systemBaseMsg = data;
            sessionStorage.setItem('systemBaseMsg',JSON.stringify(data));
        },
        TOGGLE_PAGE_MENU_LIST:(state,data) =>{
            state.pageMenuList = data;
            sessionStorage.setItem('MenuList',JSON.stringify(data));
        },
        TOGGLE_MULTI_TAB: (state, bool) => {
            state.multiTab = bool
        },
        TOGGLE_COLOR: (state, color) => {
            state.color = color
        },
        TOGGLE_MULTI_TAB_LIST: (state, data) => {
            state.multiTabList = data;
            if(data){
                sessionStorage.setItem('multiTabList',JSON.stringify(data));
            }
        },
        TOGGLE_MULTI_TAB_LIST_REMOVE: (state, i) => {
            state.multiTabList.splice(i,1);
            sessionStorage.setItem('multiTabList',JSON.stringify(state.multiTabList));
        },
        TOGGLE_DEFAULT_ACTIVE_KEY: (state, i) => {
            state.defaultActiveKey = i;
            sessionStorage.setItem('defaultActiveKey',i)
        },
        TOGGLE_DEFAYLT_INDEX: (state, i) => {
            state.defaultIndex = i;
            sessionStorage.setItem('defaultIndex',JSON.stringify(i));
        },
        TOGGLE_DEFAULT_OPEN_KEYS: (state, i) => {
            state.defaultOpenKeys = i;
            sessionStorage.setItem('defaultOpenKeys',JSON.stringify(i))
        },
        TOGGLE_THEME: (state, i) => {
            state.theme = i;
        },
        TOGGLE_WEAK: (state, flag) => {
            state.weak = flag
        },
        TOGGLE_TOKEN: (state, i) => {
            state.Token = i;
        },
        TOGGLE_LOGIN_MSG: (state, i) => {
            state.loginMsg = i;
        },
        TOGGLE_SHOW_NAV: (state, i) => {
            state.showNav = i;
        },
        // //个人资料（基本信息）公共部分
        // PERSON_INFO:(state,obj) => {
        //     state.personInfo = obj;
        // }
    },
    actions: {
       
        ToggleMultiTab ({ commit }, bool) {
            commit('TOGGLE_MULTI_TAB', bool)
        },
        ToggleColor ({ commit }, color) {
            commit('TOGGLE_COLOR', color)
        },
        TOGGLE_MULTI_TAB_LIST ({ commit }, data) {
            commit('TOGGLE_MULTI_TAB_LIST', data)
        },
        TOGGLE_MULTI_TAB_LIST_REMOVE ({ commit }, i) {
            commit('TOGGLE_MULTI_TAB_LIST_REMOVE', i)
        },
        ToggleDefaultActiveKey ({ commit }, i) {
            commit('TOGGLE_DEFAULT_ACTIVE_KEY', i)
        },
        ToggleDefaultIndex ({ commit }, i) {
            commit('TOGGLE_DEFAYLT_INDEX', i)
        },
        ToggleDefaultOpenKeys ({ commit }, i) {
            commit('TOGGLE_DEFAULT_OPEN_KEYS', i)
        },
        ToggleTheme ({ commit }, i) {
            commit('TOGGLE_THEME', i)
        },
        ToggleWeak({ commit }, weakFlag) {
            commit('TOGGLE_WEAK', weakFlag)
        },
        ToggleToken({ commit }, i) {
            commit('TOGGLE_TOKEN', i)
        },
        ToggleLoginMsg({commit},i){
            commit('TOGGLE_LOGIN_MSG', i)
        },
        PERSON_INFO({commit},val){
            commit('PERSON_INFO',val);
        },
    }
};
export default app;