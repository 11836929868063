<template>
  <div
    class="MultiTab"
    :style="{ width: showNav ? width - 220 + 'px' : width + 'px' }"
  >
    <ul class="tabs-list">
      <li class="tab-home" @click.stop="_cutCollapsed">
        <a-icon class="trigger" :type="showNav ? 'menu-unfold' : 'menu-fold'" />
      </li>
      <template v-for="(item, index) in $store.state.app.multiTabList">
        <a-dropdown :trigger="['contextmenu']">
          <li
            @click="_skipMenu(item.path, index, item.query)"
            class="tab-li"
            @mouseover="_hoverActive(index)"
            @mouseout="hoverActive = -1"
            :class="{
              'ant-menu-item-selected':
                $store.state.app.defaultActiveKey == index ? true : false,
              hoverActive: hoverActive == index ? true : false,
            }"
          >
            <span v-text="item.title"></span>
            <a-icon
              type="close"
              class="close"
              v-if="index != 0"
              v-show="
                hoverActive == index ||
                $store.state.app.defaultActiveKey == index
              "
              @click.stop="_close(index)"
            />
          </li>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="tab in menuList"
              @click="_tabClick(tab, index, item.path, item.query)"
            >
              <a href="javascript:;"
                ><a-icon :type="tab == '刷新' ? 'redo' : 'close-circle'" />
                {{ tab }}</a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MultiTab',
  data() {
    return {
      activeTab: 0,
      hoverActive: -1,
      showTabs: false,
      width: 0,
      menuList: ['刷新', '关闭全部', '关闭其他', '关闭左侧', '关闭右侧'],
    }
  },
  inject: ['reload'],
  computed: {
    ...mapState({
      showNav: (state) => state.app.showNav,
    }),
  },
  created() {
    this.width = document.body.clientWidth - 120
    if (this.$store.state.app.multiTabList.length == 0) {
      this.$store.dispatch(
        'TOGGLE_MULTI_TAB_LIST',
        JSON.parse(window.sessionStorage.getItem('multiTabList'))
      )
    }
  },
  mounted() {
    // 监听窗口改变
    window.onresize = () => {
      this.width = document.body.clientWidth - 120
      console.log(this.width,'窗口改变了');
    }
    let self = this
    this.globalClick(this._hideTabs)
    //鼠标滚轮事件
    if (window.addEventListener)
      //FF,火狐浏览器会识别该方法
      window.addEventListener('DOMMouseScroll', wheel, false)
    window.onmousewheel = document.onmousewheel = wheel //W3C
    //统一处理滚轮滚动事件
    function wheel(event) {
      var delta = 0
      if (!event) event = window.event
      if (event.wheelDelta) {
        //IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
        delta = event.wheelDelta / 120
        if (window.opera) delta = -delta //因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
      } else if (event.detail) {
        //FF浏览器使用的是detail,其值为“正负3”
        delta = -event.detail / 3
      }
      if (delta) self._scrollFunc(event)
    }
  },
  methods: {
    _cutCollapsed() {
      this.$store.commit('TOGGLE_SHOW_NAV', !this.showNav)
    },
    _tabClick(text, i, url, data) {
      if (text == '关闭全部' || text == '关闭其他') {
        this.$store.dispatch('TOGGLE_MULTI_TAB_LIST', [
          this.$store.state.app.multiTabList[i],
        ])
      } else if (text == '刷新') {
        this.reload()
      } else if (text == '关闭左侧') {
        this.$store.state.app.multiTabList.splice(0, i)
        this.$store.dispatch(
          'TOGGLE_MULTI_TAB_LIST',
          this.$store.state.app.multiTabList
        )
        this.$store.dispatch('ToggleDefaultActiveKey', 0)
        if (this.$store.state.app.defaultActiveKey < i) {
          this.$router.push({ path: url, query: data })
        }
      } else if (text == '关闭右侧') {
        this.$store.state.app.multiTabList.splice(i + 1)
        if (this.$store.state.app.defaultActiveKey > i) {
          this.$router.push({ path: url, query: data })
        }
      }
    },
    _scrollFunc(e) {
      let list = e.path
      for (let i = 0; i < list.length; i++) {
        if (list[i].className === 'MultiTab') {
          document.getElementsByClassName('MultiTab')[0].scrollLeft += e.deltaY
        }
      }
    },
    _hideTabs() {
      this.showTabs = false
    },
    _hoverActive(i) {
      this.hoverActive = i
    },
    _skipMenu(url, i, data) {
      this.showTabs = false
      this.$store.dispatch('ToggleDefaultActiveKey', i)
      this.$router.push({ path: url, query: data })
    },
    _close(targetKey) {
      this.$store.dispatch('TOGGLE_MULTI_TAB_LIST_REMOVE', targetKey)
      if (targetKey == 0) {
        return false
        //this.$router.push({path:'/'})
      } else {
        if (targetKey == this.$store.state.app.defaultActiveKey) {
          targetKey == 0
            ? this.$store.state.app.defaultActiveKey == 0
            : (this.$store.state.app.defaultActiveKey = targetKey - 1)
          this.$router.push({
            path: this.$store.state.app.multiTabList[targetKey - 1].path,
            query: this.$store.state.app.multiTabList[targetKey - 1].query,
          })
        }
        if (
          targetKey < this.$store.state.app.defaultActiveKey &&
          targetKey != 0
        ) {
          this.$store.dispatch(
            'ToggleDefaultActiveKey',
            this.$store.state.app.defaultActiveKey - 1
          )
        }
      }
    },
  },
  watch: {
    $route(to, from) {
      let self = this
      let list = this.$store.state.app.multiTabList
      let item = {
        title: to.query.tabName ? to.query.tabName : to.meta.title,
        path: to.path,
        query: to.query,
      }
      if (to.meta.tab == false) {
        return false
      }
      if (JSON.stringify(list).indexOf(JSON.stringify(item)) == -1) {
        list.push(item)
        this.$store.dispatch('TOGGLE_MULTI_TAB_LIST', list)
        this.$store.dispatch('ToggleDefaultActiveKey', list.length - 1)
      } else {
        for (let i = 0; i < list.length; i++) {
          if (list[i].path == item.path && list[i].title == item.title) {
            self.$store.dispatch('ToggleDefaultActiveKey', i)
          }
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
.MultiTab {
  text-align: left;
  overflow-y: hidden;
  .tabs-list {
    border: 1px solid #e3e5e8;
    border-bottom: none;
    border-right: 0;
    background: #fff;
    position: relative;
    z-index: 990;
    //display: flex;
    white-space: nowrap;
    color: #666;
    // overflow: auto;
    .tab-home {
      width: 32px;
      padding: 0 10px;
      text-align: center;
      i {
        font-size: 12px;
      }
    }
    .tab-li {
      //flex: 1;
      position: relative;
      span {
        display: inline-block;
        max-width: 85px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      /*padding: 0 28px;*/
    }
    li {
      display: inline-block;
      vertical-align: middle;
      width: 100px;
      padding: 0 5px;
      white-space: nowrap;
      position: relative;
      height: 34px;
      line-height: 34px;
      font-size: 14px;
      border-right: 1px solid #e3e5e8;
      cursor: pointer;
      text-align: center;
      .close {
        position: absolute;
        top: 50%;
        right: 2px;
        transform: translateY(-50%);
      }
    }
    .hoverActive {
      background: #f1f3f4;
    }
    .showTabs {
      transform: rotate(180deg);
      transition: all 0.3s ease;
    }
    .hideTabs {
      transform: rotate(0deg);
      transition: all 0.3s ease;
    }
  }
}
.ant-tabs-tab {
  margin-right: 10px !important;
}
.showTabsUl {
  li {
    display: inline-block;
    position: relative;
    white-space: nowrap;
    margin: 10px 5px;
    width: 110px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #e3e5e8;
    font-size: 14px;
    cursor: pointer;
    i {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }
}
</style>
