/*
 * keepAlive 页面是否缓存  默认为true,可选false
 * tab 是否打开新标签页 默认为true,可选false
 * role 权限控制
 * isPadding 入口页面是否添加padding 默认为true,可选false  例如：客户筛选
 * */

import { BasicLayout } from '../layouts'

export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('../pages/Login/Login')
  },
  {
    path: '/demo',
    component: () => import('../pages/Demo/Index')
  },
]

export const asyncRouterMap = [
  {
    path: '/',
    component: BasicLayout,
    meta: {
      title: '工作台',
      keepAlive: true,
      role: ['super_editor']
    },
    children: [
      {
        path: '/customerManagement',
        component: () => import('../pages/CustomPage/CustomerList'),
        meta: {
          title: '客户管理',
          keepAlive: true
        }
      },
      {
        path: '/addCustomer',
        component: () => import('../pages/CustomPage/AddCustomer1.vue'),
        meta: {
          title: '新增客户',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/customerDetail',
        component: () => import('../pages/CustomPage/Detail.vue'),
        meta: {
          title: '客户详情',
          keepAlive: true
        }
      },
      {
        path: '/CustomerMap',
        component: () => import('../pages/CustomPage/CustomerMap.vue'),
        meta: {
          title: '客户地图',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/importCustomer',
        component: () => import('../pages/CustomPage/ImportCustomer.vue'),
        meta: {
          title: '客户导入',
          keepAlive: false,
          tab: true
        }
      },
      // {
      //     path: '/',
      //     component: ()=> import('../pages/test-1'),
      //     meta: {
      //         title: '测试1',
      //         keepAlive: false,
      //         role: ['super_editor','admin'],
      //         icon: 'radar-chart'
      //     }
      // },
      // {
      //     path: '/test-2',
      //     component: ()=> import('../pages/test-2'),
      //     meta: {
      //         title: '测试2',
      //         keepAlive: true,
      //         role: ['super_editor'],
      //         icon: 'heat-map'
      //     }
      // },
      // {
      //     path: '/test-3',
      //     component: ()=> import('../pages/test-3'),
      //     meta: {
      //         title: '测试3',
      //         keepAlive: true,
      //         role: ['客服主管','super_editor'],
      //         icon: 'heat-map'
      //     }
      // },
      {
        path: '/configAdmin',
        component: () => import('../pages/ConfigAdmin/ConfigAdmin'),
        meta: {
          title: '配置管理',
          keepAlive: true,
          role: ['客服主管', 'super_editor'],
          icon: 'setting'
        }
      },
      {
        path: '/classifyAdmin',
        component: () => import('../pages/ClassifyAdmin/ClassifyAdmin'),
        meta: {
          title: '分类管理',
          keepAlive: true,
          role: ['super_editor'],
          icon: 'heat-map'
        }
      },
      {
        path: '/map',
        component: () => import('../pages/Map/Map'),
        meta: {
          title: '地图',
          keepAlive: true,
          role: ['super_editor'],
          icon: 'global'
        }
      },
      {
        path: '/upload',
        component: () => import('../pages/upload'),
        meta: {
          title: '上传图片(文件)',
          keepAlive: true,
          role: ['admin'],
          icon: 'heat-map'
        }
      },
      {
        path: '/language',
        component: () => import('../pages/languageEdit'),
        meta: {
          title: '语言设置',
          keepAlive: true,
          // role: ['admin'],
          icon: 'heat-map'
        }
      },
      {
        path: '/galender',
        component: () => import('../pages/galender'),
        meta: {
          title: '日历',
          keepAlive: true
        }
      },
      {
        path: '/messagePush',
        component: () => import('../pages/MsgPush/MsgPush'),
        meta: {
          title: '消息推送',
          keepAlive: true
        }
      },
      {
        path: '/todo',
        component: () => import('../pages/todo/todo'),
        meta: {
          title: '任务清单'
        }
      },
      {
        path: '/filesBox',
        component: () => import('../pages/FilesBox/FilesBox.vue'),
        meta: {
          keepAlive: true,
          title: '文件柜',
          isPadding: false
        }
      },
      {
        path: '/coupon',
        component: () => import('../pages/Coupon/Coupon.vue'),
        meta: {
          keepAlive: true,
          title: '优惠券'
        }
      },
      {
        path: '/treeList',
        component: () => import('@/pages/test-1/treeList'),
        meta: {
          keepAlive: true,
          title: 'tree-树'
        }
      },
      {
        path: '/test-1',
        component: () => import('@/pages/test-1/test-1'),
        meta: {
          title: '测试',
          keepAlive: true
        }
      },
      {
        path: '/logAdmin',
        component: () => import('@/pages/LogAdmin/LogAdmin'),
        meta: {
          title: '日志管理',
          keepAlive: true
        }
      },
      {
        path: '/msgAdmin',
        component: () => import('@/pages/MsgAdmin/MsgAdmin'),
        meta: {
          title: '消息管理',
          keepAlive: true
        }
      },
      {
        path: '/menuAdmin',
        component: () => import('@/pages/MenuAdmin/MenuAdmin'),
        meta: {
          title: '菜单管理',
          keepAlive: true
        }
      },
      {
        path: '/OKRManagement',
        component: () => import('@/pages/OKRManagement/OKRManagement'),
        meta: {
          title: 'OKR管理',
          keepAlive: true
        }
      },
      {
        path: '/OKRDetails',
        component: () => import('@/pages/OKRManagement/OKRDetails'),
        meta: {
          title: '项目详情',
          keepAlive: false
        }
      },
      {
        path: '/OKRProjectDetails',
        component: () => import('@/pages/OKRManagement/OKRProjectDetails'),
        meta: {
          title: '项目详情',
          keepAlive: false
        }
      },
      {
        path: '/Meeting',
        component: () => import('../pages/Meeting/Meeting.vue'),
        meta: {
          keepAlive: true,
          title: '学习计划'
        }
      },
      {
        path: '/Addmeeting',
        component: () => import('../pages/Meeting/Addmeeting.vue'),
        meta: {
          keepAlive: false,
          title: '添加学习计划'
        }
      },
      {
        path: '/SaveMeeting',
        component: () => import('../pages/Meeting/SaveMeeting.vue'),
        meta: {
          keepAlive: false, //是否启用页面缓存
          title: '修改学习计划'
        }
      },
      {
        path: '/preview',
        component: () => import('../pages/Meeting/preview.vue'),
        meta: {
          title: '学习计划详情',
          keepAlive: false
        }
      },
      {
        path: '/Record',
        component: () => import('../pages/Student/Record.vue'),
        meta: {
          title: '跟踪记录',
          keepAlive: false
        }
      },
      {
        path: '/Poster',
        component: () => import('../pages/Poster/Poster.vue'),
        meta: {
          title: '海报模板',
          keepAlive: false
        }
      },
      {
        path: '/PosterNew',
        component: () => import('../pages/Poster/PosterNew.vue'),
        meta: {
          title: '海报模板',
          keepAlive: false
        }
      },
      {
        path: '/PosterList',
        component: () => import('../pages/Poster/PosterList.vue'),
        meta: {
          title: '海报模板',
          keepAlive: false
        }
      },
      {
        path: '/table',
        component: () => import('../pages/Student/table.vue'),
        meta: {
          title: '跟踪记录',
          keepAlive: false
        }
      },

      {
        path: '/questionnaire',
        component: () => import('@/pages/Questionnaire/Questionnaire'),
        meta: {
          title: '考试问卷',
          keepAlive: false
        }
      },
      {
        path: '/QBHR_Questionnaire',
        component: () => import('@/pages/Questionnaire/QBHR_Questionnaire'),
        meta: {
          title: '随机试题',
          keepAlive: false
        }
      },
      {
        path: '/selectQuestionnaire',
        component: () => import('@/pages/Questionnaire/SelectQuestionnaire'),
        meta: {
          title: '选择问卷',
          keepAlive: false
        }
      },
      {
        path: '/addQuestionnaire',
        component: () => import('@/pages/Questionnaire/AddQuestionnaire'),
        meta: {
          title: '添加问卷',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/issueQuestionnaire',
        component: () => import('@/pages/Questionnaire/Issue'),
        meta: {
          title: '发布项目',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/statisticsQuestionnaire',
        component: () => import('@/pages/Questionnaire/Statistics'),
        meta: {
          title: '统计数据',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/correctQuestionnaire',
        component: () => import('@/pages/Questionnaire/CorrectQuestionnaire'),
        meta: {
          title: '批改问卷',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/addCorrect',
        component: () => import('@/pages/Questionnaire/AddCorrect'),
        meta: {
          title: '批改',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/personnelModul',
        component: () => import('@/pages/personnelModul/Recruit/RecruitManagement'),
        meta: {
          keepAlive: false,
          title: '招聘管理'
        },
        redirect: '/personnelModul/jobPool',
        children: [
          {
            path: '/personnelModul/jobPool',
            component: () => import('@/pages/personnelModul/Recruit/Pool/JobPool'),
            meta: {
              keepAlive: false,
              title: '招聘管理'
            }
          },
          {
            path: '/personnelModul/talentPool',
            component: () => import('@/pages/personnelModul/Recruit/Pool/TalentPool'),
            meta: {
              keepAlive: false,
              title: '人才库',
              tab: false
            }
          },
          {
            path: '/personnelModul/interviewManagement',
            component: () => import('@/pages/personnelModul/Recruit/Pool/InterviewManagement'),
            meta: {
              keepAlive: false,
              title: '面试管理',
              tab: false
            }
          },
          {
            path: '/personnelModul/CVPool',
            component: () => import('@/pages/personnelModul/Recruit/Pool/CVPool'),
            meta: {
              keepAlive: false,
              title: '简历库',
              tab: false
            }
          }
        ]
      },
      {
        path: '/statisticsChart',
        component: () => import('@/pages/personnelModul/StatisticalChart'),
        meta: {
          keepAlive: true,
          title: '招聘统计'
        }
      },
      {
        path: '/checkData',
        component: () => import('@/pages/System/checkData'),
        meta: {
          keepAlive: true,
          title: '数据大屏'
        }
      },

      {
        path: '/workOrder',
        component: () => import('@/pages/WorkOrder/WorkOrder'),
        meta: {
          keepAlive: false,
          title: '工单'
        }
      },
      {
        path: '/personInfoPublic',
        component: () => import('@/pages/personnelModul/Recruit/PersonInfo/PersonInfo_Public'),
        meta: {
          keepAlive: false,
          title: '人名'
        },
        redirect: '/personinfo/personManagement',
        children: [
          {
            path: '/personinfo/personManagement',
            component: () => import('@/pages/personnelModul/Recruit/PersonInfo/Person_Management'),
            meta: {
              keepAlive: false,
              title: '面试记录',
              tab: false
            }
          },
          {
            path: '/personinfo/personResume',
            component: () => import('@/pages/personnelModul/Recruit/PersonInfo/Person_Resume'),
            meta: {
              keepAlive: false,
              title: '个人资料',
              tab: false
            }
          }
        ]
      },
      {
        path: '/staff/StaffPublic',
        component: () => import('@/pages/personnelModul/Staff/StaffPublic'),
        meta: {
          keepAlive: false,
          title: '员工管理'
        },
        redirect: '/staffManagement/SatffManagement',
        children: [
          {
            path: '/staffManagement/SatffManagement',
            component: () => import('@/pages/personnelModul/Staff/Staff/StaffManagement'),
            meta: {
              keepAlive: false,
              title: '员工管理'
            }
          },
          {
            path: '/staffManagement/QuitManagement',
            component: () => import('@/pages/personnelModul/Staff/Staff/QuitManagement'),
            meta: {
              keepAlive: false,
              title: '离职管理',
              tab: false
            }
          },
          {
            path: '/staffManagement/interviewManagement',
            component: () => import('@/pages/personnelModul/Staff/Staff/ContractManagement'),
            meta: {
              keepAlive: false,
              title: '员工合同管理',
              tab: false
            }
          }
        ]
      },
      {
        // 员工管理 - 个人
        path: '/Staff/Personal',
        component: () => import('@/pages/personnelModul/Staff/StaffPersonal/Personal_Public'),
        meta: {
          keepAlive: false,
          title: '员工管理'
        },
        redirect: '/staffManagement/Staff/Personal_Info',
        children: [
          {
            path: '/staffManagement/Staff/Personal_Info',
            component: () => import('@/pages/personnelModul/Staff/StaffPersonal/Personal_Info'),
            meta: {
              keepAlive: false,
              title: '个人信息'
            }
          },
          {
            path: '/personnelModul/Staff/Track_Info',
            component: () => import('@/pages/personnelModul/Staff/StaffPersonal/Track_Info'),
            meta: {
              keepAlive: false,
              title: '跟踪信息',
              tab: false
            }
          },
          {
            path: '/personnelModul/Staff/Operation_Log',
            component: () => import('@/pages/personnelModul/Staff/StaffPersonal/Operation_Log'),
            meta: {
              keepAlive: false,
              title: '操作日志',
              tab: false
            }
          },
          {
            path: '/personnelModul/Staff/Role_Jurisdiction',
            component: () => import('@/pages/personnelModul/Staff/StaffPersonal/Role_Jurisdiction'),
            meta: {
              keepAlive: false,
              title: '角色权限',
              tab: false
            }
          }
        ]
      },
      {
        path: '/stockOverview',
        component: () => import('@/pages/ProductRetail/StockOverview'),
        meta: {
          keepAlive: true,
          title: '新零售统计库存',
          isPadding: true
        }
      },
      {
        path: '/ProductStudentSale',
        component: () => import('@/pages/ProductRetail/ProductStudentSale'),
        meta: {
          keepAlive: true,
          title: '产品销售',
          isPadding: true
        }
      },
      {
        path: '/schoolCenter',
        component: () => import('@/pages/SchoolCenter/SchoolCenter'),
        meta: {
          keepAlive: true,
          title: '中心管理',
          isPadding: false
        }
      },
      {
        path: '/serachSchool',
        component: () => import('@/pages/SchoolCenter/serachSchool'),
        meta: {
          keepAlive: true,
          title: '查找中心', //客户位置查附近的中心
          isPadding: false
        }
      },
      {
        path: '/seal',
        component: () => import('@/pages/SchoolCenter/Seal'),
        meta: {
          keepAlive: true,
          title: '公章管理',
          isPadding: false
        }
      },
      {
        path: '/test-3',
        component: () => import('@/pages/test-3'),
        meta: {
          keepAlive: true,
          title: 'test-3'
        }
      },
      {
        path: '/moreData',
        component: () => import('@/pages/Questionnaire/MoreData'),
        meta: {
          keepAlive: false,
          title: '查看更多',
          tab: true
        }
      },
      {
        path: '/clientScreen',
        component: () => import('@/pages/ClientScreen/ClientScreen'),
        meta: {
          keepAlive: true,
          title: '客户筛选',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/message',
        component: () => import('@/pages/message/Index'),
        meta: {
          keepAlive: true,
          title: '消息管理',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractManageList',
        component: () => import('@/pages/CustomContract/ContactManageList'),
        meta: {
          keepAlive: true,
          title: '合同管理',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractCreate',
        component: () => import('@/pages/CustomContract/ContactCreate'),
        meta: {
          keepAlive: true,
          title: '创建合同',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractBargain',
        component: () => import('@/pages/CustomContract/ContactBargain'),
        meta: {
          keepAlive: true,
          title: '合同定金',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractBargainPay',
        component: () => import('@/pages/CustomContract/ContractBargainPay'),
        meta: {
          keepAlive: true,
          title: '交定金',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractPrint',
        component: () => import('@/pages/CustomContract/ContactPrint'),
        meta: {
          keepAlive: true,
          title: '电子合同',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractFeeRefund',
        component: () => import('@/pages/CustomContract/ContactFeeRefund'),
        meta: {
          keepAlive: true,
          title: '合同退费',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customContractChange',
        component: () => import('@/pages/CustomContract/ContractChange'),
        meta: {
          keepAlive: true,
          title: '变更合同',
          tab: true,
          isPadding: false
        }
      },

      {
        path: '/customCreditList',
        component: () => import('@/pages/CustomCredits/list'),
        meta: {
          keepAlive: true,
          title: '积分管理',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/customCreditGoodsCreate',
        component: () => import('@/pages/CustomCredits/CreditGoodsCreate'),
        meta: {
          keepAlive: true,
          title: '添加积分商品',
          tab: true,
          isPadding: false
        }
      },

      {
        path: '/baseReport',
        component: () => import('@/pages/DataReport/BaseReport'),
        meta: {
          keepAlive: true,
          title: '基本报表',
          tab: true
        }
      },

      {
        path: '/template',
        component: () => import('@/pages/Activity/Template'),
        meta: {
          keepAlive: true,
          title: '活动方案库列表',
          tab: true
        }
      },
      {
        path: '/Template_info',
        component: () => import('@/pages/Activity/Template_info'),
        meta: {
          keepAlive: true,
          title: '活动方案库详情',
          tab: true
        }
      },
      {
        path: '/market',
        component: () => import('@/pages/Activity/market'),
        meta: {
          keepAlive: true,
          title: '营销政策',
          tab: true
        }
      },

      {
        path: '/countNum',
        component: () => import('@/pages/OpenCopy/countNum'),
        meta: {
          keepAlive: false,
          title: '打印管理统计',
          isPadding: true
        }
      },
      {
        path: '/openCopy',
        component: () => import('@/pages/OpenCopy/openCopy'),
        meta: {
          keepAlive: true,
          title: '打印管理',
          isPadding: true
        }
      },
      {
        path: '/homeWork',
        component: () => import('@/pages/HomeWork/HomeWork'),
        meta: {
          keepAlive: false,
          title: '课后作业',
          tab: true
        }
      },
      {
        path: '/CloudcoachReportBase',
        component: () => import('@/pages/Cloudcoach/CloudcoachReportBase'),
        meta: {
          keepAlive: false,
          title: '每日辅导报表',
          tab: true
        }
      },
      {
        path: '/CloudcoachReport',
        component: () => import('@/pages/Cloudcoach/CloudcoachReport'),
        meta: {
          keepAlive: false,
          title: '基础报表',
          tab: true
        }
      },
      {
        path: '/CloudcoachReportNew',
        component: () => import('@/pages/Cloudcoach/CloudcoachReportNew'),
        meta: {
          keepAlive: false,
          title: '每日云辅导报表',
          tab: true
        }
      },
      {
        path: '/handover',
        component: () => import('@/pages/HandOver/Index'),
        meta: {
          keepAlive: false,
          title: '离职交接',
          tab: true
        }
      },
      {
        path: '/CloudcoachReportBySchool',
        component: () => import('@/pages/Cloudcoach/CloudcoachReportBySchool'),
        meta: {
          keepAlive: false,
          title: '中心',
          tab: true
        }
      },
      {
        path: '/staffCondition',
        component: () => import('@/pages/StaffCondition/StaffCondition'),
        meta: {
          keepAlive: true,
          title: '员工疫情情况',
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/recordIndex',
        component: () => import('@/pages/RecordAdmin/index'),
        meta: {
          keepAlive: true,
          title: '故事管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/refundReport',
        component: () => import('@/pages/refundReport/refundReport'),
        meta: {
          keepAlive: true,
          title: '退费报表',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/group',
        component: () => import('@/pages/group/group'),
        meta: {
          keepAlive: true,
          title: '群管理',
          path: '/group'
        }
      },
      {
        path: '/groupList',
        component: () => import('@/pages/group/groupList'),
        meta: {
          keepAlive: true,
          title: '群管理',
          path: '/groupList'
        }
      },

      {
        path: '/rentQi',
        component: () => import('@/pages/Mini/rentQi'),
        meta: {
          keepAlive: true,
          title: '房租情况',
          path: '/rentQi'
        }
      },
      {
        path: '/sourceFrom',
        component: () => import('@/pages/Report/sourceFrom'),
        meta: {
          keepAlive: true,
          title: '名单来源报表',
          path: '/sourceFrom'
        }
      },
      {
        path: '/classCategoryRebort',
        component: () => import('@/pages/Report/classCategoryRebort'),
        meta: {
          keepAlive: true,
          title: '课程分类业绩统计',
          path: '/classCategoryRebort'
        }
      },

      {
        path: '/reportBase',
        component: () => import('@/pages/Report/reportBase'),
        meta: {
          keepAlive: true,
          title: '基本报表',
          path: '/reportBase'
        }
      },

      {
        path: '/introductionReport',
        component: () => import('@/pages/Report/introductionReport'),
        meta: {
          keepAlive: true,
          title: '转介绍报表',
          path: '/introductionReport'
        }
      },
      {
        path: '/courseNumReport',
        component: () => import('@/pages/Report/courseNumReport'),
        meta: {
          keepAlive: true,
          title: '上课人数报表',
          path: '/courseNumReport'
        }
      },
      {
        path: '/achieveCompleteRate',
        component: () => import('@/pages/Report/achieveCompleteRate'),
        meta: {
          keepAlive: true,
          title: '业绩完成率',
          path: '/achieveCompleteRate'
        }
      },
      {
        path: '/classtrackAnalysis',
        component: () => import('@/pages/Report/classtrackAnalysis'),
        meta: {
          keepAlive: true,
          title: '班级出勤分析',
          path: '/classtrackAnalysis'
        }
      },

      {
        path: '/MiniBanner',
        component: () => import('@/pages/Mini/MiniBanner'),
        meta: {
          keepAlive: true,
          title: '小程序管理',
          path: '/MiniBanner'
        }
      },
      {
        path: '/pageAdmin',
        component: () => import('@/pages/PageAdmin/pageAdmin'),
        meta: {
          keepAlive: true,
          title: '模块',

          tab: true,
          isPadding: true
        }
      },
      {
        path: '/pageList',
        component: () => import('@/pages/PageAdmin/pageList'),
        meta: {
          keepAlive: false,
          title: '页面管理列表',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/audioAdmin',
        component: () => import('@/pages/AudioAdmin/audioAdmin'),
        meta: {
          keepAlive: true,
          title: '录音管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/templateIndex',
        component: () => import('@/components/tableTemplate/index'),
        meta: {
          keepAlive: true,
          title: '模板管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/buyList',
        component: () => import('@/pages/CourseAdmin/buyList'),
        meta: {
          keepAlive: true,
          title: '精品课',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/distribution',
        component: () => import('@/pages/DistributionAdmin/DistributionIndex'),
        meta: {
          keepAlive: true,
          title: '推广素材',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/distributionMember',
        component: () => import('@/pages/DistributionAdmin/distributionMember'),
        meta: {
          keepAlive: true,
          title: '分销员列表',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/distributionInfo',
        component: () => import('@/pages/DistributionAdmin/distributionInfo'),
        meta: {
          keepAlive: false,
          title: '分销员详情',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/distributionLevel',
        component: () => import('@/pages/DistributionAdmin/distributionLevel'),
        meta: {
          keepAlive: false,
          title: '分销等级',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/levelAction',
        component: () => import('@/pages/DistributionAdmin/levelAction'),
        meta: {
          keepAlive: false,
          title: '添加分销等级',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/distributionOrder',
        component: () => import('@/pages/DistributionAdmin/distributionOrder'),
        meta: {
          keepAlive: false,
          title: '分销订单列表',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/disOrderInfo',
        component: () => import('@/pages/DistributionAdmin/disOrderInfo'),
        meta: {
          keepAlive: false,
          title: '分销订单详情',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/withdrawalList',
        component: () => import('@/pages/DistributionAdmin/withdrawalList'),
        meta: {
          keepAlive: false,
          title: '分销提现审核列表',
          tab: true,
          isPadding: true
        }
      },

      {
        path: '/activityIndex',
        component: () => import('@/pages/distribution/activityIndex'),
        meta: {
          keepAlive: true,
          title: '推广计划',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/salescourse',
        component: () => import('@/pages/Activity/salescourse'),
        meta: {
          keepAlive: true,
          title: '在线课包销售详细',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/salescourse',
        component: () => import('@/pages/Activity/salescourse'),
        meta: {
          keepAlive: true,
          title: '在线课包销售详细',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/scheduling',
        component: () => import('@/pages/scheduling/scheduling'),
        meta: {
          keepAlive: false,
          title: '排班管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/schedulSeting',
        component: () => import('@/pages/scheduling/schedulSeting'),
        meta: {
          keepAlive: false,
          title: '排班设置',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/courseAdmin',
        component: () => import('@/pages/CourseAdmin/CourseAdminIndex'),
        meta: {
          keepAlive: false,
          title: '课程管理入口文件',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/marketManagement',
        component: () => import('@/pages/MarketManagement/MarketManagement'),
        meta: {
          keepAlive: true,
          title: '市场管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/needsContent',
        component: () => import('@/pages/Hr/needsContent'),
        meta: {
          keepAlive: false,
          title: '招聘需求',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/resume',
        component: () => import('@/pages/Hr/resume'),
        meta: {
          keepAlive: false,
          title: '候选人',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/resumePersonnel',
        component: () => import('@/pages/Hr/resumePersonnel'),
        meta: {
          keepAlive: false,
          title: '人才库',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/interviewList',
        component: () => import('@/pages/Hr/interviewList'),
        meta: {
          keepAlive: false,
          title: '面试安排',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/resumePage',
        component: () => import('@/pages/Hr/resumePage'),
        meta: {
          keepAlive: false,
          title: '招聘管理',
          tab: true,
          isPadding: true
        }
      },

      {
        path: '/resume',
        component: () => import('@/pages/Hr/resume'),
        meta: {
          keepAlive: false,
          title: '简历列表',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/resumeProgress',
        component: () => import('@/pages/Hr/resumeProgress'),
        meta: {
          keepAlive: false,
          title: '招聘进展',
          tab: true,
          isPadding: true
        }
      },

      {
        path: '/hrOverview',
        component: () => import('@/pages/Hr/hrOverview'),
        meta: {
          keepAlive: false,
          title: '总览',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/resumeReport',
        component: () => import('@/pages/Hr/resumeReport'),
        meta: {
          keepAlive: false,
          title: '招聘报表',
          tab: true,
          isPadding: true
        }
      },

      {
        path: '/ddnsList',
        component: () => import('@/pages/System/ddnsList'),
        meta: {
          keepAlive: false,
          title: 'ddns',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/projectAdmin',
        component: () => import('@/pages/projectAdmin/projectAdmin'),
        meta: {
          keepAlive: false,
          title: '项目管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/postAdmin',
        component: () => import('@/pages/postAdmin/postAdmin'),
        meta: {
          keepAlive: false,
          title: '职位管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/awardRule',
        component: () => import('@/pages/postAdmin/awardRule'),
        meta: {
          keepAlive: false,
          title: '奖励规则',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/postStandard',
        component: () => import('@/pages/postAdmin/postStandard'),
        meta: {
          keepAlive: false,
          title: '职位标准',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/neituiAdmin',
        component: () => import('@/pages/postAdmin/neituiAdmin'),
        meta: {
          keepAlive: false,
          title: '内推管理',
          tab: true,
          isPadding: true
        }
      },
      {
        path: '/LogsCalendar',
        component: () => import('../pages/Cloudcoach/LogsCalendar.vue'),
        meta: {
          title: '每日辅导记录',
          keepAlive: false
        }
      },
      {
        path: '/ClassSummary',
        component: () => import('../pages/ReportCenter/ClassSummary.vue'),
        meta: {
          title: '上课汇总-个人版',
          keepAlive: false
        }
      },
      {
        path: '/ClassSummaryData',
        component: () => import('../pages/ReportCenter/ClassSummaryData.vue'),
        meta: {
          title: '上课汇总数据',
          keepAlive: false
        }
      },
      {
        path: '/OnlineClassTeacher',
        component: () => import('../pages/ReportCenter/OnlineClassTeacher.vue'),
        meta: {
          title: '线上课数据表',
          keepAlive: false
        }
      },
      {
        path: '/OnlineClassStudentList',
        component: () => import('../pages/ReportCenter/OnlineClassStudentList.vue'),
        meta: {
          title: '线上课学生数据表',
          keepAlive: false
        }
      },
      {
        path: '/OnlineClassStudentList',
        component: () => import('../pages/ReportCenter/OnlineClassStudentList.vue'),
        meta: {
          title: '线上课学生数据表',
          keepAlive: false
        }
      },
      {
        path: '/ClassStatistics',
        component: () => import('../pages/ClassStatistics/ClassStatistics.vue'),
        meta: {
          title: '课耗统计',
          keepAlive: false,
          tab: true,
          isPadding: false
        }
      },
      {
        path: '/TeachingPlan',
        component: () => import('../pages/TeachingPlan/Index.vue'),
        meta: {
          title: '教案管理',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/ContactAgreement',
        component: () => import('../pages/ContactAgreement/List.vue'),
        meta: {
          title: '模板管理',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/editContact',
        component: () => import('../pages/ContactAgreement/Index.vue'),
        meta: {
          title: '模板编辑',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/addContact',
        component: () => import('../pages/ContactAgreement/Add.vue'),
        meta: {
          title: '新增模板',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/StudentSurplusLesson',
        component: () => import('../pages/ReportCenter/StudentSurplusLesson.vue'),
        meta: {
          title: '会员剩余课次',
          keepAlive: false
        }
      },
      {
        path: '/marketing',
        component: () => import('../pages/Report/marketing.vue'),
        meta: {
          title: '全民营销',
          keepAlive: false
        }
      },

      {
        path: '/JoinStudyFund',
        component: () => import('../pages/ReportCenter/JoinStudyFund.vue'),
        meta: {
          title: '加盟学习基金汇总',
          keepAlive: false
        }
      },
      {
        path: '/JoinStudyFundSummary',
        component: () => import('../pages/ReportCenter/JoinStudyFundSummary.vue'),
        meta: {
          title: '汇总表',
          keepAlive: false
        }
      },
      {
        path: '/wandou',
        component: () => import('../pages/Report/wandou.vue'),
        meta: {
          title: '会员报表',
          keepAlive: false
        }
      },
      {
        path: '/region',
        component: () => import('../pages/Report/region.vue'),
        meta: {
          title: '中心报表',
          keepAlive: false
        }
      },
      {
        path: '/SupportSchool',
        component: () => import('../pages/Report/SupportSchool.vue'),
        meta: {
          title: '支援中心',
          keepAlive: false
        }
      },
      {
        path: '/workPlan',
        component: () => import('../pages/WorkPlan/workPlan.vue'),
        meta: {
          title: '工作计划',
          keepAlive: false
        }
      },
      {
        path: '/workReport',
        component: () => import('../pages/WorkReport/workReport.vue'),
        meta: {
          title: '周报日报',
          keepAlive: false,
          tab: true
        }
      },
      {
        path: '/Calendar',
        component: () => import('../pages/Calendar/Calendar.vue'),
        meta: {
          title: '日历',
          keepAlive: false
        }
      },
      {
        path: '/performanc',
        component: () => import('../pages/User/performanc.vue'),
        meta: {
          title: '绩效计算',
          keepAlive: false
        }
      },
      {
        path: '/salaryMsg',
        component: () => import('../pages/SalaryMsg/SalaryMsg.vue'),
        meta: {
          title: '薪资信息录入',
          keepAlive: false
        }
      },
      {
        path: '/renewStatistics',
        component: () => import('../pages/Report/renewStatistics.vue'),
        meta: {
          title: '续费跟踪统计',
          keepAlive: false
        }
      },
      {
        path: '/QmExchange',
        component: () => import('../pages/QmExchange/QmExchange.vue'),
        meta: {
          title: '七田真启蒙兑换码管理',
          keepAlive: false
        }
      },
      {
        path: '/QmPackage',
        component: () => import('../pages/QmPackage/QmPackage.vue'),
        meta: {
          title: '七田真启蒙套餐包',
          keepAlive: false
        }
      },
      {
        path: '/VipOrder',
        component: () => import('../pages/Qtz-qm/VipOrder/VipOrder.vue'),
        meta: {
          title: 'VIP订单管理',
          keepAlive: false
        }
      },
      {
        path: '/OpenCourse',
        component: () => import('../pages/Qtz-qm/OpenCourse/OpenCourse.vue'),
        meta: {
          title: '课程开通',
          keepAlive: false
        }
      },
      {
        path: '/CounselorBoard',
        component: () => import('../pages/CounselorBoard/CounselorBoard.vue'),
        meta: {
          title: '顾问看板',
          keepAlive: false
        }
      },
      {
        path: '/Plan',
        component: () => import('../pages/Plan/plan.vue'),
        meta: {
          title: '目标管理',
          keepAlive: false
        }
      },
      {
        path: '/OnlineClassSource',
        component: () => import('../pages/OnlineClass/Source.vue'),
        meta: {
          title: '线上课课件',
          keepAlive: false
        }
      },
      {
        path: '/testlist',
        component: () => import('../pages/DistributionAdmin/test_bank.vue'),
        meta: {
          title: '测试页面',
          keepAlive: false
        }
      },
      {
        path: '/ExchangeLog',
        component: () => import('@/pages/ExchangeLog/Index'),
        meta: {
          keepAlive: false,
          title: '精品课兑换码管理',
          tab: true
        }
      },
      {
        path: '/OperateNode',
        component: () => import('@/pages/OperateNode/OperateNode'),
        meta: {
          keepAlive: false,
          title: '节点管理',
          tab: true
        }
      },
      {
        path: '/hdTicket',
        component: () => import('@/pages/HdTicket/HdTicket'),
        meta: {
          keepAlive: false,
          title: '防伪码管理',
          tab: true
        }
      },
      {
        path: '/ClassFee',
        component: () => import('@/pages/ClassFee/ClassFee'),
        meta: {
          keepAlive: false,
          title: '收费优惠',
          tab: true
        },
        redirect: '/ClassFee/FeeList',
        children: [
          {
            path: '/ClassFee/FeeList',
            component: () => import('@/pages/ClassFee/Fee/FeeList'),
            meta: {
              keepAlive: false,
              title: '收费标准'
            }
          },
          {
            path: '/ClassFee/ConcessionList',
            component: () => import('@/pages/ClassFee/Concession/ConcessionList'),
            meta: {
              keepAlive: false,
              title: '课程优惠方案',
              tab: false
            }
          },
          {
            path: '/ClassFee/ProdList',
            component: () => import('@/pages/ClassFee/Prod/ProdList'),
            meta: {
              keepAlive: false,
              title: '物品优惠方案',
              tab: false
            }
          },
          {
            path: '/ClassFee/FinanceList',
            component: () => import('@/pages/ClassFee/Finance/FinanceList'),
            meta: {
              keepAlive: false,
              title: '优惠券方案',
              tab: false
            }
          },
          {
            path: '/ClassFee/CourseVoucherList',
            component: () => import('@/pages/ClassFee/Course/CourseVoucherList'),
            meta: {
              keepAlive: false,
              title: '课券管理',
              tab: false
            }
          }
        ]
      }
    ]
  },
  {
    path: '/newPage',
    component: BasicLayout,
    meta: {
      title: '其他',
      keepAlive: true,
      role: ['super_editor']
    },
    children: [
      {
        path: '/customPage',
        component: () => import('../pages/CustomPage/CustomPage'),
        meta: {
          title: '审批流程',
          keepAlive: true,
          role: ['super_editor']
        }
      }
    ]
  },
  {
    path: '*',
    component: () => import('../pages/404')
  }
]
