import myAxios from 'axios';
import Vue from 'vue';
import MD5 from '@/assets/axios/md5';
console.dir(Vue);

Vue.prototype.__USER__ = JSON.parse(window.sessionStorage.getItem('userInfo'));
//上传文件
Vue.prototype.__uploadFile__ = async function (list,url) {
    let files = [];
    for(let i=0;i<list.length;i++){
        files.push(list[i]);
    }
    //axios请求
    const http = myAxios.create({
        baseURL: url?'https://sapi.sikegroup.com':'https://smart.sikegroup.com',
        withCredentials: false,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        transformRequest: [function (data) {
            let newData = '';
            for (let k in data) {
                if (data.hasOwnProperty(k) === true) {
                    newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&';
                }
            }
            return newData;
        }]
    });
    function qiniuHash(type){
        let timestamp=new Date().getTime();
        let num = Math.round(Math.random()*10000000);
        let str = timestamp+'-'+num;
        return  MD5.md5(str)+'.'+type.toLowerCase();
    }
    const qiniuFill = url?'https://homework.sikegroup.com/':'https://smart-resource.sikegroup.com/';
    const getToken = url?url:'/qiniuclient/smart_token';
    const qiniu = await http({method: 'get', url: getToken, params: {} });
    const textPromises = files.map(async file => await new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        let formData = new FormData();

        //'https://up-z2.qbox.me'
        xhr.open('POST', qiniu.data.url, true);
        formData.append('token', qiniu.data.uptoken);
        formData.append('file', file);
        formData.append('chunks', 1);
        formData.append('chunk', 0);
        formData.append("key",qiniuHash(file.name.split('.')[file.name.split('.').length-1] ));
        xhr.onreadystatechange = function (response) {
            if (xhr.readyState == 4 && xhr.status == 200 && xhr.responseText != "") {
                let blkRet = JSON.parse(xhr.responseText);
                let item = { "FileName": file.name, "FileUrl": qiniuFill + blkRet.key, "FileType": file.type, "FileSize": file.size, "FileDuration": '' };
                resolve(item)
            }
        };
        xhr.send(formData);
    }));
    const data = [];
    // 按次序输出
    for (const textPromise of textPromises) {
        data.push(await textPromise)
    }
    return data
};

//复制对象
Vue.prototype.ObjectClone = (obj) => {
    var o;
    // 如果  他是对象object的话  , 因为null,object,array  也是'object';
    if (typeof obj === 'object') {
        // 如果  他是空的话
        if (obj === null) {
            o = null;
        }
        else {

            // 如果  他是数组arr的话
            if (obj instanceof Array) {
                o = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    o.push(Vue.prototype.ObjectClone(obj[ i ]));
                }
            }
            // 如果  他是对象object的话
            else {
                o = {};
                for (var j in obj) {
                    o[ j ] = Vue.prototype.ObjectClone(obj[ j ]);
                }
            }

        }
    }
    else {
        o = obj;
    }
    return o;
};

//数组克隆
Array.prototype.clone = function(){
    let a=[];
    for(let i=0,l=this.length;i<l;i++) {
        a.push(this[i]);
    }
    return a;
};

//全局点击函数
Vue.prototype.globalClick = function (callback) {
    document.getElementById('app').onclick = function () {
        callback();
    };
};
