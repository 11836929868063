import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './vuex'
import Antd from 'ant-design-vue'
import NProgress from 'nprogress'
import Axios from 'assets/axios/axios'
import axios from 'axios'
import moment from 'moment' //时间处理插件
import 'nprogress/nprogress.css'
import 'ant-design-vue/dist/antd.css'
import 'css/global.less'
import 'css/fonts/iconfont.js'
import 'css/fonts/iconfont.css'
import 'js/common'
import './assets/js/jquery-3.3.1.min'
import VueI18n from 'vue-i18n'
// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// 注册全局组件
import '@/custom/index'

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'GifbRye3VIFOU6L3WVh61ZYf'
})
Vue.prototype.$axios = Axios
Vue.prototype.__moment__ = moment
Vue.prototype.emoji =
  '😀 😃 😄 😁 😆 😅 🤣 😂 🙂 🙃 😉 😊 😇 😍 😘 😗 ☺ 😚 😙 😋 😛 😜 😝 🤑 🤗 🤔 😐 😑 😶 😏 😒 🙄 😬 😌 😪 😴 😷 🤒 🤕 🤢 🤧 🤠 😎 🤓 😕 😟 😮 😯 😲 😳 😦 😧 😨 😰 😱 😭 😖 😞 😤 😡 😠 😈 💀 🤡 👻 😻 😼 😽 🙀 😿 😾 🙈 🙉 🙊 💋 💌 💘 💗 💓 💞 💕 💔 ❤ 💛 💬 🤳 👄 👶 💰 🎉 🎃 🎰 🕹 👕 👖 👗 👙 👛 👜 🔔 📣 💊 📅 🔅 ㊗ 🈲 🔴 🔵 ♥ 🌜 ☀ 🌝 🌞 🌟 ❄ 🌈 👌 ✌ 🤘 🤙 👉 👆 🖕 👇 ☝ 👍 👎 ✊ 👊 🤛 👏 👐 🤝 🙏 💪 🐱 🐵 🐣 🐷 🐄 🐶 🐯 🐮 🏀 ⚾ ⚽ 🏈 🏊‍♂⛹ ☕ 🥛 🚩 🏁 🍌 🍉 ⛅ ☁ ⛈🌤 🌥 🌦 🌧🌨 🌩 🌀 💯 💥 💄💸 💳 ✉ 📩 ⏰ 🌂 ☂ '
Vue.use(Antd)
Vue.use(VueI18n)
Vue.config.productionTip = false

//路由变化添加进度条
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

router.afterEach(() => {
  NProgress.done()
})

import * as filters from './Filters/index' // global filters
// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

//监听路由
router.beforeEach((to, from, next) => {
  NProgress.start()
  //skim系统跳转过来获取用户信息
  if (to.query.code) {
    if (store.state.permission.addRouters && store.state.permission.addRouters != 0) {
      next()
    } else {
      let roles = 'super_editor'
      let skimHttp = axios.create({
        baseURL: 'https://sapi.sikegroup.com',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        withCredentials: false,
        transformRequest: [
          function (data) {
            let newData = ''
            for (let k in data) {
              if (data.hasOwnProperty(k) === true) {
                newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
              }
            }
            return newData
          }
        ]
      })
      skimHttp({
        method: 'get',
        url: '/v1/verify_by_code',
        params: { code: to.query.code }
      }).then(function (res) {
        if (res.data.code === 200) {
          window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.data.client))
          Vue.prototype.__USER__ = JSON.stringify(res.data.data.client)
          window.sessionStorage.setItem('access_token', res.data.data.access_token)
          window.sessionStorage.setItem('refresh_token', res.data.data.refresh_token)
          window.sessionStorage.setItem('multiTabList', JSON.stringify(item))
          store.dispatch('GenerateRoutes', { roles }).then(() => {
            router.addRoutes(store.state.permission.addRouters)
            next({ ...to, replace: true })
          })
          let item = [{ title: '首页', path: to.path }]
          window.sessionStorage.setItem('multiTabList', JSON.stringify(item))
          store.dispatch('TOGGLE_MULTI_TAB_LIST', item)
          // next();
        }
      })
    }
  } else {
    if (!window.sessionStorage.getItem('multiTabList') || JSON.parse(window.sessionStorage.getItem('multiTabList')).length == 0) {
      if (!(to.path == '/')) {
        let list = store.state.app.pageMenuList
        for (let i = 0; i < list.length; i++) {
          if (list[i].child) {
            for (let j = 0; j < list[i].child.length; j++) {
              if (list[i].child[j].Url == to.path) {
                let item = [{ title: list[i].child[j].Mname, path: to.path }]
                window.sessionStorage.setItem('multiTabList', JSON.stringify(item))
                store.dispatch('TOGGLE_MULTI_TAB_LIST', item)
                store.dispatch('ToggleDefaultOpenKeys', i)
              }
            }
          }
        }
      } else {
        let item = [{ title: '工作台', path: '/index', query: {} }]
        window.sessionStorage.setItem('multiTabList', JSON.stringify(item))
        store.dispatch('TOGGLE_MULTI_TAB_LIST', item)
      }
    }
    if (!window.sessionStorage.getItem('userInfo')) {
      if (to.path == '/login') {
        next()
      } else {
        next({ path: '/login', query: { redirect: to.fullPath } })
      }
    } else {
      if (store.state.permission.addRouters && store.state.permission.addRouters != 0) {
        next()
      } else {
        let roles = JSON.parse(window.sessionStorage.getItem('userInfo')).roles[0]
        store.dispatch('GenerateRoutes', { roles }).then(() => {
          router.addRoutes(store.state.permission.addRouters)
          next({ ...to, replace: true })
        })
        next()
      }
    }
  }
})
let i18n = new VueI18n({
  locale: localStorage.lang || 'en-gb',
  message: new Object()
})
let mixin = {
  created: function () {
    //   console.log('混入对象的钩子被调用')
    localStorage.setItem('language', 'zh-cn')
  }
}

new Vue({
  router,
  mixins: [mixin],
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
