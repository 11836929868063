<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view v-if="IsRouterAlive" style="height: 100%;"></router-view>
    </div>
  </a-locale-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import moment from 'moment'
import 'moment/locale/zh-cn'

moment.locale('zh-cn')
const EN = 'en-gb'
const ZH = 'zh-cn'

export default {
  name: 'app',
  components: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      locale: zhCN,
      localeval: 'zh-cn',
      IsRouterAlive: true
      // language: this.$store.state.language
    }
  },
  methods: {
    moment,
    reload() {
      this.IsRouterAlive = false
      this.$nextTick(() => {
        this.IsRouterAlive = true
      })
    },
    ajaxLanguage(type) {
      let that = this
      $.ajax({
        url: 'https://smart.sikegroup.com/skim/v1/Language/get_language',
        type: 'post',
        async: false,
        data: { Language: type, Label: '' },
        success: function (res) {
          // that.$i18n.message = res.message;
          that.$set(that.$i18n, 'message', res.message)
        }
      })
    }
  },
  mounted() {
    this.$store.state.language.langTab = 'zh-cn' //第一次通过改变state里语言，触发watch监听获取自定义语言
  },
  computed: {
    //监听语言选择
    language() {
      return this.$store.state.language.langTab
    }
  },
  watch: {
    language: {
      //  immediate:true,
      handler(val) {
        //语言判断
        let that = this
        // console.log(that.$i18n,val)
        let type = 'Cn'
        that.localeval = val
        if (val == 'en-gb') {
          moment.locale(EN)
          type = 'En'
          that.$i18n.locale = EN
          that.locale = enUS
        } else {
          moment.locale(ZH)
          type = 'Cn'
          that.$i18n.locale = ZH
          that.locale = zhCN
        }
        // console.log(type,that.localeval)
        that.ajaxLanguage(type)
      },
      deep: true
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
  height: 100%;
}
</style>
