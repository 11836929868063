<template>
    <div style="height: 100%">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "NewPageLayout",
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>